import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders
} from "react-calendar-timeline/lib";
// make sure you include the timeline stylesheet or the timeline will not be styled
import { forwardRef, useState, useEffect } from "react";
import "react-calendar-timeline/lib/Timeline.css";
import "../Scheduler.css";
import _ from "lodash";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { AppConstant } from "../../../Assests/AppConstant";
import { CustomDynamicForm } from "../../Common/CustomForm/CustomDynamicForm";
import {
  YYYY_MM_DD_FORMAT,
  changeDateToGivenFormat,
  getCurrentDate,
} from "../../../../Utils/DateUtils";
import { smartScheduleSelector } from "../../../../redux/reducers/reducerSlices/SmartScheduleReducer";
import { sagaActions } from "../../../../sagas/sagaActions";
import CustomTooltip from "../../Common/Tooltip/CustomTooltip";
import { getUserSelector } from "../../../../redux/reducers/reducerSlices/UserReducer";
import {
  createWPMetaData,
  customStylesLight,
  displayLoader,
  formParticularFieldValue,
  formParticularFieldValueForAssociatedPO,
  showAlert,
} from "../../../Assests/Utility";
import { useLocation, useNavigate } from "react-router-dom";
import ScheduleProWrapper from "../SchedulePro/ScheduleProWrapper";
import SSPopup from "../../Common/Popup/SSPopup/SSPopup";
import AddMorePOPopupSS from "../SchedulePro/Components/Common/Popups/AddMorePOPopupSS";
import SchedulerHeaderBarSS from "../Children/SchedulerHeaderBarSS";
import { CustomDynamicFormSS } from "../../Common/CustomForm/CustomDynamicFormSS";
import ViewAppointmentPopupSS from "../Popups/ViewAppointmentPopupSS";
import SendEmailSS from "../../sendEmailSS";
import CommonToggleSwitch from "../../Common/CommonToggleSwitch/CommonToggleSwitch";
import { closeUpdatesSocketConnection, initializeUpdatesSocketConnection } from "../../../../Utils/updateSocket";
import { getWhMetaDataReducerSlice } from "../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice";
import { fetchBreaksDocksListCommonFunc } from "../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/BreaksForm/Common/FetchBreakList";
import { fetchLeavesDocksListCommonFunc } from "../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/LeavesForm/Common/FetchLeavesList";
import { fetchDockListCommonFunc } from "../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/DocksMeta/Common/FetchDockList";
import { useRef } from "react";
import DatePickerSS from "../../DatePickerSS/DatePickerSS";
import CustomButtonSS from "../../Common/CustomButton/CustomButtonSS";
import { format } from "date-fns";

const WPSchedulerWHSS = (props) => {
  const { themeSelected } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const timelineRef = useRef(null);
  const ss = useSelector(smartScheduleSelector);
  const {
    poForAppt,
    appointmentList,
    wpSchedulerData,
    wpCapacityData,
    initateLoading,
    searchedPOData,
    carrierList,
    validationType,
    appointmentConfirmation,
    intiateAppointmentLoading,
    initiateMultiplePO,
    defaultDockValue,
    vehicleAndLoadType
  } = ss;
  const user = useSelector(getUserSelector);
  const { cu_id, userAccountDetails, userType, selectedWarehouse } = user;
  // Commented to resolve timezone issue
  // moment.tz.setDefault(selectedWarehouse?.TimeZone); // Set Warehouse Timezone
  const [items, setItems] = useState([]);
  const [masterGroups, setMasterGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [allDock, setAllDock] = useState([]);
  const [canvasData, setCanvasData] = useState("");
  const [loading, setLoading] = useState(false);
  // const [apptLoading, setApptLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [resetFormData, setResetFormData] = useState(0);
  const [particularFieldValue, setParticularFieldValue] = useState("");
  const [currentCalendarType, setCurrentCalendarType] = useState("today");
  const [startDateState, setStartDateState] = useState(
    changeDateToGivenFormat(
      getCurrentDate(),
      AppConstant.commonStrings.MM_DD_YYYY_dateFormat
    )
  );
  const [endDateState, setEndDateState] = useState(
    changeDateToGivenFormat(
      getCurrentDate(),
      AppConstant.commonStrings.MM_DD_YYYY_dateFormat
    )
  );
  const currentDate = moment().startOf("day");
  const [visibleTimeStart, setVisibleTimeStart] = useState(
    currentDate.clone().hour(0)
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    currentDate.clone().hour(23).minute(59)
  );
  const [startTimeState, setStartTimeState] = useState("");
  const [endTimeState, setEndTimeState] = useState("");
  const [shouldRender, setShouldRender] = useState(false);
  const [viewPoDetails, setViewPoDetails] = useState({});
  const [showAddPoModel, setShowAddPoModel] = useState(false);
  const [canvasTime, setCanvasTime] = useState("");
  const [bookApptModelTitle, setBookApptModelTitle] =
    useState("Book Appointment");
  const [showViewAppointmentModal, setShowViewAppointmentModal] =
    useState(false);
  const [showSearchPoModel, setShowSearchPoModel] = useState(false);
  const [posSearchList, setPoSearchList] = useState(null);
  const [carrierListState, setCarrierListState] = useState([]);
  const [timeslotListState, setTimeslotListState] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [holidayList, setHolidayList] = useState(null);
  const [showDeletePoApptModel, setShowDeletePoApptModel] = useState(false);
  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState(
    getCurrentDate()
  );
  const [wpCapacityDataState, setWPCapacityDataState] = useState(null);
  const [showValidationPopup, setShowValidationPopup] = useState(false);
  const [validationPopupText, setValidationPopupText] = useState("");
  const [disclaimersArray, setDisclaimersArray] = useState([]);

  const [showSearchError, setShowSearchError] = useState(false);
  const [dockLeaveList, setDockLeaveList] = useState([]);
  const [customDisableSave, setCustomDisableSave] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isRescheduleCase, setIsRescheduleCase] = useState(false);
  const [disableManual, setDisableManual] = useState(false);
  const whMetaData = useSelector(getWhMetaDataReducerSlice);
  const { whMetaLeavesList, whMetaDocksList, whMetaBreaksList } = whMetaData;
  const [selectedDock, setSelectedDock] = useState(null);
  const [defaultDockValueState, setDefaultDockValueState] = useState(false);
  const [showCalendar, setShowCalendar]= useState(false);
  const first=useRef(true);

  const [socketRef, setSocketRef] = useState(null)
  const scheduledItemClass = {
    background: "#C9F8D6",
    color: "#35C656",
    border: "1.4px solid rgb(74, 150, 74)" 
  }
  const rescheduledItemClass = {
    background: "rgba(252, 170, 52, 0.2)",
    border: "1.4px solid #FCAA34",
    color: '#FCAA34'
  }
  const completedItemClass = {
    color: '#FFFF',
    background: "slategray",
    border: "1.4px solid black"
  }
  // const isInitialMount = useRef(true);

  /**
   * useEffect to fetch appt data on change of date
   */
  useEffect(()=>{
    if (first.current) {
      first.current=false
      return
    }
    if (datePickerSelectedDate) {
      changeDatePickerSelectedDate(datePickerSelectedDate)
    }
  },[datePickerSelectedDate])
  
  useEffect(() => {
    const socketRefTemp = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose, datePickerSelectedDate)
    setSocketRef(socketRefTemp)
    setIsRescheduleCase(false)
    setShouldRender(false)
    setAllDock([])
    setItems([]);
    setPoSearchList(null);
      closeSearchPOForm();
      dispatch({
        type: sagaActions.GET_SCHEDULER_DATA,
        payload: { IDWarehouse: selectedWarehouse?.ID, type: 'C' },
      });
    // dispatch({type: sagaActions.CLEAR_SS_SEARCH_PO})
    // dispatch({ type: sagaActions.CLEAR_APPOINTMENT })
    // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
    // setStartDateState(visibleTimeStart)
    // setEndDateState(visibleTimeEnd)
    setTimeout(() => {
      if (selectedWarehouse?.ID) {
        dispatch({
          type: sagaActions.GET_SCHEDULER_DATA,
          payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: moment(datePickerSelectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            CalendarView: 'DAY' },
        });

        const wpCapacityData = createWPMetaData("wpCapacityData", "S", {
          IDWarehouse: selectedWarehouse?.ID,
        });
        const wpBreakData = createWPMetaData("wpBreakData", "S", {
          IDWarehouse: selectedWarehouse?.ID,
        });
        const wpLeaveData = createWPMetaData("wpLeaveData", "S", {
          IDWarehouse: selectedWarehouse?.ID,
        });

        dispatch({
          type: sagaActions.INITIATE_WAREHOUSE_METADATA,
          payload: { payload: wpCapacityData, key: "wpCapacityData" },
        });
        dispatch({
          type: sagaActions.INITIATE_WAREHOUSE_METADATA,
          payload: { payload: wpBreakData, key: "wpBreakData" },
        });
        dispatch({
          type: sagaActions.INITIATE_WAREHOUSE_METADATA,
          payload: { payload: wpLeaveData, key: "wpLeaveData" },
        });

      fetchBreakDockList()
      }
      fetchDocksLeaveList()
      fetchDockList()

    }, [0]);

    return () => {
      //commented due to new flow start
      // dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
      //commented due to new flow end
      // dispatch({ type: sagaActions.CLEAR_APPOINTMENT });
      if (socketRef) {
        closeUpdatesSocketConnection(socketRef, selectedWarehouse.ID)
      }
      dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] });
    };
    }, []);


  /**
   * useEffect to call function to highlight dock start,end time
   */
  useEffect(()=>{
    if (shouldRender) {
      highlightDockStartEndTime(groups)
      headerClickDisabled()
      highlightCalendarLeaveFunction()
      highlightCalendarNonOperationalFunction()
    }
  }, [shouldRender])

  useEffect(() => {
    highlightDockStartEndTime(groups)
  }, [whMetaBreaksList, whMetaDocksList])
  
  useEffect(() => {
    highlightCalendarLeaveFunction()
  }, [whMetaLeavesList])
  
  useEffect(() => {
    highlightCalendarNonOperationalFunction()
    if (_.size(whMetaDocksList)) {
      // Resume from here
      // Pending - Logic for filter dock and make it selected upon login
      // Data is coming gorm API only logic needs to be built
      // API getWhMetaDockListApi
      const data = whMetaDocksList[0];
      const { DefaultIDDock } = data;
        dispatch({
          type: sagaActions.UPDATE_DEFAULT_DOCK,
          payload: {
            CUID: cu_id,
              IDWarehouse: selectedWarehouse?.ID,
              DefaultIDDock: DefaultIDDock,
              CRUDTYPE: 'U'
          }
        })


    }
  },[whMetaDocksList])
  
  useEffect(() => {
    if (defaultDockValue) {
      setDefaultDockValueState(defaultDockValue)
    } else {
      setDefaultDockValueState(null)
    }
  }, [defaultDockValue])




  useEffect(() => {
    if (_.size(allDock)) {
        // if (isInitialMount.current && shouldRender) {
        if (defaultDockValueState) {
          const filterDock = _.filter(allDock, { idDock: defaultDockValueState })
          if (filterDock) {
            onDockSelected(filterDock[0], true)
          }
        } else {
          const filterDock = _.filter(allDock, { idDock: selectedDock?.idDock || '0000-0000' })
          if (_.size(filterDock)) {
            onDockSelected(filterDock[0], true)
          }
        }
      
        // isInitialMount.current = false
      // }
    }

  },[defaultDockValueState, allDock])
  
  // useEffect(() => {

  //   if (defaultDockValueState) {
  //      if (defaultDockValue && _.size(allDock)) {
  //     if (defaultDockValue && _.isEmpty(selectedDock)) {
  //       if (_.size(allDock)) {
  //         const filterDock = _.filter(allDock, { idDock: defaultDockValue })
  //         if (filterDock) {
  //           onDockSelected(filterDock[0])
  //         }
  //       }
  //     }
  //     else if (!_.isEmpty(selectedDock) && selectedDock?.dockName !== 'ALL') {
  //       if (_.size(allDock)) {
  //         const filterDock = _.filter(allDock, { idDock: defaultDockValue })
  //         if (filterDock) {
  //           onDockSelected(filterDock[0])
  //         }
  //       }
  //     }
  //   }
  //   } else {
  //     if (!_.isEmpty(selectedDock) && selectedDock?.dockName === 'ALL') {
  //       if (_.size(allDock)) {
  //         const filterDock = _.filter(allDock, { idDock: '0000-0000' })
  //         if (filterDock) {
  //           onDockSelected(filterDock[0])
  //         }
  //       }
  //     }
  //   }

   
  // },[defaultDockValueState, allDock])
  
  // useEffect(() => {
  //   if (!_.isEmpty(selectedDock)) {
  //     // if defaultDockValue and selectedDock idDock are equal then make Default Dock as checked
  //     if (defaultDockValue && defaultDockValue === selectedDock?.idDock) {
  //       setDefaultDockValueState(defaultDockValue)
  //     } else {
  //       setDefaultDockValueState(null)
  //     }
  //   }
  // },[selectedDock])
  /**
   * Function to disable click event on hours and date
   */
  const headerClickDisabled=()=>{
    let headers=document.getElementsByClassName('rct-dateHeader')
    if (headers && headers.length) {
        headers.forEach((head)=>{
          head.classList.add('event-none');
          head.onClick=false;
        })
    }
  }

  /**
   * Function to remove already ploated dock start,end
   */
const removeAlreadPloated=()=>{
  let alreadyPloated= document.getElementsByClassName("dock-hour-off")
  let elementsArray = Array.from(alreadyPloated);
    elementsArray.forEach((el)=>{
      el.remove()
    })
}

/**
 * Function to highlight dock start end time
 * @param {*} groupList 
 */
  const highlightDockStartEndTime=(groupList) => {
    removeAlreadPloated()
   const colorAmber='#e6b3cc'
    let selectedDate=moment(datePickerSelectedDate).format(AppConstant.commonStrings.YYYY_MM_DD);
    let currentDate=moment().format(AppConstant.commonStrings.YYYY_MM_DD);
    let diffInDays=moment(selectedDate).diff(moment(currentDate), 'days');
    let dockArray=[]
    if (_.size(groupList)) {
      groupList.forEach((el)=>{
        let obj={
          ...el,
          dockStartTime: moment(el.dockStartTime).utc().format(AppConstant.commonStrings.HH_mm_dateFormat),
          dockEndTime: moment(el.dockEndTime).utc().format(AppConstant.commonStrings.HH_mm_dateFormat)
        }
        dockArray.push(obj)
      })
    }
    let breakArray=[];
    if (_.size(whMetaBreaksList)) {
      whMetaBreaksList.forEach((brk) => {
        if (brk?.Status) {
          let obj={
            ...brk,
            BreakStart:moment(brk.BreakStart).utc().format(AppConstant.commonStrings.HH_mm_dateFormat),
            BreakEnd:moment(brk.BreakEnd).utc().format(AppConstant.commonStrings.HH_mm_dateFormat),
          }
          breakArray.push(obj);
       }
      })
    }

    breakArray = _.compact(breakArray)

    let sideBars= document.getElementsByClassName('rct-sidebar-row');
for (let classindex = 0; classindex < diffInDays+7; classindex++) {
      for (let i = 0; i < dockArray.length; i++) {
        let first= document.getElementsByClassName(`rct-vl rct-vl-first rct-day-${classindex} `)[0];
        let HEIGHT=sideBars[i]?.style?.height;
        const element = dockArray[i];
        let start= element.dockStartTime.split(":");
        let end= element.dockEndTime.split(":");
        let dockBreaks= breakArray?.filter((el)=>el.IDDock===element.idDock)
            if (first) {
                for (let index = 0; index < 24; index++) {
                  first && first.classList.add('dock-hour-off-wrapper');
                  
                  const node = document.createElement("div");
                  node.setAttribute("class","dock-hour-off")
                  node.style.height=HEIGHT;
                      if (index<start[0] || index>end[0]) {
                        node.style.background=`linear-gradient(to right, #ecece7 100%, transparent 0%)`
                            if (start[0]-1==index) {
                            first.setAttribute("start",'1')
                            first.setAttribute("id",element.idDock+moment(datePickerSelectedDate).format(AppConstant.commonStrings.YYYY_MM_DD)+'_start')
                            }
                            if (end[0]-1==index) {
                              first.setAttribute("end","1");
                              first.setAttribute("id",element.idDock+moment(datePickerSelectedDate).format(AppConstant.commonStrings.YYYY_MM_DD)+'_end')
                          }
                  }
                  dockBreaks.forEach((el)=>{
                    let breakStart=el?.BreakStart.split(":");
                    let breakEnd=el?.BreakEnd.split(":");
                    if ((breakStart[0]<=index && index<breakEnd[0] && breakStart[1]==0)) {
                      node.style.background=`linear-gradient(to right, ${colorAmber} ${100}%, transparent 0%)`;
                      node.setAttribute("title",el.Description);
                    }
                    if ((breakStart[0]<=index && index<breakEnd[0] && breakStart[1]!=0)) {
                      let minSap=60/Number(breakStart[1]);
                    let percentageFilled= 100-100/minSap;
                    node.style.background=`linear-gradient(to left, ${colorAmber} ${percentageFilled}%, transparent 0%)`;
                    node.setAttribute("title",el.Description);
                    }
                    if (breakEnd[0]-1==index && breakEnd[1]==0) {
                      node.style.background=`linear-gradient(to right, ${colorAmber} ${100}%, transparent 0%)`;
                      node.setAttribute("title",el.Description);
                    } 
                     if (breakEnd[0]==index && breakEnd[1]!=0) {
                      let minSap=60/Number(breakEnd[1]);
                      let percentageFilled= (100/minSap);
                      node.style.background=`linear-gradient(to right, ${colorAmber} ${percentageFilled}%, transparent 0%)`;
                      node.setAttribute("title",el.Description);
                    }
                  })
                  
                  if (index==start[0]) {
                    let minSap=60/Number(start[1]);
                    let percentageFilled= 100/minSap;
                    node.style.background=`linear-gradient(to right, #ecece7 ${percentageFilled}%, transparent 0%)`;
           
                  }
                  if (index==end[0]) {
                    let minSap=60/Number(end[1]);
                    let percentageFilled= 100-(100/minSap);
                    node.style.background=`linear-gradient(to left, #ecece7 ${percentageFilled}%, transparent 0%)`;
                
                  }
                  first.appendChild(node)
                  first=first.nextElementSibling;
                }
              }
      }
     
}
  }



  useEffect(() => {
    if (_.size(items) && _.size(groups)) {
      setShouldRender(true);
      // highlightCalendarFunction()
      // highlightDockStartEndTime(filterGroupArry)
      highlightCalendarLeaveFunction();
      highlightCalendarNonOperationalFunction()
    }
  }, [items, groups])

  
  
  /**
    * Method is used to handle truck update and fetch latest truck data
    */
    const onUpdateSocketReceived = (message, date) => {
      let parsedMessage = JSON.parse(message)
      if (typeof parsedMessage === 'string') {
        parsedMessage = JSON.parse(parsedMessage)
      }
      // console.log("🚀 ~ onUpdateSocketReceived ~ parsedMessage:", parsedMessage)  
      
       if (!_.isEmpty(parsedMessage)) {
              const { type } = parsedMessage;
              switch (type) {
                case 'APPT_END_TIME':
                  dispatch({
                    type: sagaActions.GET_SCHEDULER_DATA,
                    payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: date,
                      CalendarView: 'DAY' },
                  });
                  break;
                case 'DOCK_STATUS':
                  dispatch({
                    type: sagaActions.GET_SCHEDULER_DATA,
                    payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
                      CalendarView: 'DAY' },
                  });
                  break;
                case 'DOCK_PPH':
                  fetchDockList();
                  break;
                case 'DOCK_START_TIME':
                  // Trigger API and dom highlight function
                  dispatch({
                    type: sagaActions.GET_SCHEDULER_DATA,
                    payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
                      CalendarView: 'DAY' },
                  });
                break;
                case 'DOCK_END_TIME':
                  // Trigger API and dom highlight function
                  dispatch({
                    type: sagaActions.GET_SCHEDULER_DATA,
                    payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
                      CalendarView: 'DAY' },
                  });
                  break;
                case 'DOCK_BREAK_STATUS':
                  fetchBreakDockList();
                  // Working
                  break;
                case 'DOCK_BREAK_START_TIME':
                  fetchBreakDockList();
                  break;
                case 'DOCK_BREAK_END_TIME':
                  fetchBreakDockList();
                  break;
                case 'DOCK_BREAK_DESCRIPTION':
                  fetchBreakDockList();
                  break;
                case 'DOCK_BREAK_DELETED':
                  fetchBreakDockList();
                  break;
                case 'DOCK_LEAVE_STATUS':
                  fetchDocksLeaveList();
                  // Working
                  break;
                case 'DOCK_LEAVE_START_TIME':
                  fetchDocksLeaveList();
                  break;
                case 'DOCK_LEAVE_END_TIME':
                  fetchDocksLeaveList();
                  break;
                case 'DOCK_LEAVE_DESCRIPTION':
                  fetchDocksLeaveList();
                  break;
                case 'DOCK_LEAVE_DELETED':
                  fetchDocksLeaveList();
                  break;
                  case 'DOCK_OPERATION_DAY':
                  fetchDockList();
                  // Working
                  break;
                  case 'DOCK_DOOR_STATUS':
                    dispatch({
                      type: sagaActions.GET_SCHEDULER_DATA,
                      payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
                        CalendarView: 'DAY' },
                    });
                    break;
                  case 'DOCK_DOOR_TIMESLOTNAME':
                    dispatch({
                      type: sagaActions.GET_SCHEDULER_DATA,
                      payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
                        CalendarView: 'DAY' },
                    });
                    break;
                  case 'DOCK_DOOR_TIMESLOTSTATUS':
                    dispatch({
                      type: sagaActions.GET_SCHEDULER_DATA,
                      payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
                        CalendarView: 'DAY' },
                    });
                    break;
                  case 'DOCK_DOOR_DELETED':
                    dispatch({
                      type: sagaActions.GET_SCHEDULER_DATA,
                      payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
                        CalendarView: 'DAY' },
                    });
                    break;
                default:
                  break;
              }
  }
  
   
}

/**
 * Method is used to captcure socket error and re initate the connection 
 * @param {*} error 
 */
  const onUpdateSocketError = (error) => {
    validateURLandFetchAppointments()
}

/**
 * Method is used to capture socket close
 * @param {*} data 
 */
  const onUpdateSocketClose = (data) => {
  
    //check if user is on same page
    //initiatesocket connection
    // refetch appointment data
    if (data.code !== 1000) {
      validateURLandFetchAppointments()
    }
    
  }
  
  const validateURLandFetchAppointments = () => {
    if (window.location.pathname === '/schedulerweek') {
      const socketRefTemp = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose, datePickerSelectedDate)
    setSocketRef(socketRefTemp)
      dispatch({
        type: sagaActions.GET_SCHEDULER_DATA,
        payload: {
          IDWarehouse: selectedWarehouse?.ID, DateSelected: datePickerSelectedDate,
          CalendarView: 'DAY'
        },
      });

    }
  }
 
  


  /**
   * Function to fetch dock breaks
   */
  const fetchBreakDockList = () => {
    const { apiParams, operationObj } = fetchBreaksDocksListCommonFunc(selectedWarehouse);
    dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
}

  const clearSearchedPOData = () => {
    dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
  }

  useEffect(() => {
    // setItems([])
    // setPoSearchList(null); // Commented now for new WP Schedule flow, check later
    // closeSearchPOForm(); // Commented now for new WP Schedule flow, check later
    if (_.size(poForAppt)) {
      const masterPO = poForAppt[0];
      dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: false });
      // When Selecting PO for Appointment we need to check whether it is New or  Reschedule Appointment
      if (masterPO.ApptScheStatus === "Not Scheduled") {
        setBookApptModelTitle("Book Appointment");
      } else if (masterPO.ApptScheStatus === "Scheduled") {
        setBookApptModelTitle("Reschedule Appointment");
        const itemId = canvasData + '_' + masterPO.PoNumber
        let ifApptExists = _.find(appointmentList, {
          PoNumber: itemId.split("_")[2],
        });
        let foundObject = [];
        if (!_.isEmpty(ifApptExists)) {
          let { PORelationID } = ifApptExists;
          foundObject = _.filter(appointmentList, {
            PORelationID
          });
        }
        if (_.size(poForAppt)) {
          // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: foundObject })
          setRescheduleStateData([...poForAppt])
          setViewPoDetails({ itemId: itemId, viewPO: [...poForAppt] });
        } else {
          console.log('Error in setting viewPoDetails')
        }
        // Commented now for new WP Schedule flow, check later
        // if (_.size(appointmentList)) {
        //   setTimeout(() => {
        //   setRescheduleStateData(null);
        //  }, 100);

        // }
      }
      if (showModal) {
        closeAddMorePOForm();
      }
      if (canvasData && canvasTime) {
        recalculateApptDurationHandler(canvasData, canvasTime)

      } else {
        if (masterPO?.Door) {
          const gid = masterPO?.IDDock + "_" + masterPO?.Door
          recalculateApptDurationHandler(gid, masterPO?.ApptStartTime)
        } else {
          console.log('New Appointment')
        }
      }
    }
  }, [poForAppt]);


  useEffect(() => {
    if (_.size(masterGroups)) {
      // setShouldRender(true);
      // if (shouldRender) {
      //   setTimeout(() => {
      //     highlightCalendarFunction()
      //   }, [0])
      // }
    }
  }, [visibleTimeStart, visibleTimeEnd, shouldRender]);

  useEffect(() => {
    if (_.size(carrierList)) {
      formCarrierListData();
    }
  }, [carrierList]);


  useEffect(() => {
    if (_.size(searchedPOData)) {
      // setViewPoDetails(null)
      setShowSearchError(false);
      setPoSearchList([...searchedPOData]);
      const masterPO = searchedPOData[0];
      if (canvasData && canvasTime) {
        recalculateApptDurationHandler(canvasData, canvasTime)

      } else {
        if (masterPO?.Door) {
          const gid = masterPO?.IDDock + "_" + masterPO?.Door
          recalculateApptDurationHandler(gid, masterPO?.ApptStartTime)
        } else {
          console.log('New Appointment')
        }
      }
    } else {
      setPoSearchList(null);
    }
  }, [searchedPOData]);

  // useEffect(() => {
  //   if (_.size(poForAppt) && showModal) {
  //     if (bookApptModelTitle === 'Book Appointment') {
  //       // onCanvasClickHanlder(canvasData, canvasTime)
  //     } else if (bookApptModelTitle === 'Reschedule Appointment') {
  //       const { viewPO, itemId } = viewPoDetails;
  //       // onCanvasClickHanlder(itemId, viewPO?.startTime)y
  //       // onCanvasClickHanlder(itemId, new Date(viewPO?.ApptStartTime));
  //     }
  //     closeAddMorePOForm()
  //   }
  // }, [poForAppt])

  useEffect(() => {
    if (_.size(wpSchedulerData)) {
      createSchedulerGroups();
    } else {
      // If unable to get in 30 Sec we need display "scheduleError"(AppConstant) message
      setTimeout(() => {
        setShouldRender(true)
        
      }, [30000])
    }
  }, [wpSchedulerData]);

  useEffect(() => {
    if (_.size(appointmentList) || _.size(masterGroups)) {
      dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false });
      dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false });
      // form data from Appointment API to render in calendar
      // after deletimg PO if popup is open, we need to close it
      showViewAppointmentModal && closeViewAppointmentpopup();
      _.size(appointmentList) && formCalendarFormatDataToRender(appointmentList);
      setLoading(false);
      // setApptLoading(false)
      bookApptModelTitle === "Book Appointment" && setShowModal(false);
      if (_.size(appointmentList) && _.size(masterGroups)) {
        // setTimeout(() => {
        //   console.log("🚀 ~ useEffect ~ masterGroups:", appointmentList)
        //   console.log("🚀 ~ useEffect ~ selectedWarehouse:", selectedWarehouse)
        //   setShouldRender(true);
        //   // setAppointmentFormData();
        // }, 100);
      }
    }
  }, [appointmentList, masterGroups]);

  useEffect(() => {
    formHolidayData();
  }, [whMetaLeavesList, validationType]);

  useEffect(() => {
    if (_.size(wpCapacityData)) {
      if (userType === "USER") {
        let IDWarehouse = selectedWarehouse?.ID;
        const ifSameWPDataExists = _.find(wpCapacityData, { ID: IDWarehouse });

        if (ifSameWPDataExists) {
          setWPCapacityDataState(ifSameWPDataExists);
        } else {
          setShouldRender(false)
          console.log(
            "Either Warehouse data is not Present of there is mismatch in WP ID"
          );
        }
      }
    }
  }, [wpCapacityData]);

  useEffect(() => {
    if (showSuccess) {
      if (_.size(poForAppt)) {
        const masterPO = poForAppt[0]
        dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: masterPO?.PoNumber, ReceivingZipCode: masterPO?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : selectedWarehouse?.ID } })
      }
    }
  }, [showSuccess])

  /**
   * This function is used to remove highlighted dock leaves
   */
  const removeHighlightedLeave = () => {
    const elements = document.querySelectorAll('.bgRed');
    if (_.size(elements)) {
      elements.forEach(el => {
        el.classList.remove('bgRed')
      })
    }

  }
  
  /**
   * This function is used to highlight dock leaves
   * It uses DOM manipulation to color the Day calendar sidebar with red color to highlight leaves
   */
  const highlightCalendarLeaveFunction = () => {
    removeHighlightedLeave();
    let ifLeaveToday = []
    if (_.size(whMetaLeavesList) && _.size(groups)) {
      ifLeaveToday = whMetaLeavesList.map(elLeave => {
        if (elLeave?.Status) {
          const visibleTimeStartTemp = moment(visibleTimeStart)
          const LeaveStartTemp = moment(elLeave?.LeaveStart)
           const LeaveEndTemp = moment(elLeave?.LeaveEnd)
          //  check if selected date is between LeaveStart and LeaveEnd
          const isBetweenInclusive = visibleTimeStart.isSameOrAfter(LeaveStartTemp, 'day') && visibleTimeStartTemp.isSameOrBefore(LeaveEndTemp, 'day');
           if (isBetweenInclusive) {
           return elLeave
          }
         }
      })   
      ifLeaveToday = _.compact(ifLeaveToday)
      if (_.size(ifLeaveToday)) {
        ifLeaveToday.forEach(elLeave => {
          const filterGroup = _.filter(groups, { idDock: elLeave?.IDDock })
          if (_.size(filterGroup)) {
              filterGroup.forEach(el => {
                  const selector = `[data-for="${CSS.escape(el.id)}"]`;
                  const elements = document?.querySelectorAll(selector);
                  if (_.size(elements)) {
                    elements.forEach(node => {                      
                      node.closest('.rct-sidebar-row').classList.add('bgRed')
                  
                    })
                  }
              })
          }
        })
      }
    }
  }

  /**
   * This function is used to remove highlighted dock non operational day
   */
 const removeHighlightCalendarNonOperationalFunction = () => {
  const elements = document.querySelectorAll('.wh-closed');
  if (_.size(elements)) {
    elements.forEach(el => {
      el.classList.remove('wh-closed')
    })
  }

}

  /**
   * This function is used to highlight dock non operational day
   * It uses DOM manipulation to color the Day calendar sidebar with grey color to highlight dock non operational day
   */
  const highlightCalendarNonOperationalFunction = () => {
    removeHighlightCalendarNonOperationalFunction();
    let dd = moment(visibleTimeStart).format('dddd'); // Get the current day in full string format
    let dd_day = _.lowerCase(dd); // Convert the day to start with a capital letter

    let filteredArray = [];
    _.forEach(whMetaDocksList, (item) => {
      Object.keys(item).forEach((key) => {
        if (key.toLowerCase() === dd_day && !item[key]) {
          filteredArray.push({ id: item.ID, name: item.Name, [key]: item[key] });
        }
      });
    });

    if (_.size(filteredArray)) {
      filteredArray.forEach(elNonOperational => {
        const filterGroup = _.filter(groups, { idDock: elNonOperational?.id })
        if (_.size(filterGroup)) {
            filterGroup.forEach(el => {
                const selector = `[data-for="${CSS.escape(el.id)}"]`;
                const elements = document?.querySelectorAll(selector);
                if (_.size(elements)) {
                  elements.forEach(node => {                      
                    node.closest('.rct-sidebar-row').classList.add('wh-closed')
                
                  })
                }
            })
        }
      })
    }
  }

  const fetchDocksLeaveList = () => {
    const { apiParams, operationObj } = fetchLeavesDocksListCommonFunc(selectedWarehouse);
    dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
  }

  /**
   * Function to fetch dock list
   */
  const fetchDockList = () => {
    const { apiParams, operationObj } = fetchDockListCommonFunc(selectedWarehouse, cu_id);
    dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
  }

  const formLeaveData = (leaveArray) => {
    if (_.size(leaveArray)) {
      let hArray = [];
      let index = 1;
      leaveArray.forEach((obj) => {
        const { LeaveStart, LeaveEnd, Status, Purpose, IDWarehouse, IDDock } =
          obj;
        if (Status) {
          let mLeaveStart = moment(LeaveStart);
          let mLeaveEnd = moment(LeaveEnd);
          // Loop through each date between the start and end date
          while (
            mLeaveStart.isBefore(mLeaveEnd) ||
            mLeaveStart.isSame(mLeaveEnd, "day")
          ) {
            hArray.push({
              LeaveID: index,
              LeaveDate: mLeaveStart.clone(),
              Purpose,
              IDWarehouse,
              IDDock,
            });
            mLeaveStart = mLeaveStart.add(1, "day");
            index = index + 1;
          }
        }
      });
      return hArray;
    } else {
      console.log("No Holiday's Found");
    }
  };

  const getDisclaimerDock = (idDock) => {

    let disclaimerData = [...disclaimersArray];
    if (wpSchedulerData.length > 0) {
      let dockData = _.find(wpSchedulerData, { dockId: idDock })
      if (dockData) {
        const sTime = moment(dockData.DockStartTime).utc().format('HH:mm A');
        const eTime = moment(dockData.DockEndTime).utc().format('HH:mm A');
        const text = `Dock opens at ${sTime} and closes at ${eTime}`;
        let dockTimeData = _.find(disclaimerData, { type: 'DOCK-TIME' })
        if (dockTimeData) {
          dockTimeData.ID = dockData.dockId;
          dockTimeData.text = text;

        }
        else {
          disclaimerData.push({
            ID: dockData.dockId,
            type: 'DOCK-TIME',
            text: text
          });

        }

        let findIfExists = _.find(disclaimerData, { type: 'Dock-Leave' });
        if (dockData.DockLeaveDetails.length > 0) {

          let leaveList = formLeaveData(dockData.DockLeaveDetails);
          setDockLeaveList(leaveList);
          if (leaveList.length > 0) {
            let textDock = 'Dock is closed on '
            let allDates = ' '
            leaveList.forEach(el => {
              allDates = allDates + moment(el.LeaveDate).utc().format('MM/DD/YYYY') + ','
            })
            textDock = textDock + allDates
            if (!findIfExists) {
              disclaimerData.push({
                type: 'Dock-Leave',
                text: textDock
              });

            } else {
              findIfExists.text = textDock;

            }
          } else {

            if (findIfExists) {
              findIfExists.text = null;
            }
          }
        }
        setDisclaimersArray([...disclaimerData])
      }
    }
    else {
      alert("wait till data load")
    }

    // setParticularFieldValue(...particularFieldValue,{po_number: poForAppt?.PoNumber})
  }

  const formHolidayData = () => {
    if (_.size(whMetaLeavesList)) {
      let hArray = [];
      let index = 1;
      whMetaLeavesList.forEach((obj) => {
        const { LeaveStart, LeaveEnd, Status, Purpose, IDWarehouse, IDDock } =
          obj;
        if (Status) {
          let mLeaveStart = moment(LeaveStart);
          let mLeaveEnd = moment(LeaveEnd);
          // Loop through each date between the start and end date
          while (
            mLeaveStart.isBefore(mLeaveEnd) ||
            mLeaveStart.isSame(mLeaveEnd, "day")
          ) {
            hArray.push({
              LeaveID: index,
              LeaveDate: mLeaveStart.clone(),
              Purpose,
              IDWarehouse,
              IDDock,
            });
            mLeaveStart = mLeaveStart.add(1, "day");
            index = index + 1;
          }
        }
      });
      setHolidayList([...hArray]);
    } else {
      console.log("No Holiday's Found");
    }
  };

  useEffect(() => {
    if (_.size(holidayList)) {
      // Need to Work
      const findIfExists = _.find(disclaimersArray, { type: "HOLDIDAY" });
      if (!findIfExists) {
        let text = "Warehouse is closed on ";
        let allDates = "";
        holidayList.forEach((el) => {
          allDates =
            allDates + " " + moment(el.LeaveDate).utc().format("MM/DD/YYYY");
        });
        text = text + allDates;
        const temp = [
          {
            type: "HOLDIDAY",
            text: text,
          },
          ...disclaimersArray,
        ];

        setDisclaimersArray([...temp]);
      }
    }
    if (!_.isEmpty(wpCapacityDataState)) {
      const { WHStartTime, WHEndTime, ID } = wpCapacityDataState;

      const sTime = moment(WHStartTime).utc().format("HH:mm");
      const eTime = moment(WHEndTime).utc().format("HH:mm");
      const text = `This warehouse opens at ${sTime} and closes at ${eTime}`;
      const findIfExists = _.find(disclaimersArray, { type: "WP", ID });

      if (!findIfExists) {
        setDisclaimersArray([
          ...disclaimersArray,
          {
            ID: ID,
            type: "WP",
            text: text,
          },
        ]);
      }
    }
  }, [holidayList, wpCapacityDataState]);

  // const setAppointmentFormData = () => {
  //   if (bookApptModelTitle === "Book Appointment") {
  //     // openSearchPOForm(); 
  //   } else if (bookApptModelTitle === "Reschedule Appointment") {
  //     setRescheduleStateData();
  //   }
  // };
  // This functions set all data in component to perform Reschedule
  const setRescheduleStateData = (data) => {
    let masterPO = poForAppt[0];
    // if (!_.isEmpty(viewPoDetails)) {
    // masterPO = viewPoDetails?.viewPO;    
    // }
    if (!_.isEmpty(viewPoDetails)) {
      masterPO = viewPoDetails?.viewPO[0];
    }
    let foundObject = _.filter(appointmentList, { PORelationID: masterPO?.PoNumber });
    setViewPoDetails({
      itemId: `${masterPO?.IDDock}_${masterPO?.Door}_${masterPO?.PoNumber}`,
      viewPO: [...foundObject],
    });
    const filterDockData = _.find(wpSchedulerData, {
      dockId: masterPO?.IDDock,
    });
    setStartDateState(
      moment(masterPO?.ApptStartDate).format(
        AppConstant.commonStrings.MM_DD_YYYY_dateFormat
      )
    );
    setEndDateState(
      moment(masterPO?.ApptEndDate).format(
        AppConstant.commonStrings.MM_DD_YYYY_dateFormat
      )
    );
    setStartTimeState(moment(masterPO?.ApptStartTime));
    setEndTimeState(moment(masterPO?.ApptEndTime));
    setCanvasData(masterPO?.IDDock + "_" + masterPO?.Door);
    setSelectedCarrier({
      label: masterPO?.CarrierName,
      value: masterPO?.IDCarrier,
    });
    setParticularFieldValue({
      carrier_name: masterPO?.IDCarrier,
      dock: masterPO?.DockName,
      po_number: masterPO?.PoNumber,
      start_date: moment(masterPO?.ApptStartDate).format("YYYY-MM-DD"),
      end_date: masterPO?.ApptEndDate,
      ReceivingZipCode: masterPO?.ReceivingZipCode,
      WarehouseName: masterPO?.WarehouseData?.Name || "-",
      start_time: moment(masterPO?.ApptStartTime).toString(),
      end_time: masterPO?.ApptEndTime,
      WarehousePPH: wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0,
      DockPPH: filterDockData?.PPH,
    });
    if (appointmentConfirmation) {
      dispatch({
        type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,
        payload: false,
      });
    } else {
      if (!showSearchPoModel) openCreateForm(filterDockData, false);
    }
  };

  const formTime = (dateStr, timeStr) => {
    // Combine the date and time strings
    const combinedDateTimeStr = `${dateStr} ${timeStr}`;

    // Create a moment object with the combined date and time
    const momentObj = moment(combinedDateTimeStr).tz(
      selectedWarehouse?.TimeZone
    );

    // Create a JavaScript Date object from the moment object
    const newDate = momentObj.toDate();
    return newDate;
  };

  const formCalendarFormatDataToRender = (data) => {
    const formattingData = data.map((el, i) => {
      const filteredData = _.find(groups, { id: el.IDDock + "_" + el.Door });
      const findAppt = _.filter(data, { PORelationID: el.PORelationID })
      let poNum = '';
      if (_.size(findAppt)) {
        const fieldData = formParticularFieldValue(findAppt);
        poNum = fieldData?.po_number
      }
      if (filteredData) {
        // Below is done to resolve timezone issue
        const apptStartTimeDD = el?.ApptStartTime.slice(0, 10);
        const apptStartTimeHH = el?.ApptStartTime.slice(11, 19);

        const apptEndTimeDD = el?.ApptEndTime.slice(0, 10);
        const apptEndTimeHH = el?.ApptEndTime.slice(11, 19);

        const finalStartTime = formTime(apptStartTimeDD,apptStartTimeHH)
        const finalEndTime = formTime(apptEndTimeDD, apptEndTimeHH)
        // Above is done to resolve timezone issue
        
        return {
          id: filteredData.id + "_" + el.PoNumber,
          group: filteredData.id,
          title: poNum ? poNum : el.PoNumber,
          masterTitle: el.PoNumber,
          start_time: moment(finalStartTime),
          end_time: moment(finalEndTime),
          // background: '#C9F8D6',
          itemProps: {
            style: el?.ApptScheStatus.toLowerCase() ==='completed' ? completedItemClass : el?.ApptScheStatus.toLowerCase() ==='scheduled' && el?.Rescheduled ? rescheduledItemClass : scheduledItemClass
          }
        };
      } else {
        return;
      }
    });
    // to remove false or null values if any
    const compactArray = _.compact(formattingData);
    // remove duplicates from array using title key
    const uniqueArray = _.uniqBy(compactArray, 'title');
    setItems([...uniqueArray]);
    return;
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="customDatePickerInput" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const TUSDatePickerInput = forwardRef(({ value, onClick }, ref) => {
    let dateStr = "HH:MM";
    if (!value.includes("mm")) {
      const getHour = ("0" + moment(value).hour()).slice(-2);
      const getMin = ("0" + moment(value).minutes()).slice(-2);
      dateStr = getHour + ":" + getMin;
    }

    return (
      <button className="customDatePickerInput" onClick={onClick} ref={ref}>
        {dateStr}
      </button>
    );
  });



  // const getFormFieldData = (data, name, control, inputs) => {
  //   if (name === "start_date") {
  //     const parts = inputs?.start_time.split(" ");
  //     // Extract the time component
  //     const timeStr = parts[4];
  //     const newDate = formTime(data, timeStr);
  //     recalculateApptDurationHandler(canvasData, newDate);
  //   }
  //   if (name === "start_time") {
  //     // let nDataTime = data
  //     const parts = data.split(" ");
  //     // Extract the time component
  //     const timeStr = parts[4];
  //     const newDate = formTime(
  //       moment(inputs.start_date).format("YYYY-MM-DD"),
  //       timeStr
  //     );
  //     recalculateApptDurationHandler(canvasData, newDate);
  //   }
  //   if (name === "dock") {
  //     const newDate = formTime(
  //       moment(inputs.start_date).format("YYYY-MM-DD"),
  //       inputs?.start_time
  //     );
  //     recalculateApptDurationHandler(canvasData, newDate);
  //   }
  // };

  const getFormFieldData = (data, name, control, inputs) => {
    if (name === 'start_date') {
      if (moment(data).isBefore(getCurrentDate())) {
        showAlert(toast.TYPE.ERROR, "Please select a date on or after today's date.", themeSelected);
        setParticularFieldValue({ 'start_date': inputs?.start_date })
        return;
      }
      setParticularFieldValue({ 'dock': null })
    }
    if (name === 'start_time' && inputs?.start_date) {

      const parts = data.split(' ');
      // Extract the time component
      const timeStr = parts[4];
      const newDate = formTime(inputs.start_date, timeStr)
      setStartTimeState(moment(newDate))
      setParticularFieldValue({ 'dock': null, start_time: moment(newDate).toString() })

    }
    if (name === 'dock') {
      let splitData = ''
      if (data.includes('_')) {
        splitData = data.split('_')
      }

      _.size(splitData) && getDisclaimerDock(splitData[0])

      let timeStr = "";

      if (inputs?.start_time.includes("T")) {
        let newTime = moment(inputs?.start_time).tz(selectedWarehouse
          ?.TimeZone).format("HH:mm");



        timeStr = newTime;
      }
      else {
        const parts = data.split(' ');
        // Extract the time component
        timeStr = parts[4];
      }



      const newDate = formTime(inputs.start_date, timeStr)
      recalculateApptDurationHandler(data, newDate)
    }
  };

  const openAddMorePOForm = () => {
    setShowAddPoModel(true);
  };
  const closeAddMorePOForm = () => {
    // setEditData({});
    setShowAddPoModel(false);
  };
  const openAddMorePO = () => {
    // if (_.size(poForAppt)) {
    const masterPO = poForAppt[0];
    return (
      <AddMorePOPopupSS
        closeSendMailpopup={closeAddMorePOForm}
        zipCode={selectedWarehouse?.Zip}
        actionHandler={(data, type) => {
          onAddRemoveHandler(data, type);
        }}
        initateLoading={initiateMultiplePO}
        poForAppt={poForAppt}
      />
    );
    // }
  };

  const [showSendMailModal, setShowSendEmailModal] = useState(false);
  const openSendEmailForm = () => {
    setShowSendEmailModal(true);
  };
  const closeSendMailpopup = () => {
    // setEditData({});
    setShowSendEmailModal(false);
  };
  const openSendEmail = () => {
    return (
      <SendEmailSS
        closeSendMailpopup={closeSendMailpopup}
        formFieldList={formFieldListViewAppointment}
        poDetails={viewPoDetails}
      ></SendEmailSS>
    );
  };
  const showSendEmailPopup = () => {
    const popupObj = {
      id: "createAppointment",
      modalLabel: "sendEmail",
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: "md",
      title: "Send Email",
      bodyText: openSendEmail(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.comments.cancelCommentButtonTitle,
        },
        btn2: {
          show: true,
          text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
        },
      },
    };

    return (
      <SSPopup
        {...popupObj}
        popupBtnHandler={(text) => () => ""}
        closepopup={closeSendMailpopup}
        themeSelected={themeSelected}
        showModal={showSendMailModal}
      />
    );
  };
  const openCreateForm = (groupId, isRescheduleCaseType) => {
    let disclaimerData = [...disclaimersArray];
    if (wpSchedulerData.length > 0) {
      let dockData = _.find(wpSchedulerData, { dockId: groupId?.dockId });
      const sTime = moment(dockData.DockStartTime).utc().format("HH:mm A");
      const eTime = moment(dockData.DockEndTime).utc().format("HH:mm A");
      const text = `This Dock opens at ${sTime} and closes at ${eTime}`;
      let dockTimeData = _.find(disclaimerData, { type: "DOCK-TIME" });
      if (dockTimeData) {
        dockTimeData.ID = dockData.dockId;
        dockTimeData.text = text;
      } else {
        disclaimerData.push({
          ID: dockData.dockId,
          type: "DOCK-TIME",
          text: text,
        });
      }

      let findIfExists = _.find(disclaimerData, { type: "Dock-Leave" });

      if (dockData.DockLeaveDetails.length > 0) {
        let leaveList = formLeaveData(dockData.DockLeaveDetails);
        setDockLeaveList(leaveList);
        if (leaveList.length > 0) {
          let textDock = "This Dock is closed on ";
          let allDates = " ";
          leaveList.forEach((el) => {
            allDates =
              allDates + moment(el.LeaveDate).utc().format("MM/DD/YYYY") + ",";
          });
          textDock = textDock + allDates;
          if (!findIfExists) {
            disclaimerData.push({
              type: "Dock-Leave",
              text: textDock,
            });
          } else {
            findIfExists.text = textDock;
          }
        } else {
          if (findIfExists) {
            findIfExists.text = null;
          }
        }
      }
      setDisclaimersArray([...disclaimerData]);
      setIsRescheduleCase(isRescheduleCaseType)
      setShowModal(true);
    } else {
      alert("wait till data load");
    }

    // setParticularFieldValue(...particularFieldValue,{po_number: poForAppt?.PoNumber})
  };

  const closeCurrentpopup = () => {
    // setEditData({});
    // setSelectedCarrier(null);
    setShowModal(false);
  };

  const formCarrierListData = () => {
    if (_.size(carrierList)) {
      const carrierData = carrierList.map((el) => {
        return {
          value: el.carrier_id,
          label: el.carrier_name,
        };
      });
      setCarrierListState([...carrierData]);
    } else {
      setCarrierListState([]);
    }
  };

  const onCarrierSelcted = (props) => {
    if (props.length > 2) {
      dispatch({
        type: sagaActions.SS_GET_CARRIER_LIST,
        payload: { carrier: props },
      });
    }
  };

  const getTimeslotList = () => {
    if (_.size(groups) && _.size(searchedPOData)) {
      const filteredData = groups.filter((el) => searchedPOData && searchedPOData[0].IDDock === el?.idDock)
      return filteredData.map((ele, index) => {
        return (
          <option key={ele.id} value={ele.id}>
            {ele.title}
          </option>
        );
      })
    }
  }

  const onChangeValue = (props) => {
    setSelectedCarrier(props);
  };

  const optionsFunc = (test) => { };

  const formatCreateLabel = (props) => { };

  const formFieldListOld = [
    {
      name: "po_number",
      type: "text",
      size: "col-sm-6",
      position: 1,
      title: AppConstant.poManagement.tableHeader.po,
      formValidation: {
        required: true,
        // addIcon: true,
        // iconHandler: openAddMorePOForm
      },
      disabledField: false,
    },
    {
      name: "ReceivingZipCode",
      type: "text",
      size: "col-sm-6",
      position: 2,
      title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip,
      formValidation: {
        required: true,
        lengthValidation: {
          customError:
            AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
              .zipCodeErrorMessage + ". ",
        },
      },
      disabledField: true,
    },
    {
      name: "carrier_name",
      type: "select_with_input_search_add_button",
      size: "col-sm-6 mb-3 fs-6",
      parentClass: "inputTitle",
      componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
      inputTitle: "Select Carrier",
      position: 3,
      title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
      customStylesLight: customStylesLight,
      placeholderText: "Select a carrier from the list",
      onInputChange: onCarrierSelcted,
      // onChange: onChangeValue,
      defaultValue: !_.isEmpty(selectedCarrier)
        ? selectedCarrier
        : "Select a carrier from the list",
      isLoading: false,
      isClearable: true,
      isSearchable: true,
      options: carrierListState,
      formatCreateLabel: formatCreateLabel,
      loadingMessage: "Please wait loading....",
      formValidation: {
        required: true,
      },
    },
    // Requested by Rajest to comment
    // {
    //   name: "WarehouseName",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 3,
    //   title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.warehouseNameTitleStr,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    // {
    //   name: "WarehousePPH",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 4,
    //   title: AppConstant.reports.truckUnloadSummaryReport.warehouse + ' ' + AppConstant.smartScheduler.dockCapacity.tableHeaders.palletsPerHour,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    {
      name: "dock",
      type: "text",
      size: "col-sm-6",
      position: 4,
      title: AppConstant.poManagement.tableHeader.dock,
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    // {
    //   name: "DockPPH",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 6,
    //   title: AppConstant.reports.adfatReport.dropdownLabels.dock + ' ' + AppConstant.smartScheduler.dockCapacity.tableHeaders.palletsPerHour,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    {
      size: "col-sm-6 mt-1",
      name: "start_date",
      type: "date",
      customDatePickerConfig: {
        calendarType: currentCalendarType,
        placeholder: "",
        selectedDate: startDateState,
        startDate: getCurrentDate(),
        maxDate: "NA",
        name: "start_date",
        customInput: <ExampleCustomInput />,
        showTimeSelect: false,
      },
      position: 5,
      value: startDateState,
      title: AppConstant.reports.adfatReport.dropdownLabels.startDate,
      formValidation: {
        required: true,
      },
    },
    {
      size: "col-sm-6 mt-1",
      name: "start_time",
      type: "custom-time-picker",
      customDatePickerConfig: {
        selectedDate: startTimeState,
        name: "start_time",
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 30,
        timeCaption: "Time",
        customInput: <TUSDatePickerInput value={startTimeState} />,
      },
      position: 6,
      value: startTimeState,
      title: AppConstant.smartScheduler.breaksForm.tableHeaders.startTime,
      formValidation: {
        required: true,
      },
    },
    // Requested by Rajest to comment
    // {
    //   size: "col-sm-6 mt-1",
    //   name: "end_date",
    //   type: "custom-date-picker",
    //   customDatePickerConfig: {
    //     calendarType: currentCalendarType,
    //     placeholder: '',
    //     selectedDate: endDateState,
    //     startDate: endDateState,
    //     name: "end_date",
    //     customInput: <ExampleCustomInput />,
    //     disableCalendar: startDateState ? false : true,
    //     showTimeSelect: false
    //   },
    //   position: 7,
    //   value: endDateState,
    //   title:
    //     AppConstant.reports.adfatReport.dropdownLabels.endDate,
    //   formValidation: {
    //     required: true,
    //   }
    // },
    // {
    //   size: "col-sm-6 mt-1",
    //   name: "end_time",
    //   type: "custom-time-picker",
    //   customDatePickerConfig: {
    //     placeholder: 'HH:MM',
    //     selectedDate: endTimeState,
    //     startDate: endTimeState,
    //     name: "end_time",
    //     showTimeSelect: true,
    //     showTimeSelectOnly: true,
    //     timeIntervals: 30,
    //     timeCaption: "Time",
    //     minTime: startTimeState,
    //     customInput: <TUSDatePickerInput value={endTimeState} />,
    //   },
    //   position: 8,
    //   value: endTimeState,
    //   title: AppConstant.smartScheduler.breaksForm.tableHeaders.endTime,
    //   formValidation: {
    //     required: true,
    //   }
    // }
  ];
  const formFieldList = [
    {
      name: "po_number",
      type: "text",
      size: "col-sm-6",
      position: 1,
      title: AppConstant.poManagement.tableHeader.po,
      formValidation: {
        required: true,
        addIcon: isRescheduleCase,
        iconHandler: openAddMorePOForm
      },
      disabledField: true,
    },
    {
      name: "ReceivingZipCode",
      type: "text",
      size: "col-sm-6",
      position: 2,
      title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip,
      formValidation: {
        required: true,
        lengthValidation: {
          customError:
            AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
              .zipCodeErrorMessage + ". ",
        },
      },
      disabledField: true,
    },
    {
      size: "col-sm-6 mt-1",
      name: "start_date",
      type: "date",
      customDatePickerConfig: {
        calendarType: currentCalendarType,
        placeholder: "",
        selectedDate: startDateState,
        startDate: getCurrentDate(),
        maxDate: _.size(poForAppt) ? poForAppt[0]?.DueDate : 'NA',
        name: "start_date",
        customInput: <ExampleCustomInput />,
        showTimeSelect: false,
      },
      position: 3,
      value: startDateState,
      title: AppConstant.reports.adfatReport.dropdownLabels.startDate,
      formValidation: {
        required: true,
      },
    },
    {
      size: "col-sm-6 mt-1",
      name: "start_time",
      type: "custom-time-picker",
      customDatePickerConfig: {
        selectedDate: startTimeState,
        name: "start_time",
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 30,
        timeCaption: "Time",
        customInput: <TUSDatePickerInput value={startTimeState} />,
      },
      position: 4,
      value: startTimeState,
      title: AppConstant.smartScheduler.breaksForm.tableHeaders.startTime,
      formValidation: {
        required: true,
      },
    },
    {
      name: "carrier_name",
      type: "select_with_input_search_add_button",
      size: "col-sm-6 mb-3 fs-6",
      parentClass: "inputTitle",
      componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
      inputTitle: "Select Carrier",
      position: 5,
      title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
      customStylesLight: customStylesLight,
      placeholderText: "Select a carrier from the list",
      onInputChange: onCarrierSelcted,
      // onChange: onChangeValue,
      defaultValue: !_.isEmpty(selectedCarrier)
        ? selectedCarrier
        : "Select a carrier from the list",
      isLoading: false,
      isClearable: true,
      isSearchable: true,
      options: carrierListState,
      formatCreateLabel: formatCreateLabel,
      loadingMessage: "Please wait loading....",
      formValidation: {
        required: true,
      },
    },
    // Requested by Rajest to comment
    // {
    //   name: "WarehouseName",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 3,
    //   title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.warehouseNameTitleStr,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    // {
    //   name: "WarehousePPH",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 4,
    //   title: AppConstant.reports.truckUnloadSummaryReport.warehouse + ' ' + AppConstant.smartScheduler.dockCapacity.tableHeaders.palletsPerHour,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    {
      name: "dock",
      type: "select",
      size: "col-sm-6",
      position: 6,
      title: 'Timeslot',
      formValidation: {
        required: true,
      },
      getSelectData: getTimeslotList,
    },

  ];

  const cancelbtndata = {
    show: true,
    text: AppConstant.comments.resetbutton,
  };

  const submitbtnData = {
    show: true,
    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
      .saveButtonTitle,
  };

  const searchbtnData = {
    show: true,
    text: "Search",
  };

  const getSummitedFormData = (data) => {
    //  setApptLoading(true);
    // dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true });
    dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: true });
    let allPOs = "";
    let payload_door = "";
    let payload_IDDock = "";
    let CRUDTYPE = "";
    let masterPO = poForAppt[0];
    let IDWarehouse = masterPO?.IDWarehouse;
    let ReceivingZipCode = masterPO?.ReceivingZipCode;

    if (bookApptModelTitle === "Book Appointment") {
      CRUDTYPE = "I";
      // while fresh appointment booking
      payload_door = canvasData.split("_")[1];
      payload_IDDock = canvasData.split("_")[0];
      if (_.size(poForAppt)) {
        masterPO = poForAppt[0];
        poForAppt.forEach((el) => {
          allPOs = allPOs + el.PoNumber + ",";
          return;
        });
      }
    } else if (bookApptModelTitle === "Reschedule Appointment") {
      CRUDTYPE = "R";
      // while rescheduling appointment
      const { viewPO } = viewPoDetails;
      poForAppt.forEach((el) => {
        allPOs = allPOs + el.PoNumber + ",";
        return;
      });
      payload_door = data?.dock?.split("_")[1];
      payload_IDDock = data?.dock?.split("_")[0];
      if (!allPOs.includes(",")) {
        allPOs = allPOs + ",";
      }

      IDWarehouse = viewPO[0]?.IDWarehouse;
      ReceivingZipCode = viewPO[0]?.ReceivingZipCode;
    }

    const newItemPayload = {
      PoNumber: allPOs,
      IDWarehouse,
      ReceivingZipCode,
      ApptStartDate: moment(data?.start_date),
      ApptStartTime: moment(data?.start_time),
      ApptEndDate: moment(endDateState),
      ApptEndTime: moment(endTimeState),
      ApptStatus: 1,
      Door: payload_door,
      ApptCreatedBy: cu_id,
      IDDock: payload_IDDock,
      IDCarrier: data?.carrier_name,
      CRUDTYPE: CRUDTYPE,
      DeletedPos: "",
      userType: userType,
      "vehicleType":vehicleAndLoadType && vehicleAndLoadType.vehicleType ? vehicleAndLoadType.vehicleType : "", 
      "orderType": vehicleAndLoadType && vehicleAndLoadType.loadType ? vehicleAndLoadType.loadType : "", 
    };
    if (CRUDTYPE==="R") {
      newItemPayload.vehicleType=masterPO?.vehicleType;
      newItemPayload.orderType=masterPO?.orderType
  }
    dispatch({ type: sagaActions.ADD_APPOINTMENT, payload: newItemPayload });
  };

  const createForm = () => {
    return (
      <CustomDynamicForm
        formFieldList={formFieldList}
        sort={true}
        submitbtn={submitbtnData}
        themeSelected={themeSelected}
        getFormFieldData={getFormFieldData}
        getSummitedFormData={getSummitedFormData}
        // editFormData={editData}
        loading={intiateAppointmentLoading}
        resetFormData={resetFormData}
        setParticularFieldValue={particularFieldValue}
        disclaimer={true}
        disclaimersArray={disclaimersArray}
        customDisableSave={customDisableSave}
      />
    );
  };

  // const showBookAppointmentPopup = () => {
  //   const popupObj = {
  //     id: "createAppointment",
  //     modalLabel: "createAppointment",
  //     showClose: true,
  //     showfooter: false,
  //     showHeader: true,
  //     sendDriverNotification: false,
  //     modalSize: "lg",
  //     title: bookApptModelTitle,
  //     bodyText: createForm(),
  //     subText: "",
  //     keyboard: false,
  //     backdrop: "static",
  //     footerBtn: {
  //       btn1: {
  //         show: true,
  //         text: AppConstant.comments.cancelCommentButtonTitle,
  //       },
  //       btn2: {
  //         show: true,
  //         text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
  //           .saveButtonTitle,
  //       },
  //     },
  //   };

  //   return (
  //     <SSPopup
  //       {...popupObj}
  //       popupBtnHandler={(text) => () => ""}
  //       closepopup={closeCurrentpopup}
  //       themeSelected={themeSelected}
  //       showModal={showModal}
  //     />
  //   );
  // };

  const getAssociatedPos = () => {
    const { viewPO } = viewPoDetails;
    if (_.size(viewPO)) {
      const fieldData = formParticularFieldValueForAssociatedPO(viewPO)
      return fieldData?.po_number
    } else {
      return null
    }
  }

  const formFieldListViewAppointment = [
    {
      type: "string",
      size: "col-sm-12",
      position: 1,
      name: "Po Number",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.PoNumber : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 1,
      name: AppConstant.poManagement.tableHeader.associatedPo,
      value: getAssociatedPos(),
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 2,
      name: "Dock",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.DockName : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 3,
      name: "Commodity",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.Commodity : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 4,
      name: "Buyer",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.BuyerName : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 5,
      name: "Vendor",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.VendorName : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 6,
      name: "Quantity",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.Quantity : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 7,
      name: "Cases",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.ItemsCases : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 8,
      name: "Pallets",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.Pallets : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 9,
      name: "Weight",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.Weight : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 10,
      name: "Warehouse",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.WarehouseData?.Name : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 10,
      name: "ZipCode",
      value: _.size(viewPoDetails?.viewPO) ? viewPoDetails?.viewPO[0]?.ReceivingZipCode : '-',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    {
      type: "string",
      size: "col-sm-12",
      position: 11,
      name: "Appointment Start",
      value: _.size(viewPoDetails?.viewPO) ? moment(viewPoDetails?.viewPO[0]?.ApptStartTime).format(
        "MM-DD-YYYY HH:mm"
      ) : '--',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
    // Requested by Rajest to comment
    // {
    //   type: "string",
    //   size: "col-sm-12",
    //   position: 12,
    //   name: "Appointment End",
    //   value: moment(viewPoDetails?.viewPO?.endTime).format('DD/MM/YYYY HH:mm A'),
    //   formValidation: {
    //     required: true,
    //   },
    //   disabledField: true
    // },
    {
      type: "string",
      size: "col-sm-12",
      position: 13,
      name: "Due Date",
      value: _.size(viewPoDetails?.viewPO) ? moment(viewPoDetails?.viewPO[0]?.DueDate).format("MM-DD-YYYY") : '--',
      formValidation: {
        required: true,
      },
      disabledField: true,
    },
  ];

  const viewAppointmentPopupBtns = [
    {
      show: true,
      text: "Delete",
      handler: (e, type) => popupBtnHandler(e, type),
    },
    {
      show: true,
      text: "Reschedule",
      handler: (e, type) => popupBtnHandler(e, type),
    },
    {
      show: true,
      text: "Email Appointment",
      handler: (e, type) => popupBtnHandler(e, type),
    },
  ];

  const openViewAppointmentForm = () => {
    setShowViewAppointmentModal(true);
  };
  const closeViewAppointmentpopup = () => {
    // setEditData({});
    setShowViewAppointmentModal(false);
  };
  const openViewAppointmentPopup = () => {
    return (
      <ViewAppointmentPopupSS
        closeSendMailpopup={closeViewAppointmentpopup}
        formFieldList={formFieldListViewAppointment}
        footerBtn={viewAppointmentPopupBtns}
        initateLoading={initateLoading}
        timezone={selectedWarehouse?.TimeZone}
      />
    );
  };

  const popupBtnHandler = (e, type) => {
    // const { viewPO, itemId } = viewPoDetails
    switch (type) {
      case "Reschedule":
        const { viewPO } = viewPoDetails;
        dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [...viewPO] })
        navigate('/bookappointments', { state: { type: 'rescheduling' } });
        //commented due to new ui start
        // setBookApptModelTitle("Reschedule Appointment");
        // closeViewAppointmentpopup();
        // const { viewPO } = viewPoDetails;
        // if (!_.isEmpty(viewPoDetails) && _.size(viewPO)) {
        //   dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: viewPO })
        //   dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
        //   let masterPO = viewPO[0];
        //   const { IDDock, Door } = masterPO
        //   const gid = IDDock + '_' + Door
        //   setRescheduleStateData(viewPoDetails);
        //   openSearchPOForm(gid, true)
        // }
        //commented due to new ui end

        // const filterDockData = _.find(wpSchedulerData, {
        //   dockId: viewPO[0]?.IDDock,
        // });

        // openCreateForm(filterDockData, true)
        break;
      case "Delete":
        setShowDeletePoApptModel(true);
        break;
      case "Email Appointment":
        openSendEmailForm();
        break;

      default:
        break;
    }
  };

  const showAppointmentDetails = () => {
    const popupObj = {
      id: "viewAppointment",
      modalLabel: "viewAppointment",
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: "md",
      title: "Appointment Details",
      bodyText: openViewAppointmentPopup(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: false,
          text: AppConstant.comments.cancelCommentButtonTitle,
        },
        btn2: {
          show: false,
          text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
        },
      },
    };

    return (
      <SSPopup
        {...popupObj}
        popupBtnHandler={(text) => () => ""}
        closepopup={closeViewAppointmentpopup}
        themeSelected={themeSelected}
        showModal={showViewAppointmentModal}
      />
    );
  };

  const calculteEndTimePPH = (wpPPH, dockPPH) => {
    let totalPallets = 0;
    // poForAppt depends on Book Appointment or

    if (bookApptModelTitle === "Book Appointment") {
      poForAppt.forEach((el) => {
        totalPallets = totalPallets + parseFloat(el?.Pallets);
        return;
      });
    } else if (bookApptModelTitle === "Reschedule Appointment") {
      viewPoDetails?.viewPO?.forEach((el) => {
        totalPallets = totalPallets + parseFloat(el?.Pallets);
        return;
      });
    }

    let calcPPHhour = "";
    let calcPPHmin = "";
    if (dockPPH) {
      calcPPHhour = totalPallets / dockPPH;
      calcPPHmin = totalPallets % dockPPH;
    } else {
      calcPPHhour = totalPallets / wpPPH;
      calcPPHmin = totalPallets % wpPPH;
    }
    return {
      calcPPHhour: calcPPHhour,
      calcPPHmin: calcPPHmin,
    };
  };
  const onItemClickHandler = (itemId, e, time) => {

    e.stopPropagation();
    let ifApptExists = _.find(appointmentList, {
      PoNumber: itemId.split("_")[2],
    });
    let foundObject = [];
    let gid = '';
    if (!_.isEmpty(ifApptExists)) {
      let { PORelationID, IDDock, Door } = ifApptExists;
      gid = IDDock + '_' + Door
      foundObject = _.filter(appointmentList, {
        PORelationID
      });
    }
    if (_.size(foundObject)) {
      dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: foundObject })
      dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: foundObject })
      setViewPoDetails({ itemId: itemId, viewPO: [...foundObject] });
      // openViewAppointmentForm();
    } else {
      console.log('Error in setting viewPoDetails')
    }
  };

  const dateHeaderRenderer = ({ headerContext, width, height, style }) => {
    const { intervalText } = headerContext;

    const isHoliday = holidayList.some((holiday) =>
      intervalText.isSame(holiday, "day")
    );

    // const isWeekend = weekends.includes(intervalText.day());

    const backgroundColor = isHoliday ? "red" : true ? "yellow" : "white";

    return (
      <div style={{ ...style, backgroundColor }} className="custom-header">
        {intervalText.format("ddd")}
      </div>
    );
  };

  const holidayValidationHandler = (IDDock, leaveDate) => {
    // Check validationType if WP or DOCK
    const mLeaveDate = moment(leaveDate).format(YYYY_MM_DD_FORMAT);
    if (validationType === "WP") {
      const allLeaves = holidayList.map((el) =>
        moment(el.LeaveDate).format(YYYY_MM_DD_FORMAT)
      );
      if (allLeaves.includes(mLeaveDate)) {
        return {
          status: true,
          message: `Please choose a different date for your appointment as selected date is a designated holiday and booking is not available.`,
        };
      } else {
        return {
          status: false,
          message: "",
        };
      }
    } else if (validationType === "DOCK") {
      const allLeaves = dockLeaveList.map((el) =>
        moment(el.LeaveDate).utc().format(YYYY_MM_DD_FORMAT)
      );

      if (allLeaves.includes(mLeaveDate)) {
        return {
          status: true,
          message: `Please choose a different date for your appointment as selected date is a designated holiday and booking is not available.`,
        };
      } else {
        return {
          status: false,
          message: "",
        };
      }
    }
  };

  const WPCapacityValidationHandler = (IDDock, startTime) => {
    // Check validationType if WP or DOCK
    if (validationType === "WP") {
      const { WHStartTime, WHEndTime } = wpCapacityDataState;
      const dTime = startTime.format("HH:mm:ss");

      const sTime = moment(WHStartTime).utc().format("HH:mm:ss");
      const eTime = moment(WHEndTime).utc().format("HH:mm:ss");
      const isTimeBetween = dTime >= sTime && dTime <= eTime;
      // Commented code due - WP-2397
      // if (isTimeBetween) {
      //   return {
      //     status: false,
      //     message: "",
      //   };
      // } else {
      //   return {
      //     status: true,
      //     message: `Please choose a timeslot that is between dock start and end time.`,
      //   };
      // }
      return {
        status: true,
        message: `Please choose a timeslot that is between dock start and end time.`,
      };
    } else if (validationType === "DOCK") {

      let ifDockExists = _.find(wpSchedulerData, { dockId: IDDock });
      if (ifDockExists) {
        const { DockStartTime, DockEndTime } = ifDockExists;
        const dTime = startTime.utc().format("HH:mm:ss");
        const sTime = moment(DockStartTime).utc().format("HH:mm:ss");
        const eTime = moment(DockEndTime).utc().format("HH:mm:ss");
        // Commented code due - WP-2397
        // const isTimeBetween = dTime >= sTime && dTime <= eTime;
        // if (isTimeBetween) {
        //   return {
        //     status: false,
        //     message: "",
        //   };
        // } else {
        //   return {
        //     status: true,
        //     message: `Please choose a different time that lies between Dock Start and End time`,
        //   };
        // }

        return {
          status: true,
          message: `Please choose a different time that lies between Dock Start and End time`,
        };
      } else {
        console.log("Dock does not exist in dock capacity data");
      }
    }
  };

  const appointmentValidations = (groupId, time) => {
    let gid = groupId;
    let pStartTime = moment(time).tz(selectedWarehouse?.TimeZone); // might be reduadant code
    const filteredData = _.find(groups, { id: gid });
    const filterDockData = _.find(wpSchedulerData, {
      dockId: gid.split("_")[0] || 0,
    });
    const clickedTime = moment(time).startOf("day").toDate();
    if (_.size(holidayList)) {
      const { status, message } = holidayValidationHandler(
        gid?.split("_")[0],
        moment(pStartTime)
      );
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        setCustomDisableSave(true);
        return false;
      }
    }
    // Check if WPCapacityData exist then perform WP start Start and end time validation
    if (!_.isEmpty(wpCapacityDataState)) {
      const { status, message } = WPCapacityValidationHandler(
        gid?.split("_")[0],
        moment(pStartTime)
      );
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        setCustomDisableSave(true);
        return false;
      }
    }
    // const filterDockData = _.find(wpSchedulerData, {
    //   dockId: gid.split("_")[0] || 0,
    // });

    const { calcPPHhour, calcPPHmin } = calculteEndTimePPH(
      parseFloat(wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0),
      parseFloat(filterDockData.PPH)
    );
    let mpStartTime = moment(pStartTime)
      .add(calcPPHhour, "hours")
      .add(calcPPHmin, "minutes");
    // Validate Appointement
    const { status, message } = handleAppointmentBookingValidation({
      startTime: pStartTime,
      endTime: mpStartTime,
      groupId: gid,
    });
    if (status) {
      showAlert(toast.TYPE.WARNING, message, themeSelected);
      return false
    }
    return true
  }
  
  const recalculateApptDurationHandler = (groupId, time, e, data) => {
    let obj = null
    let gid = groupId;
    let pStartTime = moment(time).tz(selectedWarehouse?.TimeZone); // might be reduadant code
    // Check if holiday List exist then perform holiday validation
    setCustomDisableSave(false);
    const filteredData = _.find(groups, { id: gid });
    const filterDockData = _.find(wpSchedulerData, {
      dockId: gid.split("_")[0] || 0,
    });
    const clickedTime = moment(time).startOf("day").toDate();
    // if (bookApptModelTitle === "Book Appointment") {
    //   const fieldData = formParticularFieldValue(poForAppt);
    //   setParticularFieldValue({
    //     dock: filteredData?.title,
    //     po_number: fieldData?.po_number,
    //     ReceivingZipCode: selectedWarehouse?.Zip,
    //     start_date: moment(clickedTime).format("YYYY-MM-DD"),
    //   });
    // } else if ("Reschedule Appointment") {
    //   let poNum = null;
    //   const { viewPO } = viewPoDetails;
    //   // console.log("🚀 ~ file: WPSchedulerWH.jsx:1889 ~ recalculateApptDurationHandler ~ viewPO:", viewPO)
    //   if (_.size(data)) {
    //   const fieldData = formParticularFieldValue(data);
    //   poNum = fieldData?.po_number
    //   } else {
    //     if (_.size(poForAppt)) {
    //       const fieldData = formParticularFieldValue(poForAppt);
    //       poNum = fieldData?.po_number
    //     }
    //   }
    //   console.log("🚀 ~ file: WPSchedulerWH.jsx:1875 ~ recalculateApptDurationHandler ~ poNum:", poNum)
    //   setParticularFieldValue({...particularFieldValue,
    //     po_number: poNum,
    //     ReceivingZipCode: viewPO[0]?.ReceivingZipCode,
    //     WarehousePPH: wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0 || 0,
    //     DockPPH: filterDockData?.PPH,
    //     start_date: moment(clickedTime).format("YYYY-MM-DD"),
    //   });
    // }
    if (_.size(holidayList)) {
      const { status, message } = holidayValidationHandler(
        gid?.split("_")[0],
        moment(pStartTime)
      );
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        setCustomDisableSave(true);
        return;
      }
    }
    // Check if WPCapacityData exist then perform WP start Start and end time validation
    if (!_.isEmpty(wpCapacityDataState)) {
      const { status, message } = WPCapacityValidationHandler(
        gid?.split("_")[0],
        moment(pStartTime)
      );
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        setCustomDisableSave(true);
        return;
      }
    }
    // const filterDockData = _.find(wpSchedulerData, {
    //   dockId: gid.split("_")[0] || 0,
    // });

    const { calcPPHhour, calcPPHmin } = calculteEndTimePPH(
      parseFloat(wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0),
      parseFloat(filterDockData.PPH)
    );
    let mpStartTime = moment(pStartTime)
      .add(calcPPHhour, "hours")
      .add(calcPPHmin, "minutes");
    // Validate Appointement
    const { status, message } = handleAppointmentBookingValidation({
      startTime: pStartTime,
      endTime: mpStartTime,
      groupId: gid,
    });
    if (status) {
      setDisableManual(true)
      showAlert(toast.TYPE.WARNING, message, themeSelected);
    } else {
      setDisableManual(false)
      // setStartDateState(moment(pStartTime).format('YYYY-MM-DD'))
      setStartTimeState(moment(pStartTime));
      setCanvasData(groupId);
      setCanvasTime(time);
      if (bookApptModelTitle === "Reschedule Appointment") {
        const splitData = groupId.split("_");
        gid = splitData[0] + "_" + splitData[1];
      }
      // const filteredData = _.find(groups, { id: gid });
      // const calcEndTime = changeDateToGivenFormat(
      //   mpStartTime,
      //   AppConstant.commonStrings.MM_DD_YYYY_dateFormat
      // );

      setEndDateState(mpStartTime);
      setEndTimeState(mpStartTime);
      // Requested by Rajest to comment
      // showAlert(toast.TYPE.INFO, `Calculated End time is ${calcPPHhour} hours ${calcPPHmin} minutes. Your unloading will end at ${mpStartTime.format('HH:mm A')}`, themeSelected);
      if (bookApptModelTitle === "Book Appointment") {
        const fieldData = formParticularFieldValue(poForAppt);
        obj = {
          dock: filteredData?.id,
          po_number: fieldData?.po_number,
          ReceivingZipCode: selectedWarehouse?.Zip,
          end_date: mpStartTime,
          start_time: pStartTime.toString(),
          start_date: moment(clickedTime).format("YYYY-MM-DD"),
          end_time: mpStartTime.toString(),
          ReceivingZipCode: selectedWarehouse?.Zip,
          WarehouseName: fieldData?.WarehouseName || "-",
        }
        // setParticularFieldValue({
        //   dock: filteredData?.id,
        //   po_number: fieldData?.po_number,
        //   ReceivingZipCode: selectedWarehouse?.Zip,
        //   end_date: mpStartTime,
        //   start_time: pStartTime.toString(),
        //   start_date: moment(clickedTime).format("YYYY-MM-DD"),
        //   end_time: mpStartTime.toString(),
        //   ReceivingZipCode: selectedWarehouse?.Zip,
        //   WarehouseName: fieldData?.WarehouseName || "-",
        // });
      } else if ("Reschedule Appointment") {
        let poNum = null;
        const { viewPO } = viewPoDetails;
        if (_.size(data)) {
          const fieldData = formParticularFieldValue(data);
          poNum = fieldData?.po_number
        } else {
          if (_.size(poForAppt)) {
            const fieldData = formParticularFieldValue(poForAppt);
            poNum = fieldData?.po_number
          }
        }

        if (_.size(viewPO)) {
          // setParticularFieldValue({
          //   po_number: poNum,
          //   ReceivingZipCode: viewPO[0]?.ReceivingZipCode,
          //   WarehousePPH: wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0 || 0,
          //   DockPPH: filterDockData?.PPH,
          //   start_date: moment(clickedTime).format("YYYY-MM-DD"),
          //   end_date: mpStartTime,
          //   start_time: pStartTime.toString(),
          //   end_time: mpStartTime.toString(),
          // });
          obj = {
            po_number: poNum,
            ReceivingZipCode: viewPO[0]?.ReceivingZipCode,
            WarehousePPH: wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0,
            DockPPH: filterDockData?.PPH,
            start_date: moment(clickedTime).format("YYYY-MM-DD"),
            end_date: mpStartTime,
            start_time: pStartTime.toString(),
            end_time: mpStartTime.toString(),
            dock: filteredData?.id,
            carrier_name: viewPO[0]?.IDCarrier,
          }
        }
      }
      if (location && location?.state) {
        const { type } = location?.state;
        if (type === 'rescheduling') {
          openSearchPOForm(gid, true)
          // Clear type after popup opens from MyAppointment
          navigate({ state: { type: null } });
        }
      }
    }
    setParticularFieldValue(obj)
    return obj
  };


  const onCanvasClickHanlder = (groupId, time, e) => {
    setCanvasData(groupId);
    // setSelectedCarrier({
    //   label: masterPO?.CarrierName,
    //   value: masterPO?.IDCarrier,
    // });
    setCanvasTime(time);
    // Commented code due - WP-2397
    // const status = appointmentValidations(groupId, time)
    if (true) {
      openSearchPOForm(groupId)
    }
    return;
  };

  // const appointmentBookingHandler = (groupId, time) => {
  //   let gid = groupId;
  //   let pStartTime = moment(time);

  //   // Check if holiday List exist then perform holiday validation
  //   setCustomDisableSave(false);
  //   if (_.size(holidayList)) {
  //     const { status, message } = holidayValidationHandler(
  //       gid?.split("_")[0],
  //       moment(pStartTime)
  //     );
  //     if (status) {
  //       // showAlert(toast.TYPE.ERROR, message, themeSelected);
  //       setValidationPopupText(message);
  //       setShowValidationPopup(true);
  //       setCustomDisableSave(true);
  //       return;
  //     }
  //   }
  //   // Check if WPCapacityData exist then perform WP start Start and end time validation
  //   if (!_.isEmpty(wpCapacityDataState)) {
  //     const { status, message } = WPCapacityValidationHandler(
  //       gid?.split("_")[0],
  //       moment(pStartTime)
  //     );
  //     if (status) {
  //       // showAlert(toast.TYPE.ERROR, message, themeSelected);
  //       setValidationPopupText(message);
  //       setShowValidationPopup(true);
  //       setCustomDisableSave(true);
  //       return;
  //     }
  //   }
  //   const clickedTime = moment(time).startOf("day");
  //   const filterDockData = _.find(wpSchedulerData, {
  //     dockId: gid.split("_")[0] || 0,
  //   });
  //   const { calcPPHhour, calcPPHmin } = calculteEndTimePPH(
  //     parseFloat(wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0),
  //     parseFloat(filterDockData.PPH)
  //   );
  //   let mpStartTime = moment(pStartTime)
  //     .add(calcPPHhour, "hours")
  //     .add(calcPPHmin, "minutes");
  //   // Validate Appointement
  //   const { status, message } = handleAppointmentBookingValidation({
  //     startTime: pStartTime,
  //     endTime: mpStartTime,
  //     groupId: gid,
  //   });
  //   if (status) {
  //     showAlert(toast.TYPE.WARNING, message, themeSelected);
  //   } else {
  //     setStartTimeState(pStartTime);
  //     // setCanvasData(groupId);
  //     // setCanvasTime(time);
  //     if (bookApptModelTitle === "Reschedule Appointment") {
  //       const splitData = groupId.split("_");
  //       gid = splitData[0] + "_" + splitData[1];
  //     }
  //     const filteredData = _.find(groups, { id: gid });
  //     const calcEndTime = changeDateToGivenFormat(
  //       mpStartTime,
  //       AppConstant.commonStrings.MM_DD_YYYY_dateFormat
  //     );
  //     setEndDateState(mpStartTime);
  //     setEndTimeState(mpStartTime);
  //     // Requested by Rajest to comment
  //     //  showAlert(toast.TYPE.INFO, `Calculated End time is ${calcPPHhour} hours ${calcPPHmin} minutes. Your unloading will end at ${mpStartTime.format('HH:mm A')}`, themeSelected);
  //     const fieldData = formParticularFieldValue(poForAppt);
  //    if (bookApptModelTitle === "Book Appointment") {

  //       if(!fieldData?.po_number){
  //         showAlert(
  //           toast.TYPE.ERROR,
  //           "Search Po First",
  //           "light"
  //         );
  //        }
  //        else{
  //         setParticularFieldValue({
  //           dock: filteredData.title,
  //           po_number: fieldData?.po_number,
  //           start_date: moment(clickedTime).format("YYYY-MM-DD"),
  //           end_date: mpStartTime,
  //           start_time: pStartTime.toString(),
  //           end_time: mpStartTime.toString(),
  //           ReceivingZipCode: selectedWarehouse?.Zip,
  //           WarehouseName: fieldData?.WarehouseName || "-",
  //           WarehousePPH: wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0 || 0,
  //           DockPPH: filterDockData.PPH,
  //         });
  //         openCreateForm(filterDockData);
  //        }


  //     } else if ("Reschedule Appointment") { 
  //       const { viewPO } = viewPoDetails;
  //      if(!viewPO){
  //       showAlert(
  //         toast.TYPE.ERROR,
  //         "Search Po First",
  //         "light"
  //       );
  //      }
  //      else{

  //       setSelectedCarrier({
  //         label: viewPO?.CarrierName,
  //         value: viewPO?.IDCarrier,
  //       });
  //       setParticularFieldValue({
  //         dock: filteredData.title,
  //         po_number: viewPO?.PoNumber,
  //         start_date: moment(clickedTime).format("YYYY-MM-DD"),
  //         end_date: mpStartTime,
  //         start_time: pStartTime.toString(),
  //         end_time: mpStartTime.toString(),
  //         WarehousePPH: wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0 || 0,
  //         DockPPH: filterDockData?.PPH,
  //         ReceivingZipCode: selectedWarehouse?.Zip,
  //       });
  //       openCreateForm(filterDockData);
  //     }}

  //     // const fieldData = formParticularFieldValue();
  //     // setParticularFieldValue({
  //     //   dock: filteredData.title,
  //     //   po_number: fieldData?.po_number,
  //     //   start_date: moment(clickedTime).format("YYYY-MM-DD"),
  //     //   end_date: mpStartTime,
  //     //   ReceivingZipCode: selectedWarehouse?.Zip,
  //     //   WarehouseName: fieldData?.WarehouseName || "-",
  //     //   start_time: pStartTime.toString(),
  //     //   end_time: mpStartTime.toString(),
  //     //   WarehousePPH: wpCapacityData && _.isArray(wpCapacityData) && wpCapacityData[0]?.PPH || 0 || 0,
  //     //   DockPPH: filterDockData.PPH,
  //     // });

  //   }
  // }

  useEffect(() => {
    if (_.size(appointmentList) && intiateAppointmentLoading) {
      showAlert(
        toast.TYPE.SUCCESS,
        "Appointment Booking successful",
        "light"
      );
      setLoading(false)
      closeSearchPOForm(true)
      showModal && closeCurrentpopup()
      dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false });
    }
  }, [appointmentList])


  const handleAppointmentBookingValidation = ({
    startTime,
    endTime,
    groupId,
  }) => {
    // Check if PO already has appointment and it is not getting rescheduled
    let masterPO = {};
    let ponum = "";
    if (bookApptModelTitle === "Book Appointment") {
      if (_.size(poForAppt)) {
        masterPO = poForAppt[0];
        ponum = masterPO?.PoNumber;
      }
      // if (_.size(poForAppt)) {
      //   masterPO = poForAppt[0];
      //   ponum = masterPO?.PoNumber;
      //   if (
      //     _.size(poForAppt) &&
      //     bookApptModelTitle !== "Reschedule Appointment"
      //   ) {
      //     const ifApptExists = _.find(items, { masterTitle: masterPO?.PoNumber });
      //     if (!_.isEmpty(ifApptExists)) {
      //       return {
      //         status: true,
      //         message:
      //           AppConstant.smartScheduler.myAppointments
      //             .scheduleAppointmentExistsWarning,
      //       };
      //     }
      //   }
      // }
    } else {
      masterPO = particularFieldValue;
      ponum = particularFieldValue?.po_number;
    }
    // Check if appointment is overlapping with another Appointment
    const gCanvasData = groupId.split("_");
    const isOverlap = items.some((el) => {
      const elDockTimeSlot = el?.group.split("_");
      return (
        el?.start_time < endTime &&
        el?.end_time > startTime &&
        elDockTimeSlot[0] === gCanvasData[0] &&
        elDockTimeSlot[1] === gCanvasData[1] &&
        el?.masterTitle !== ponum && el?.PORelationID !== masterPO?.PORelationID
      );
    });
    if (isOverlap) {
      // Handle the overlap conflict (e.g., display an error message)
      return {
        status: true,
        message: "Appointment overlaps with an existing appointment.",
      };
    } else {
      return {
        status: false,
        message: "",
      };
    }
  };

  const groupRenderer = ({ group }) => {
    return (
      <div className="custom-group">
        <span
          className="title subText"
          data-tip={`${group.title}`}
          data-for={`${group.id}`}
        >
          {group.title}
        </span>
        <CustomTooltip
          id={group.id}
          position="top"
          type={
            themeSelected === AppConstant.commonStrings.lightModeFilterString
              ? "light"
              : "dark"
          }
          multiline={false}
        />
      </div>
    );
  };

  const createSchedulerGroups = () => {
    let groupArray = [];
    let itemArray = [];
    let allDockArray = [];
    let sorted_docklist = _.sortBy(wpSchedulerData,
      [function (o) { return o.dockName; }]);
      sorted_docklist.forEach((el) => {
      const { dockId, dockName, timeSlots ,DockStartTime,DockEndTime} = el;

      if (_.size(timeSlots)) {
        allDockArray.push({
          idDock: dockId,
          dockName: dockName,
          dockStartTime: DockStartTime,
          dockEndTime:DockEndTime
        });
        timeSlots.forEach((ele) => {
          let obj = {
            id: dockId + "_" + ele.timeslotName,
            title: dockName,
            // title: dockName + "-" + ele.timeslotName,
            // tip: dockId + "_" + ele.timeslotName,
            tip: dockId,
            idDock: dockId,
            dockStartTime: DockStartTime,
            dockEndTime:DockEndTime
          };
          groupArray.push(obj);
        });
      }
    });
    allDockArray.push({ idDock: "0000-0000", dockName: "ALL" })
    if (_.size(groupArray)) {
      setMasterGroups([...groupArray]);
      setGroups([...groupArray]);
      setAllDock([...allDockArray]);
      getTimeslotList(groupArray)
      highlightDockStartEndTime(groupArray)
    } else {
      setMasterGroups([]);
      setGroups([]);
      setAllDock([]);
      // setTimeslotListState([])
      // setShouldRender(true);
    }
  };

  const onAddRemoveHandler = (data, type) => {
    dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: true });
    let IDDock = "";
    // const masterPO = poForAppt[0];
    let IDWarehouse = selectedWarehouse?.ID;
    if (bookApptModelTitle === "Book Appointment") {
      // while fresh appointment booking
      IDDock = canvasData.split("_")[0];
    } else if (bookApptModelTitle === "Reschedule Appointment") {
      // while rescheduling appointment
      const { itemId } = viewPoDetails;
      IDDock = itemId.split("_")[0];
    }
    if (type === "ADD") {

      const newData = data.map((el) => {
        const { poNumber, ReceivingZipCode } = el;
        if (poNumber && ReceivingZipCode) {
          return {
            PoNumber: poNumber,
            ReceivingZipCode,
            IDDock,
            IDWarehouse,
          };
        }
      });
      dispatch({
        type: sagaActions.ADD_REMOVE_PO,
        payload: { payload: newData, type: type },
      });
    } else if (type === "REMOVE") {
      const newData = poForAppt.map((el) => {
        if (el.PoNumber !== data.poNumber) {
          return el;
        } else {
          return null;
        }
      });
      const filteredData = _.compact(newData);
      const finalData = _.uniqBy(filteredData, "IDPO");
      dispatch({
        type: sagaActions.ADD_REMOVE_PO,
        payload: { payload: finalData, type: type },
      });
    }
  };

  const showAddPoPopup = () => {
    const popupObj = {
      id: "addPO",
      modalLabel: "addPO",
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: "md",
      title: "Add PO's",
      bodyText: openAddMorePO(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.comments.cancelCommentButtonTitle,
        },
        btn2: {
          show: true,
          text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
        },
      },
    };

    return (
      <SSPopup
        {...popupObj}
        popupBtnHandler={(text) => () => ""}
        closepopup={closeAddMorePOForm}
        themeSelected={themeSelected}
        showModal={showAddPoModel}
      />
    );
  };

  const showSearchPoAppointmentPopup = () => {
    const popupObj = {
      id: "searchPOAppointment",
      modalLabel: "searchPOAppointment",
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: "xl",
      title: "Search PO",
      bodyText: createSearchPOForm(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.comments.cancelCommentButtonTitle,
        },
        btn2: {
          show: true,
          text: AppConstant.poManagement.searchPOSection.searchTitleStr,
        },
      },
    };

    return (
      <SSPopup
        {...popupObj}
        popupBtnHandler={(text) => () => ""}
        closepopup={() => closeSearchPOForm(true)}
        themeSelected={themeSelected}
        showModal={showSearchPoModel}
      />
    );
  };

  const formFieldListSeachPo = [
    {
      name: "po_number",
      type: "text",
      size: "col-sm-6",
      position: 1,
      placeholder: "Purchase Order",
      title: AppConstant.smartScheduler.poSection.poNumTitle,
      formValidation: {
        required: true,
      },
      disabledField: false,
    },
    {
      name: "ReceivingZipCode",
      type: "text",
      placeholder: "Zip Code",
      size: "col-sm-6",
      position: 2,
      title: "Receiving Zip Code",
      formValidation: {
        required: true,
        lengthValidation: {
          customError:
            AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
              .zipCodeErrorMessage + ". ",
        },
      },
      disabledField: true,
    },
  ];

  const openSearchPOForm = (groupId, clear) => {
    // Could create issue when redireting from My Appointments
    // clearSchedulerData();
    if (!clear) {
      dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
      // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
    }
    let filterTimeSlot = []
    if (_.size(timeslotListState)) {
      filterTimeSlot = timeslotListState.filter(el => el.id === groupId)
    }
    setParticularFieldValue({
      ReceivingZipCode: selectedWarehouse?.Zip,
      dock: _.size(filterTimeSlot) ? filterTimeSlot[0]?.title : ''
    })
    setShowSearchPoModel(true);
  };

  const clearSchedulerData = () => {
    setPoSearchList(null);
    setCanvasData('');
    setCanvasTime('')
    setParticularFieldValue({})
    dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
    dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
  }
  const closeSearchPOForm = (clearData) => {
    setShowSearchPoModel(false);
    if (clearData) {
      clearSchedulerData()

    }
  };

  const getSummitedFormDataSearchPO = (data) => {
    const { po_number, ReceivingZipCode } = data;
    if (po_number && ReceivingZipCode) {
      // clearSchedulerData()
      setShowSearchError(true);
      dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true });
      dispatch({
        type: sagaActions.INTIATE_SS_SEARCH_PO,
        payload: {
          PoNumber: po_number,
          ReceivingZipCode: ReceivingZipCode,
          UserType: userType,
          ID:
            userType === "CARRIER"
              ? userAccountDetails?.CUID
              : selectedWarehouse?.ID,
        },
      });
    } else {
      showAlert(
        toast.TYPE.WARNING,
        "Both PO and Zip Code are mandatory",
        "light"
      );
    }
  };

  useEffect(() => {
    if (posSearchList && posSearchList.length) {
      dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [...posSearchList] })
      navigate('/bookappointments', { state: { type: 'rescheduling' } });
    }
  }, [posSearchList])

  const changeDatePickerSelectedDate = (dates) => {
    if (socketRef) {
      closeUpdatesSocketConnection(socketRef, selectedWarehouse.ID)
    }
    const socketRefTemp = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose, datePickerSelectedDate)
    setSocketRef(socketRefTemp)
    setShouldRender(false);

    dispatch({
      type: sagaActions.GET_SCHEDULER_DATA,
      payload: { IDWarehouse: selectedWarehouse?.ID, DateSelected: moment(dates).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        CalendarView: 'DAY' },
    });
    const dateTemp = moment(dates);
    const visibleTimeStartTemp = dateTemp.clone().hour(0);
    const visibleTimeEndTemp = dateTemp.clone().hour(23).minute(59);
    setVisibleTimeStart(visibleTimeStartTemp);
    setVisibleTimeEnd(visibleTimeEndTemp);
    setDatePickerSelectedDate(dates);
    // Time added for loader to function properly
    setTimeout(() => {
      setShouldRender(true);
    },[1000])
  };

  const createSearchPOForm = () => {
    return (
      <>
        <CustomDynamicFormSS
          formFieldList={formFieldListSeachPo}
          sort={true}
          cancelBtn={false}
          submitbtn={searchbtnData}
          themeSelected={themeSelected}
          getFormFieldData={getFormFieldData}
          getSummitedFormData={getSummitedFormDataSearchPO}
          // editFormData={editData}
          loading={initateLoading}
          resetFormData={() => resetDataForSearch()}
          setParticularFieldValue={particularFieldValue}
        />
        {posSearchList && posSearchList.length ? (""
          // <CarrierApptsListScreen
          //   data={posSearchList}
          //   screenTypeClassname="ssappointmentListTable-wpflow"
          //   setShowSuccess={setShowSuccess} showSuccess={showSuccess}
          //   selectedCarrier={selectedCarrier}
          //   setSelectedCarrier={setSelectedCarrier}
          //   carrierListState={carrierListState}
          //   setCarrierListState={setCarrierListState}
          //   onCarrierSelcted={onCarrierSelcted}
          //   getFormFieldData={getFormFieldData}
          //   getSummitedFormData={getSummitedFormData}
          //   loading={intiateAppointmentLoading}
          //   resetFormData={resetFormData}
          //   particularFieldValue={particularFieldValue}
          //   setParticularFieldValue={setParticularFieldValue}
          //   disclaimersArray={disclaimersArray}
          //   customDisableSave={customDisableSave}
          //   formatCreateLabel={formatCreateLabel}
          //   currentCalendarType={currentCalendarType}
          //   startDateState={startDateState}
          //   startTimeState={startTimeState}
          //   setRescheduleStateData={setRescheduleStateData}
          //   recalculateApptDurationHandler={recalculateApptDurationHandler}
          //   canvasData={canvasData}
          //   canvasTime={canvasTime}
          //   getTimeslotList={getTimeslotList}
          //   formFieldList={formFieldList}
          //   submitbtnData={submitbtnData}
          //   getDisclaimerDock={getDisclaimerDock}
          //   disableManual={disableManual}

          // />
        ) : (
          showSearchError && !initateLoading && showNoDataAfterSearch()
        )}
      </>
    );
  };

  const resetDataForSearch = () => {
    setResetFormData(0);
  };

  const showNoDataAfterSearch = () => {
    return (
      <span className={`d-flex justify-content-center text-center`}>
        {"No Appointments available for this PO."}
      </span>
    );
  };

  const closeAppointmentValidationPopup = () => {
    setShowValidationPopup(false);
  };

  const showAppointmentValidationPopup = () => {
    const popupObj = {
      id: "appointmentValidationPopup",
      modalLabel: "appointmentValidationPopup",
      showClose: true,
      showfooter: true,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: "lg",
      title: "Alert",
      bodyText: <div className="boldText">{validationPopupText}</div>,
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: false,
          text: "",
        },
        btn2: {
          show: true,
          text: AppConstant.commonStrings.close,
        },
      },
    };
    return (
      <SSPopup
        {...popupObj}
        popupBtnHandler={(text) => {
          closeAppointmentValidationPopup();
        }}
        closepopup={closeAppointmentValidationPopup}
        themeSelected={themeSelected}
        showModal={showValidationPopup}
        modalClassname={"deletePopupBkg"}
      />
    );
  };

  function createWarningText(mPO, associatedPO, arr) {
    return <div className='boldText'>
      <span> Are you sure, you want to delete this Appointment for PO: {mPO} </span>
      {arr.length > 1 ? <><span> and its associated POs :  </span>
        <span className='textRed'>{associatedPO} ?</span></> : <span>?</span>}
    </div>; // This will render HTML
  }


  const showDeletePoPopup = () => {
    if (_.size(appointmentList) && !_.isEmpty(viewPoDetails)) {
      const { viewPO } = viewPoDetails;
      if (_.size(viewPO)) {
        const masterPO = viewPO[0];
        const fieldDataAssociated = formParticularFieldValueForAssociatedPO(viewPO)
        const popupObj = {
          id: 'deleteAppointment',
          modalLabel: 'deleteEmail',
          showClose: true,
          showfooter: true,
          showHeader: true,
          sendDriverNotification: false,
          modalSize: 'md',
          title: 'Delete PO',
          alignType: 'center',
          bodyText: createWarningText(masterPO?.PoNumber, fieldDataAssociated?.po_number, viewPO),
          subText: "",
          keyboard: false,
          backdrop: "static",
          footerBtn: {
            btn1: {
              show: true,
              text: AppConstant.commonStrings.yes
            },
            btn2: {
              show: true,
              text: AppConstant.commonStrings.no
            }
          },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => deletePoApptPopupBtnHandler(text)} closepopup={closeDeletePOApptForm} themeSelected={themeSelected} showModal={showDeletePoApptModel} />
      }
    }
  }

  const closeDeletePOApptForm = () => {
    setShowDeletePoApptModel(false);
  };

  const deletePoApptPopupBtnHandler = (titleStr) => {
    if (titleStr === AppConstant.commonStrings.yes) {
      if (!_.isEmpty(viewPoDetails)) {
        const { viewPO } = viewPoDetails;
        if (_.size(viewPO)) {
          const masterPO = viewPO[0];
          const newItemPayload = {
            PoNumber: masterPO.PORelationID,
            IDWarehouse: masterPO?.IDWarehouse,
            ReceivingZipCode: masterPO?.ReceivingZipCode,
            ApptStartDate: null,
            ApptStartTime: null,
            ApptEndDate: null,
            ApptEndTime: null,
            ApptStatus: 1,
            Door: null,
            ApptCreatedBy: null,
            IDDock: masterPO.IDDock,
            IDCarrier: masterPO?.IDCarrier,
            CRUDTYPE: "D",
            DeletedPos: "",
            userType: userType
          };
          dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true });
          dispatch({ type: sagaActions.ADD_APPOINTMENT, payload: newItemPayload });
          dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
          setBookApptModelTitle("Book Appointment");
          setShowDeletePoApptModel(false);
        }

      }
    } else {
      setShowDeletePoApptModel(false);
    }
  };

  const onDockSelected = (data, calledFrom) => {
    removeAlreadPloated()
    setSelectedDock(data)
    if (!calledFrom) { 
      if (defaultDockValue && defaultDockValue === data?.idDock) {
        setDefaultDockValueState(defaultDockValue)
      } else {
        setDefaultDockValueState(null)
      }
    }
    const { idDock } = data;
    if (idDock && idDock === "0000-0000") {
      createSchedulerGroups();
      // setDefaultDockValueState(null)
    } else {
      const filterGroupArry = _.filter(masterGroups, { idDock });
      setGroups([...filterGroupArry]);
      highlightDockStartEndTime(filterGroupArry)
    }
  };

  const getToggleSwitch = (value, index) => {
    return (
        <div className='d-flex justify-content-center align-items-center py-1'>
            <CommonToggleSwitch value={true}
          onChangeValue={(value) => {
            dispatch({
              type: sagaActions.UPDATE_DEFAULT_DOCK,
              payload: {
                CUID: cu_id,
                  IDWarehouse: selectedWarehouse?.ID,
                  DefaultIDDock: null,
                  CRUDTYPE: 'U'
              }
            })
            setTimeout(() => {
              navigate('/wpscheduleappointments')
            }, 0);
          }} 
                allowPermissionD={_.size(groups) ? true: false}
                />
        </div>
    )
  }
  
  const onDefaultDockChange = (data, checked) => {
    dispatch({
      type: sagaActions.UPDATE_DEFAULT_DOCK,
      payload: {
        CUID: cu_id,
          IDWarehouse: selectedWarehouse?.ID,
        DefaultIDDock: selectedDock?.idDock,
          CRUDTYPE: 'I'
      }
    })
  }

  return (
    <ScheduleProWrapper>
      <div className="scheduler-wrapper-ss p-3">
        <div className="d-flex justify-content-between">
        {/* <p className="f-16 fw-500">Schedule</p> */}
        <div className="pos-rel">
        <button
                    className="date-picker-btn h-100 ms-1"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    <span className="btn-title fw-500 f-14">
                   
                      {datePickerSelectedDate
                        ?`${format(new Date(datePickerSelectedDate),"PPP")}`
                        : "Select Date"}
                    </span>
                    {showCalendar ? (
                      <span
                        className="font-default"
                        onClick={() => setShowCalendar(false)}
                      >
                        <i
                          className="fa-solid fa-xmark"
                          style={{ marginInline: "9px" }}
                        ></i>
                      </span>
                    ) : (
                      <span className="font-default">
                        <i
                          className="fas fa-calendar-week"
                          style={{ marginInline: "9px" }}
                        ></i>
                      </span>
                    )}
                  </button>
                  {showCalendar && (
                    <div
                      className="myappt-ss-datePicker"
                      style={{ zIndex: "999999999" }}
                    >
                      <DatePickerSS
                        selectedDate={datePickerSelectedDate}
                        setSelectedDate={setDatePickerSelectedDate}
                        minDate={new Date(new Date().setHours(0,0,0,0))}
                        setLoading={false}
                        closeCalendar={() => {
                          setShowCalendar(!showCalendar);
                        }}
                      />
                    </div>
                  )}
          </div>
          <div className="d-flex justify-content-end gap-3">
          <CustomButtonSS
          isLoading={false}
          title={AppConstant.smartScheduler.searchAppointments.newApptBtnText}
          className={"btn-highlighted f-12 ms-3 "}
          onClick={() => {
            clearSearchedPOData()
            navigate('/bookappointments')
          }}
          isIcon={true}
          iconClass={'fa-solid fa-plus fa-lg color-white'}
        />
         
        <div className='d-flex align-items-center'>
          <p className='me-2 mb-1 fw-600 f-14'>Day Wise View</p>
        {getToggleSwitch()}
            </div>
            </div>
        </div>
        <br />
        <SchedulerHeaderBarSS
          {...props}
          clearSearchedPOData={clearSearchedPOData}
          openModel={() => openSearchPOForm()}
          datePickerSelectedDate={datePickerSelectedDate}
          changeDatePickerSelectedDate={(dates) =>
            changeDatePickerSelectedDate(dates)
          }
          allDock={allDock}
          onDockSelected={(data) => onDockSelected(data)}
          shouldRender={shouldRender}
          selectedDock={selectedDock}
          defaultDockValueState={defaultDockValueState}
          onDefaultDockChange={onDefaultDockChange}
        />
        <br />
        {shouldRender && _.size(groups) ? (
          <Timeline
            // ref={timelineRef}
            groups={groups}
            items={items}
            groupRenderer={groupRenderer}
            defaultTimeStart={visibleTimeStart}
            defaultTimeEnd={visibleTimeEnd}
            canResize={false}
            canMove={false}
            onCanvasClick={onCanvasClickHanlder}
            onTimeChange={(_start, _end, updateScrollCanvas) => {
              updateScrollCanvas(
                moment(visibleTimeStart).valueOf(),
                moment(visibleTimeEnd).valueOf()
              );
            }}
            stackItems
            // onItemResize={onItemResizeHandler}
            // buffer={1}
            onItemClick={onItemClickHandler}
            // visibleTimeStart={visibleTimeStart}
            // visibleTimeEnd={visibleTimeEnd}
            // verticalLineClassNameForTime={verticalLineClassNameForTime}
            minZoom={60 * 60 * 1000}
            maxZoom={60 * 60 * 1000}
            timeSteps={{
              second: 1,
              minute: 15,
              hour: 1,
              day: 1,
              month: 1,
              year: 1,
            }}
            dateHeaderRenderer={dateHeaderRenderer}
          >
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return <div {...getRootProps()}>Dock-TimeSlots</div>;
                }}
              </SidebarHeader>
              <DateHeader
                unit="primaryHeader"
                headerRenderer={dateHeaderRenderer}
              />
              <DateHeader
                unit="hour"
                labelFormat="HH:mm"
                style={{ height: 50 }}
              />
            </TimelineHeaders>
          </Timeline>
        ) : shouldRender && !_.size(groups) ? (
          <div className="w-100 text-center boldText">
            {AppConstant.smartScheduler.carrierSignUp.scheduleError}
          </div>
        ) : (
          <div className="w-100">{displayLoader(themeSelected, 400, 1)}</div>
        )}
      </div>
      {/* {showBookAppointmentPopup()} */}
      {showSendEmailPopup()}
      {/* {showAppointmentDetails()} */}
      {showAddPoPopup()}
      {showSearchPoAppointmentPopup()}
      {showDeletePoPopup()}
      {showAppointmentValidationPopup()}
    </ScheduleProWrapper>
  );
};

export default WPSchedulerWHSS;
