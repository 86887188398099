import { Uchain } from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { updateDockList, updateLastEvaluatedKey, updateLastPage, updateListLoading, updateLoading, updateLogList, updatePoIntakeList, updatePoIntakeLogPayload, updateResponseObject, updateWarehouseList, clearPoIntakeLog } from "../redux/reducers/reducerSlices/PoIntakeMangementReducer";
import { showAlert } from "../App/Assests/Utility";
import { toast } from "react-toastify";

const poIntakeList = [
  {
    warehouse: {
      warehouseID: "56464564",
      name: "Trinity"
    },
    dock: {
      dockID: "56464564",
      name: "Trinity dock"
    },
    fileName: "trinity_poList",
    status: true,
    poNumber: "trinity_po",
    defaultPoNumber: "po_number",
    quantity: "trinity_quantity",
    defaultPoNumber: "quantity",
  },
  {
    warehouse: {
      warehouseID: "56464564",
      name: "Trinity"
    },
    dock: {
      dockID: "56464564",
      name: "Trinity dock"
    },
    fileName: "trinity_poList_2",
    status: true,
    poNumber: "trinity_po_2",
    defaultPoNumber: "po_number_2",
    quantity: "trinity_quantity_2",
    defaultPoNumber: "quantity_2",
  }
]

const warehouselist = [
  {
    warehouseID: "123455",
    name: "Acme"
  },
  {
    warehouseID: "56464564",
    name: "Trinity"
  },
]

const docklist = [
  {
    dockID: "123455",
    name: "Clear water"
  },
  {
    dockID: "56464564",
    name: "Trinity dock"
  },
]

function* poIntakeDetailsSaga(data) {
  try {

    if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'S') {
      yield put(updateListLoading(true))
    } else {
      yield put(updateLoading(true));
    }
    const response = yield call(Uchain.poIntakeMangementDetails, data.payload)

    if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'I' && response && response.RESULT.includes('successfully')) {
      showAlert(toast.TYPE.SUCCESS, response.RESULT, 'light')
      yield put(updateResponseObject(response.RESULT))
    } else if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'I' && response && response.RESULT.includes('ERROR')) {
      showAlert(toast.TYPE.ERROR, response.RESULT, 'light')
    }
    else if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'U' && response && response.RESULT.includes('ERROR')) {
      showAlert(toast.TYPE.ERROR, response.RESULT, 'light')
    } else if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'U' && response && response.RESULT.includes('successfully')) {
      showAlert(toast.TYPE.SUCCESS, response.RESULT, 'light')
      console.log("🚀 ~ function*poIntakeDetailsSaga ~ response:", response)

      yield put(updateResponseObject(response.RESULT))
    } else if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'D' && response && response.RESULT.includes('successfully')) {
      showAlert(toast.TYPE.SUCCESS, response.RESULT, 'light')
      yield put(updateResponseObject(response.RESULT))
    }
    else if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'D' && response && response.RESULT.includes('ERROR')) {
      showAlert(toast.TYPE.ERROR, response.RESULT, 'light')
    }
    else if (data.payload.CRUDTYPE && data.payload.CRUDTYPE === 'S') {
      yield put(updateListLoading(true))
      yield put(updatePoIntakeList(response));
    }

  } catch (error) {
    console.log("🚀 ~ function*poIntakeDetailsSaga ~ error:", error)
    showAlert(toast.TYPE.ERROR, error.message, 'light')
  } finally {
    yield put(updateLoading(false))
    yield put(updateListLoading(false))
  }
}

function* clearPoIntakeResponseObjectSaga() {
  yield put(updateResponseObject(''))
}

function* poIntakeLogsSaga(data) {
  try {
    if (data.payload.CRUD === 'S') {
      yield put(updateListLoading(true))

      if (!data.payload.lastEvaluatedKey) {
        yield put(updateLastPage(false))
        yield put(clearPoIntakeLog());
      }

      const response = yield call(Uchain.poIntakeMangementLogs, data.payload)

      yield put(updateLogList(response.items));
      if (response?.lastEvaluatedKey) {
        yield put(updateLastEvaluatedKey(response.lastEvaluatedKey))
      } else {
        yield put(updateLastPage(true))
      }
    }
    else {
      yield put(clearPoIntakeLog());
    }


  } catch (error) {
    console.log("🚀 ~ function*poIntakeDetailsSaga ~ error:", error)
    showAlert(toast.TYPE.ERROR, error.message, 'light')

  } finally {
    yield put(updateLoading(false))
    yield put(updateListLoading(false))
  }
}

function* poIntakeLogPayloadStoreSaga(data) {
  try {
    yield put(updatePoIntakeLogPayload(data.payload))
  } catch (error) {

  }
}


export function* poIntakeDetailsWatcher() {
  yield takeEvery(sagaActions.GET_PO_INTAKE_MANGEMENT_DETAILS, poIntakeDetailsSaga)
}

export function* clearPoIntakeResponseObjectWatcher() {
  yield takeEvery(sagaActions.CLEAR_PO_INTAKE_RESPONSE_OBJECT, clearPoIntakeResponseObjectSaga)
}

export function* poIntakeLogWatcher() {
  yield takeEvery(sagaActions.GET_PO_INTAKE_LOGS, poIntakeLogsSaga)
}

export function* poIntakeLogPayloadStoreWatcher() {
  yield takeEvery(sagaActions.STORE_POINTAKE_LOG_PAYLOAD, poIntakeLogPayloadStoreSaga)
}








