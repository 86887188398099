import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { initiateSearch } from '../../../../../../../Assests/Utility';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { fetchBreakListCommonFunc, fetchBreaksDocksListCommonFunc } from '../Common/FetchBreakList';
import { showAlert, changeFormatWithoutTz } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { getCurrentDate } from '../../../../../../../../Utils/DateUtils';
import { changeDateToGivenFormat, getFormattedDate } from '../../../../../../../../Utils/DateUtils'
import DrilldownTable from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import moment from "moment";
import './BreaksMetaList.css';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import EditIcon from '../../../../../../../Assests/Images/edit-icon-new.png'
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';

const BreaksMetaListSS = (props) => {

    const { currentFilterSelected, themeSelected, editBreakAction, searchStr, allowPermissionE, allowPermissionD, whObj } = props;
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { whMetaBreaksList, operationObj, arrangeOrder, arrangeKey ,preCheckList} = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [metaBreaksArr, setMetaBreaksArr] = useState([]);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj,setSelectedObj]=useState(null)
    const [selectedIndex,setSelectedIndex]=useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    
    useEffect(()=>{
        if (preCheckList && preCheckList.length>0) {
            setCurrentItemIndex("NA")
            setCurrentDeleteIndex('NA')
        }
    },[preCheckList])

    const dockTimeslotsTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'BreakStart', name: AppConstant.smartScheduler.breaksForm.tableHeaders.fromTitle },
        { id: 'BreakEnd', name: AppConstant.smartScheduler.breaksForm.tableHeaders.tillTitle },
        { id: 'Description', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.description },
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'status', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    const dockOnlyTimeslotsTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'Name', name: AppConstant.poManagement.tableHeader.dock },
        { id: 'TimeSlotName', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.timeslot },
        { id: 'BreakStart', name: AppConstant.smartScheduler.breaksForm.tableHeaders.fromTitle },
        { id: 'BreakEnd', name: AppConstant.smartScheduler.breaksForm.tableHeaders.tillTitle },
        { id: 'Description', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.description },
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'status', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    const dockBreaksTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'Name', name: AppConstant.poManagement.tableHeader.dock },
        { id: 'BreakStart', name: AppConstant.smartScheduler.breaksForm.tableHeaders.fromTitle },
        { id: 'BreakEnd', name: AppConstant.smartScheduler.breaksForm.tableHeaders.tillTitle },
        { id: 'Description', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.description },
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'status', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    useEffect(() => {
        setListLoading(true);
        // if (currentFilterSelected.name === 'Warehouse') {
        //     fetchBreakList();
        // }
         if (currentFilterSelected.name === 'Docks' || currentFilterSelected.name === 'Timeslot') {
            fetchBreakDockList();
        }
    }, [currentFilterSelected])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Update status':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.breaksForm.updateBreakStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.breaksForm.updateBreakStatusFailure);
                    }
                    break;
                case 'Delete Breaks':
                    if (operationObj.status) {
                            handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.breaksForm.deleteBreakSuccess);
                    } else {
                            handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.breaksForm.deleteBreakFailure);
                        }
                    break;
                case 'Fetch Breaks':
                    if (!operationObj.status) {
                        setMetaBreaksArr([]);
                        setListLoading(false);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        setListLoading(false);
        setCurrentItemIndex('NA');
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            if (currentFilterSelected.name === 'Warehouse') {
                fetchBreakList();
            } else if (currentFilterSelected.name === 'Docks' || currentFilterSelected.name === 'Timeslot') {
                fetchBreakDockList();
            }
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });

    }

    const fetchBreakDockList = () => {
        const { apiParams, operationObj } = fetchBreaksDocksListCommonFunc(whObj, _.upperCase(currentFilterSelected?.name));
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const fetchBreakList = () => {
        const { apiParams, operationObj } = fetchBreakListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    useEffect(() => {
        if (whMetaBreaksList && whMetaBreaksList.length) {
            setListLoading(false);
            setCurrentItemIndex('NA');
            setCurrentDeleteIndex('NA')
            setMetaBreaksArr(whMetaBreaksList);
        } else {
            setMetaBreaksArr([])
        }
    }, [whMetaBreaksList])

    useEffect(() => {
        if (searchStr.length > 1) {
            const searchedArr = initiateSearch(whMetaBreaksList, searchStr, 'GroupName');
            setMetaBreaksArr(searchedArr);
        } else if (searchStr === '') {
            setMetaBreaksArr(whMetaBreaksList)
        }
    }, [searchStr])

    const disableBreakAction = (data, index) => {
        setCurrentItemIndex(index)
        const apiParams = {
            "IDDockBreak": currentFilterSelected.name === "Docks"  || currentFilterSelected.name === "Timeslot" ? data.IDDockBreak : null,
            "IDWHBreak": currentFilterSelected.name === "Warehouse" ? data.IDWHBreak : null,
            "IDWarehouse": data.IDWarehouse,
            "BreakStart": data.BreakStart,
            "BreakEnd": data.BreakEnd,
            "Description": data.Description,
            "Status": !data.Status,
            "CreatedBy": cu_id,
            "CRUDTYPE": "U",
            "UpdatedBy": cu_id,
            "CreatedOn": data.CreatedOn,
            "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "IDDock": currentFilterSelected.name === "Docks" || currentFilterSelected.name === "Timeslot" ? data.IDDock : null,
            "IDTimeslot": data?.breaksForWhOrDock !== "Warehouse" ? data?.IDTimeslot : null,
            "BreakType": data?.BreakType.toUpperCase() || null
        }
        const operationObj = {
            type: 'Update status',
            status: ''
        }

        if (currentFilterSelected.name === "Warehouse") {
            dispatch({ type: sagaActions.WAREHOUSE_META_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
        } else {
            dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
        }
    }

    const deleteHandler=(data,index)=>{
        setSelectedObj(data,index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }
    const popupBtnHandler=(text)=>{
        if (text===AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else{
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                "IDDockBreak": currentFilterSelected.name === "Docks" || currentFilterSelected.name === "Timeslot" ? selectedObj.IDDockBreak : null,
                "IDWHBreak": currentFilterSelected.name === "Warehouse" ? selectedObj.IDWHBreak : null,
                "IDWarehouse": selectedObj.IDWarehouse,
                "BreakStart": selectedObj.BreakStart,
                "BreakEnd": selectedObj.BreakEnd,
                "Description": selectedObj.Description,
                "Status": true,
                "CreatedBy": cu_id,
                "CRUDTYPE": currentFilterSelected.name === "Warehouse" ?"R":"D",
                "UpdatedBy": cu_id,
                "CreatedOn": selectedObj.CreatedOn,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "IDDock": currentFilterSelected.name === "Docks" || currentFilterSelected.name === "Timeslot" ? selectedObj.IDDock : null,
            }
            const operationObj = {
                type: 'Delete Breaks',
                status: ''
            }
            if (currentFilterSelected.name === "Warehouse") {
                dispatch({ type: sagaActions.WAREHOUSE_META_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
            } else {
                dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
            }
        }
        setShowDeleteModel(false)
    }
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title:  AppConstant.smartScheduler.breaksForm.deleteBreakTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.smartScheduler.breaksForm.deleteBreakTitle}`,            
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => {popupBtnHandler(text)} } closepopup={()=>{setShowDeleteModel(false)}} themeSelected={themeSelected} showModal={showDeleteModel} />
    }



    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex align-items-center justify-content-center gap-2 w-max'>
                    <img src={EditIcon} alt='edit' className={allowPermissionE ? 'editIconPO-ss' : 'editIconPO-ss disabled'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editBreakAction(groupObj, index)}
                    />
                    {/* <button
                        className={allowPermissionE ? `addButton` : 'addButton disabled'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editBreakAction(groupObj, index)}
                    >
                        {AppConstant.commonStrings.edit}
                    </button> */}
                      <CustomButtonSS
                                disabled={false}
                                isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                                className={`no-border no-bg`}
                                onClick={() => {deleteHandler(groupObj,index)}}
                                isIcon={true}
                                iconClass={"fa-regular fa-trash-can fa-lg"}
                            />
                    <CustomButtonSS
                        isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                        title={`${!groupObj.Status ? AppConstant.commonStrings.enable : AppConstant.commonStrings.disable}`}
                        className={`${!groupObj.Status ? ' btnEnable-ss ' : 'btnDisable-ss'} mx-2`}
                        onClick={() => disableBreakAction(groupObj, index)}
                    />
                </span>
            </div>
        )
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            if (currentFilterSelected.name === 'Warehouse') {
                return {
                    id: index,
                    0: index + 1,
                    1: changeFormatWithoutTz(el.BreakStart, AppConstant.commonStrings.HH_mm_dateFormat) || '-',
                    2: changeFormatWithoutTz(el.BreakEnd, AppConstant.commonStrings.HH_mm_dateFormat) || '-',
                    3: el.Description,
                    4: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                    5: el.Status?'Enabled':'Disabled',
                    6: actionsButton(el, index),
                }
            }   else if (currentFilterSelected.name === 'Docks') {
                return {
                    id: index,
                    0: index + 1,
                    1: el.Name || '-',
                    2: changeFormatWithoutTz(el.BreakStart, AppConstant.commonStrings.HH_mm_dateFormat) || '-',
                    3: changeFormatWithoutTz(el.BreakEnd, AppConstant.commonStrings.HH_mm_dateFormat) || '-',
                    4: el.Description,
                    5: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                    6: el.Status?'Enabled':'Disabled',
                    7: actionsButton(el, index),
                }
            }
            else {
                return {
                    id: index,
                    0: index + 1,
                    1: el.Name || '-',
                    2: el.TimeSlotName || '-',
                    3: changeFormatWithoutTz(el.BreakStart, AppConstant.commonStrings.HH_mm_dateFormat) || '-',
                    4: changeFormatWithoutTz(el.BreakEnd, AppConstant.commonStrings.HH_mm_dateFormat) || '-',
                    5: el.Description,
                    6: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                    7: el.Status?'Enabled':'Disabled',
                    8: actionsButton(el, index),
                }
            }
        })
    }

    return (
        <>
        {metaBreaksArr &&
        <DrilldownTableSS
            themeSelected={themeSelected}
            bodyData={metaBreaksArr.length && formatListDataDrillDown(metaBreaksArr)}
            titleData={currentFilterSelected.name === 'Docks' ? dockBreaksTitleDD : dockOnlyTimeslotsTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={metaBreaksArr}
            initiateDrillDownReport={isListLoading}
            loaderArray={currentFilterSelected.name === 'Docks' ? 8 : 9}
            sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
            subRoot={{ pathName: 'whMetaBreaksArr' }}
            showUTCTime={false}
        />}
        {showDeleteConfirmation()}
        </>
    )
}

export default BreaksMetaListSS;