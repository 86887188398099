import React, { useEffect, useState } from 'react'
import _ from 'lodash-es';
import moment from 'moment';
import { AppConstant } from '../../../../../../../Assests/AppConstant';

export const SSAssociatePOs = (props) => {
    const {  rowData,themeSelected } = props;

    return (
        <div className='carrier-associated-po-wrapper p-2' data-testid="appt-card" key={rowData?.PoNumber }>
            <div className='pt-3 d-flex flex-column justify-content-between h-87'>
                <div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-cube" ></i> <span className='ms-1'>{_.upperCase(rowData.LoadType) === 'INBOUND'? AppConstant.poManagement.tableHeader.po: AppConstant.poManagement.tableHeader.so}</span>
                        </div>
                        <div className='carrier-po-value'>{rowData?.PoNumber}</div>
                    </div>
                    <hr className='my-2' />
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-user"></i> <span className='ms-1'>Vendor</span>
                        </div>
                        <div className='carrier-po-value'>{rowData?.VendorName ? rowData?.VendorName : '-'}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-store"></i> <span className='ms-1'>Buyer</span>
                        </div>
                        <div className='carrier-po-value'>{rowData?.BuyerName ? rowData?.BuyerName : '-'}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-suitcase"></i> <span className='ms-1'>Commodity</span>
                        </div>
                        <div className='carrier-po-value'>{rowData?.Commodity ? rowData?.Commodity : '-'}</div>
                    </div>

                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-cubes"></i> <span className='ms-1'>{`${AppConstant.dashboard.defaultdashboard.doorUsageSummary.palletsHeading}`}</span>
                        </div>
                        <div className='carrier-po-value w-50'>{`${rowData?.Pallets ? rowData?.Pallets : '-'}`}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key w-50'>
                            <i className="fa-solid fa-sitemap"></i> <span className='ms-1'>{`${AppConstant.loadManagement.purchaseOrderDetails.items} | ${AppConstant.loadManagement.purchaseOrderDetails.cases}`}</span>
                        </div>
                        <div className='carrier-po-value w-50'>{rowData?.Quantity ? rowData?.Quantity : '-'} | {rowData?.ItemsCases ? rowData?.ItemsCases : '-'}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-calendar"></i> <span className='ms-1'>Due Date</span>
                        </div>
                        <div className='carrier-po-value text-danger'>{moment(rowData?.DueDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) || ''}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key w-50'>
                            <i className="fa-solid fa-user"></i> <span className='ms-1'>Carrier</span>
                        </div>
                        <div className='carrier-po-value w-50 text-truncate' title={rowData?.CarrierName || '-'}>{rowData?.CarrierName || '-'}</div>
                    </div>
                </div>
            </div>
         
        </div >
    )
}
