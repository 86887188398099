import React, { useEffect, useState, useRef } from 'react';
import { AppConstant } from '../../../Assests/AppConstant';
import SSPopup from '../Popup/SSPopup/SSPopup';
import _ from 'lodash-es';

import moment from 'moment';

const PoDetailsPopup = (props) => {
    const { themeSelected, showModal, setCloseModal,  initateLoading,poData } = props;
    const [show, setShow] = useState(false);
    const [loading,setLoading] = useState(false)

    /**
     * useEffect to show and hide modal
     */
    useEffect(() => {
        setShow(showModal)
    }, [showModal])

    /**
     * useEffect to for loader
     */
    useEffect(() => {
        setLoading(initateLoading)
    }, [initateLoading])
    


    /**
     * fuction to close modal
     */
    const handleClose = () => {
        setShow(false)
        setCloseModal(false)
    };
    

    
    const getBodyData = () => {
        return (
            <div className='pt-3 d-flex flex-column justify-content-between h-87'>
            <div>
                <div className='d-flex justify-content-between mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-cube" ></i> <span className='ms-1'>{`${AppConstant.poManagement.tableHeader.po} / ${AppConstant.poManagement.tableHeader.so}`}</span>
                    </div>
                    <div className='carrier-po-value'>{poData?.PoNumber}</div>
                </div>
                {/* {poNumber && <div className='d-flex justify-content-between mb-2'>
                <div className='carrier-po-key'>
                    <i className="fa-solid fa-cube" ></i> <span className='ms-1'>Asso. Po</span>
                </div>
                <div className='carrier-po-value'>{poNumber}</div>
            </div>} */}
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-warehouse fa-sm"></i> <span className='ms-1'>Warehouse</span>
                    </div>
                    <div className='carrier-po-value w-50'>{poData?.WarehouseData?.Name}</div>
                </div>
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-envelope"></i> <span className='ms-1'>ZIP Code</span>
                    </div>
                    <div className='carrier-po-value'>{poData?.ReceivingZipCode}</div>
                </div>
                {/* <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-calendar"></i> <span className='ms-1'>Appt. No.</span>
                    </div>
                    <div className='carrier-po-value'>{poData?.AppointmentNumber || "-"}</div>
                </div> */}
                <hr className='my-2' />
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-user"></i> <span className='ms-1'>Vendor</span>
                    </div>
                    <div className='carrier-po-value'>{poData?.VendorName ? poData?.VendorName : '-'}</div>
                </div>
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-store"></i> <span className='ms-1'>Buyer</span>
                    </div>
                    <div className='carrier-po-value'>{poData?.BuyerName ? poData?.BuyerName : '-'}</div>
                </div>
                {/* <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key w-40'>
                        <i className="fa-solid fa-user"></i> <span className='ms-1'>Appt. By</span>
                    </div>
                    <div className='carrier-po-value w-60'>{`${poData.FirstName || "-"} ${poData.LastName || "-"} ( ${poData.CarrierName || "-"} )`}</div>
                </div> */}
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-suitcase"></i> <span className='ms-1'>Commodity</span>
                    </div>
                    <div className='carrier-po-value'>{poData?.Commodity ? poData?.Commodity : '-'}</div>
                </div>

                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-cubes"></i> <span className='ms-1'>{`Dock | ${AppConstant.dashboard.defaultdashboard.doorUsageSummary.palletsHeading}`}</span>
                    </div>
                    <div className='carrier-po-value w-50'>{poData?.DockName ? poData?.DockName : ''}{` | ${poData?.Pallets ? poData?.Pallets : '-'}`}</div>
                </div>
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-sitemap"></i> <span className='ms-1'>{`${AppConstant.loadManagement.purchaseOrderDetails.items} | ${AppConstant.loadManagement.purchaseOrderDetails.cases}`}</span>
                    </div>
                    <div className='carrier-po-value w-50'>{poData?.Quantity ? poData?.Quantity : '-'} | {poData?.ItemsCases ? poData?.ItemsCases : '-'}</div>
                </div>
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-calendar"></i> <span className='ms-1'>Due Date</span>
                    </div>
                    <div className='carrier-po-value text-danger'>{moment(poData?.DueDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) || ''}</div>
                </div>
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-user"></i> <span className='ms-1'>Carrier</span>
                    </div>
                    <div className='carrier-po-value w-50 text-truncate' title={poData?.CarrierName || '-'}>{poData?.CarrierName || '-'}</div>
                </div>
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                    <i className="fa-solid fa-truck"></i> <span className='ms-1'>Vehicle Type</span>
                    </div>
                    <div className='carrier-po-value w-50 text-truncate' title={poData?.vehicleType || '-'}>{poData?.vehicleType || '-'}</div>
                </div>
                <div className='d-flex justify-content-between  mb-2'>
                    <div className='carrier-po-key'>
                    <i className="fa-solid fa-truck-ramp-box"></i> <span className='ms-1'>Load Type</span>
                    </div>
                    <div className='carrier-po-value w-50 text-truncate' title={poData?.orderType || '-'}>{poData?.orderType || '-'}</div>
                </div>
            </div>
          
        </div>
        )
    }

    const poDetailsObj = {
        id: "poDetailsObj",
        modalLabel: "poDetailsObj",
        showClose: true,
        showfooter: false,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "md",
        title: `${AppConstant.poManagement.tableHeader.po}/${AppConstant.poManagement.tableHeader.so} Details`,
        bodyText: getBodyData(),
        subText: "",
        keyboard: false,
        backdrop: "static",
        alignType: 'center',
        type: 'info',
        footerBtn: {
            btn1: {
                show: false,
                text: AppConstant.commonStrings.yes,
            },
            btn2: {
                show: false,
                text: AppConstant.commonStrings.no,
            },
        },
    }

    return (
        <>
            {show ? <SSPopup {...poDetailsObj} popupBtnHandler={() => { }} closepopup={handleClose} themeSelected={themeSelected} showModal={show} /> : ''}

        </>
    );
};

export default PoDetailsPopup;
