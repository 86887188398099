import React, { useState, useEffect, forwardRef, useRef } from 'react';
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import { useNavigate } from 'react-router-dom';
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { CustomDynamicFormSS } from '../../../../../Common/CustomForm/CustomDynamicFormSS';
import DockTypeList from './DockTypeList';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { changeFormatWithoutTz, showAlert } from '../../../../../../Assests/Utility';
import { getCurrentDate } from '../../../../../../../Utils/DateUtils';

const DockType = (props) => {
    const { themeSelected, allowPermissionE = true, allowPermissionD, whObj } = props;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [searchStr, setSearchStr] = useState('');
    const [loading, setLoading] = useState(false);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { operationObj, dockTypeList } = whMetaData;
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const firstUpdate = useRef(true);
    const navigate = useNavigate()
    const [unSlectedDays, setUnSelectedDays] = useState('');
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const [dockListType, setDockListType] = useState(null)
    const [resetFormData, setResetFormData] = useState(0);

    const formFieldList = [
        {
            name: "DockType",
            type: "text",
            size: "col-sm-12 ",
            position: 0,
            title: AppConstant.smartScheduler.docks.dockType,
            formValidation: {
                required: true,
            },
        }
    ]

    const formFieldEditList = [
        {
            name: "DockType",
            type: "text",
            size: "col-sm-12 ",
            position: 0,
            title: AppConstant.smartScheduler.docks.dockType,
            formValidation: {
                required: true,
            },
        }
    ]

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            fetchDockTypeList()
        }, 100);
    }, [])

    const fetchDockTypeList = () => {
        const apiParams = createPayload({}, 'Fetch')
        const operationObj = {
            type: 'Fetch dock type',
            status: '',
        }
        dispatch({ type: sagaActions.DOCK_TYPE_LIST_ACTION, payload: apiParams, operationObj: operationObj });
    }

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Add dock type':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.docks.createDockTypeSuccess);
                        closeCurrentpopup()
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.createDockTypeFailure);
                    }
                    break;
                case 'Update dock type':
                    if (operationObj.status) {
                        closeCurrentpopup()
                    }
                    setLoading(false);
                    break;
                default:
            }
        }
    }, [operationObj])


    const handleApiResponse = (result, message) => {
        setLoading(false);
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchDockTypeList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }

    }

    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        let operationObj = {
            type: '',
            status: '',
        }
        if (data.ID) {
            const apiTempParams = createPayload(data, "Edit")
            operationObj.type = 'Update dock type'
            apiParams = { ...data, ...apiTempParams }
        }
        else {
            apiParams = createPayload(data, "Add")
            operationObj.type = 'Add dock type'
        }
        dispatch({ type: sagaActions.DOCK_TYPE_LIST_ACTION, payload: apiParams, operationObj: operationObj });

    };

    const createPayload = (data, type) => {
        if (type === "Edit") {
            return {
                "ID": data.ID,
                "IDWarehouse": data.IDWarehouse,
                "DockType": data.DockType,
                "CRUDTYPE": "U",
                "CreatedDate": data.CreatedDate,
                "CreatedBy": data.CreatedBy,
                "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "UpdatedBy": cu_id,
                "Status": data.Status,
                "Deleted": data.Deleted
            }
        }
        else if (type === "Add") {
            return {
                "IDWarehouse": selectedWarehouse.ID,
                "DockType": data.DockType,
                "CRUDTYPE": "I",
                "CreatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "CreatedBy": cu_id,
                "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "UpdatedBy": cu_id,
                "Status": true,
                "Deleted": false
            }
        } else {
            return {
                "IDWarehouse": selectedWarehouse.ID,
                "DockType": null,
                "CRUDTYPE": "S",
                "CreatedDate": null,
                "CreatedBy": null,
                "UpdatedDate": null,
                "UpdatedBy": null,
                "Status": null,
                "Deleted": null
            }
        }
    }

    const createForm = () => {
        return (
            <CustomDynamicFormSS
                formFieldList={Object.keys(editData) && Object.keys(editData).length ? formFieldEditList : formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                // getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    const showCreateDockTypePopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: !Object.keys(editData).length ? AppConstant.smartScheduler.docks.createDockType : AppConstant.smartScheduler.docks.editDockType,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const closeCurrentpopup = () => {
        setEditData({});
        setShowModal(false);
    }

    const openCreateForm = () => {
        setShowModal(true);
        setParticularFieldValue({});
    }

    const editDock = (data) => {
        console.log("edit dock : ", data);
        const editData1 = {
            "ID": data.ID,
            "IDWarehouse": data.IDWarehouse,
            "DockType": data.DockType,
            "CRUDTYPE": "U",
            "CreatedDate": data.CreatedDate,
            "CreatedBy": data.CreatedBy,
            "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "UpdatedBy": cu_id,
            "Status": data.Status,
            "Deleted": data.Deleted

        }
        setShowModal(true);
        setEditData(editData1);
    }


    return (
        <div>
            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1'>
                <button
                    className={'btn-highlighted f-12 me-3'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}>
                    {AppConstant.smartScheduler.docks.dockType}
                </button>
            </div>
            <div className="d-flex justify-content-center metaDockList">
                <DockTypeList searchStr={searchStr} editDockTypeAction={(groupObj) => editDock(groupObj)} showPopup={() => showCreateDockTypePopup()} themeSelected={themeSelected} />
                {showCreateDockTypePopup()}
            </div>
        </div>

    )
}

export default DockType;