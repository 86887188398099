import React, { useEffect, useState } from 'react'
import CustomFieldWithTtileSS from '../../../../../../Common/CustomField/CustomFieldWithTtileSS'
import { AppConstant } from '../../../../../../../Assests/AppConstant'
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS'
import './PoIntakeAssignmentForm.css'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { sagaActions } from '../../../../../../../../sagas/sagaActions'
// import { getWarehouseDataReducer } from '../../../../../../../../redux/reducers/reducerSlices/GetWarehouseDataReducer'
import { fetchDockListCommonFunc } from '../../../../../SmartScheduler/WarehouseMetaData/Forms/DocksMeta/Common/FetchDockList'
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice'
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer'
import _ from 'lodash';
import { sortArrByKey } from '../../../../../../../Assests/Utility'

const initialErrors = {
  warehouse: "",
  dock: "",
  fileName: "",
  poNumber: "",
  quantity: "",
  itemsCases: "",
  pallets: "",
  vendorName: "",
  commodity: "",
  dueDate: "",
  loadType: "",
  excelDockName: "",
  company: "",
  poRelationID: "",
  poLink: "",
};
const PoIntakeAssignmentForm = (props) => {
  // const warehouseData = useSelector(getWarehouseDataReducer);
  const whMetaData = useSelector(getWhMetaDataReducerSlice);
  const { whMetaDocksList } = whMetaData;
  const { editData, createPayload, loading } = props
  const user = useSelector(getUserSelector);
  const { companyList, allWarehouseList, userType } = user;
  const [showDockTypeInForm, setShowDockTypeInFormForm] = useState(false);
  const [showExcelWarehouseInForm, setShowExcelWarehouseInForm] = useState(false);
  const [warehouseListState, setWarehouseListState] = useState([]);
  const [dockListState, setDockListState] = useState([]);
  const dispatch = useDispatch()
  const [inputs, setInputs] = useState({
    warehouse: '',
    dock: '',
    fileName: '',
    poNumber: '',
    quantity: '',
    defaultPoNumber: '',
    defaultQuantity: '',
    itemsCases: '',
    defaultItemsCases: '',
    pallets: '',
    vendorName: '',
    commodity: '',
    dueDate: '',
    loadType: '',
    defaultPallets: '',
    defaultVendorName: '',
    defaultCommodity: '',
    defaultDueDate: '',
    defaultLoadType: '',
    excelHeaderRow: '',
    excelDataRow: '',
    excelDockName: '',
    defaultDockName: '',
    company: '',
    excelWarehouseName: '',
    poRelationID: '',
    defaultPoRelationID: '',
    poLink : '',
    defaultPoLink: '',
  })

  const [errors, setErrors] = useState(initialErrors)

  const [dirty, setDirty] = useState({
    warehouse: false,
    dock: false,
    fileName: false,
    poNumber: false,
    quantity: false,
    itemsCases: false,
    pallets: false,
    vendorName: false,
    commodity: false,
    dueDate: false,
    loadType: false,
    excelDockName: false,
    company: false,
    excelWarehouseName: false,
    poRelationID: false,
    poLink: false,
  })

  const getCompanyList = () => {
    setWarehouseListState([]);
    setDockListState([]);
    const params = {
      "company_group_id": null
    }
    dispatch({ type: sagaActions.GET_COMPANY_LIST, payload: params });
  }

  useEffect(() => {
    dispatch({ type: sagaActions.RESET_ALL_WAREHOUSE_LIST })
    dispatch({ type: sagaActions.RESET_META_DOCKS_LIST })
    getCompanyList();
  }, [])

  useEffect(() => {
    if (editData && Object.keys(editData).length) {
      if (editData.CompanyID) {
        fetchWarehouseList(editData.CompanyID)
      }

      if (editData.IDWarehouse) {
        fetchDockList(editData.IDWarehouse)
      }

      if (editData?.IDDock === null && (editData?.ExcelDockName != null || editData?.DefaultDockName)) {
        setShowDockTypeInFormForm(true);
      }
      if (editData?.IDWarehouse === null && editData?.ExcelWarehouse != null) {
        setShowExcelWarehouseInForm(true);
      }

      let editObj = {
        company: editData?.CompanyID,
        warehouse: editData?.IDWarehouse ? editData.IDWarehouse : AppConstant.help.poIntakeDockExcelElementName,
        dock: editData?.IDDock ? editData.IDDock : AppConstant.help.poIntakeDockExcelElementName,
        fileName: editData?.FileName,
        poNumber: editData?.PONumberExcel,
        quantity: editData?.QuantityExcel,
        defaultPoNumber: editData?.PONumberDefault,
        defaultQuantity: editData?.QuantityDefault,
        itemsCases: editData?.ItemsCasesExcel,
        defaultItemsCases: editData?.ItemsCasesDefault,
        pallets: editData?.PalletsExcel,
        vendorName: editData?.VendorNameExcel,
        commodity: editData?.CommodityNameExcel,
        dueDate: editData?.DueDateExcel,
        loadType: editData?.LoadTypeExcel,
        defaultPallets: editData?.PalletsDefault,
        defaultVendorName: editData?.VendorNameDefault,
        defaultCommodity: editData?.CommodityNameDefault,
        defaultDueDate: editData?.DueDateDefault,
        defaultLoadType: editData?.LoadTypeDefault,
        excelHeaderRow: editData?.HeaderRow,
        excelDataRow: editData?.DataRow,
        excelDockName: editData?.ExcelDockName,
        defaultDockName: editData?.DefaultDockName,
        excelWarehouseName: editData?.ExcelWarehouse,
        poRelationID: editData?.ExcelPORelationID,
        defaultPoRelationID: editData?.DefaultPORelationID,
        poLink:editData?.POLinkExcel,
        defaultPoLink:editData?.POLinkDefault
      }
      setInputs(editObj)
    }
  }, [editData])



  useEffect(() => {
    if (_.size(allWarehouseList)) {
      setWarehouseListState(allWarehouseList)
    } else { setWarehouseListState([]) }
  }, [allWarehouseList])

  useEffect(() => {
    if (_.size(whMetaDocksList)) {
      setDockListState(whMetaDocksList)
    } else { setDockListState([]) }
  }, [whMetaDocksList])


  const fetchWarehouseList = (selectedCompanyID) => {
    dispatch({ type: sagaActions.UPDATE_ALL_WAREHOUSE, payload: { user_type: userType, cu_id: null, company_id: selectedCompanyID, warehouseId: null } })
  };

  useEffect(() => {
    if(_.size(allWarehouseList)){
        setWarehouseListState(allWarehouseList)
    }
},[allWarehouseList])


  const fetchDockList = (selectedWarehouse) => {
    const { apiParams, operationObj } = fetchDockListCommonFunc({ ID: selectedWarehouse });
    dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
  };

  /**
   * Function to handle change in form 
   * @param {*} data 
   * @param {*} name 
   * @param {*} control 
   */
  let handleChange = (data, name) => {
    if (typeof data === 'object') {
      data = data.toString();
    }
    if (name === 'company') {
      dispatch({ type: sagaActions.RESET_ALL_WAREHOUSE_LIST })

      fetchWarehouseList(data);
      // dispatch({ type: sagaActions.RESET_WAREHOUSE_META_DOCK_LIST_ACTION })
      // fetchDockList(data)

    }
    if (name === 'warehouse') {
      fetchDockList(data)
    }

    if (name === 'warehouse' && data.toUpperCase() === AppConstant.help.poIntakeDockExcelElementName.toUpperCase()) {
      setShowExcelWarehouseInForm(true)
    }

    if (name === 'dock' && data.toUpperCase() === AppConstant.help.poIntakeDockExcelElementName.toUpperCase()) {
      setShowDockTypeInFormForm(true)
    }

    if (name === 'warehouse' && data.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase()) {
      setShowExcelWarehouseInForm(false)
    }

    if (name === 'dock' && data.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase()) {
      setShowDockTypeInFormForm(false)
    }

    setInputs((inputs) => ({ ...inputs, [name]: data }));

  };




  /**
   * Function to handle errors
   * @param {*} name 
   */
  const handleErrors = (name) => {
    setDirty((dirty) => ({ ...dirty, [name]: true }));
    validate(name);
  };

  /**
   * Function to validate form inputs
   * @param {*} name 
   * @returns 
   */
  const validate = (name) => {

    let valid = true
    let errorData = { ...initialErrors }
    if (!inputs.warehouse) {
      errorData.warehouse = AppConstant.help.required
      valid = false
    }
    // if (!inputs.dock) {
    //   errorData.dock = AppConstant.help.required
    //   valid = false
    // }x
    if (!inputs.dock && showDockTypeInForm) {
      if (!inputs.excelDockName) {
        errorData.excelDockName = AppConstant.help.required
        valid = false
      }
    }

    if ((inputs.warehouse && inputs.warehouse.toUpperCase() === AppConstant.help.poIntakeDockExcelElementName.toUpperCase())) {
      if (!inputs.excelWarehouseName) {
        errorData.excelWarehouseName = AppConstant.help.required
        valid = false
      }
    }

    if (inputs.dock && inputs.dock.toUpperCase() === AppConstant.help.poIntakeDockExcelElementName.toUpperCase()) {
      if (!inputs.excelDockName && !inputs.defaultDockName) {
        errorData.excelDockName = AppConstant.help.required
        valid = false
      }
    }
    if (!inputs.fileName) {
      errorData.fileName = AppConstant.help.required
      valid = false
    }
    if (!inputs.poNumber && !inputs.defaultPoNumber) {
      errorData.poNumber = AppConstant.help.poInTakeRequired
      valid = false
    }
    if (!inputs.quantity && !inputs.defaultQuantity) {
      errorData.quantity = AppConstant.help.poInTakeRequired
      valid = false
    }
    if (!inputs.itemsCases && !inputs.defaultItemsCases) {
      errorData.itemsCases = AppConstant.help.poInTakeRequired
      valid = false
    } if (!inputs.commodity && !inputs.defaultCommodity) {
      errorData.commodity = AppConstant.help.poInTakeRequired
      valid = false
    } if (!inputs.vendorName && !inputs.defaultVendorName) {
      errorData.vendorName = AppConstant.help.poInTakeRequired
      valid = false
    } if (!inputs.dueDate && !inputs.defaultDueDate) {
      errorData.dueDate = AppConstant.help.poInTakeRequired
      valid = false
    } if (!inputs.loadType && !inputs.defaultLoadType) {
      errorData.loadType = AppConstant.help.poInTakeRequired
      valid = false
    } if (!inputs.pallets && !inputs.defaultPallets) {
      errorData.pallets = AppConstant.help.poInTakeRequired
      valid = false
    }if (!inputs.poRelationID && !inputs.defaultPoRelationID) {
      errorData.poRelationID = AppConstant.help.poInTakeRequired
      valid = false
    }
    // if (!inputs.poLink && !inputs.defaultPoLink) {
    //   errorData.poLink = AppConstant.help.poInTakeRequired
    //   valid = false
    // }
    // if (!inputs.poLink && !inputs.defaultPoLink) {
    //   errorData.poLink = AppConstant.help.poInTakeRequired
    //   valid = false
    // }

    setErrors(errorData)
    return valid
  }

  /**
* Function to handle form submit
*/
  const submitForm = () => {
    let dirtyData = dirty;
    Object.keys(dirty).forEach((control) => {
      dirtyData[control] = true;
    });


    setDirty(dirtyData);
    if (validate()) {
      if (editData && editData.ID) {
        let payload = createPayload({ ...editData, ...inputs }, "U")
        dispatch({ type: sagaActions.GET_PO_INTAKE_MANGEMENT_DETAILS, payload: payload })
      } else {
        let payload = createPayload({ ...inputs }, "I")
        console.log("payload I :", payload)
        dispatch({ type: sagaActions.GET_PO_INTAKE_MANGEMENT_DETAILS, payload: payload })
      }
    }
  };

  const getCompanyListData = () => {
    return companyList && companyList.length && companyList.map((ele, index) => {
      return (
        <option key={ele.CompanyId} value={ele.CompanyId}>
          {ele.CompanyName}
        </option>
      );
    })
  }

  const getWarehouseListData = () => {
    const newDockList = [...warehouseListState, { ID: AppConstant.help.poIntakeDockExcelElementName, WarehouseName: AppConstant.help.poIntakeDockExcelElementName }]
    let whList = newDockList && newDockList.length && sortArrByKey(newDockList, 'WarehouseName').map((ele, index) => {
      return (
        <option key={ele.ID} value={ele.ID}>
          {ele.WarehouseName}
        </option>
      );
    })
    return whList;
  }

  const getDockListData = () => {
    // const newDockList = [...whMetaDocksList, { ID: AppConstant.help.poIntakeDockExcelElementName, Name: AppConstant.help.poIntakeDockExcelElementName }]
    const newDockList = [...dockListState, { ID: AppConstant.help.poIntakeDockExcelElementName, Name: AppConstant.help.poIntakeDockExcelElementName }]
    let list = newDockList && newDockList.length && newDockList.map((ele, index) => {
      return (
        <option key={ele.ID} value={ele.ID}>
          {ele.Name}
        </option>
      );
    })
    return list;
  }

  //   const createWarehouseList = () => {
  //     if (_.isEmpty(warehouseListState)) {

  //         return checkDropDownIsEmpty()
  //     }
  //     const warehouseArr = warehouseListState && sortArrByKey(warehouseListState, 'WarehouseName').map((ele) => {
  //         return <li><a className="dropdown-item" onClick={() => setWarehouseName(ele)}>{ele.WarehouseName}</a></li>
  //     })

  //     return [...warehouseArr]
  // }
  return (
    <>
      <div className='po-intake-form'>
        <CustomFieldWithTtileSS
          type={"select"}
          showError={
            dirty["company"] && errors["company"][0]
              ? errors["company"]
              : ""
          }
          name={"company"}
          value={inputs["company"] ? inputs["company"] : ""}
          handleErrors={handleErrors}
          title={"Company"}
          required={true}
          onChange={(data, name) => handleChange(data, name)}
          listData={getCompanyListData}
          disabledField={editData && Object.keys(editData).length ? true : false}
        />
        <CustomFieldWithTtileSS
          type={"select"}
          showError={
            dirty["warehouse"] && errors["warehouse"][0]
              ? errors["warehouse"]
              : ""
          }
          name={"warehouse"}
          value={inputs["warehouse"] ? inputs["warehouse"] : ""}
          handleErrors={handleErrors}
          title={"Warehouse"}
          required={true}
          onChange={(data, name) => handleChange(data, name)}
          listData={getWarehouseListData}
          disabledField={editData && Object.keys(editData).length ? true : false}
        />
      </div>

      <div className='po-intake-form'>
        <CustomFieldWithTtileSS
          type={'select'}
          showError={dirty["dock"] && errors["dock"] ? errors["dock"] : ""}
          name='dock'
          listData={getDockListData}
          value={inputs.dock ? inputs.dock : ""}
          handleErrors={handleErrors}
          title='Dock'
          required={true}
          onChange={(data, name) => handleChange(data, name)}
          className={"f-14"}
          disabledField={editData && Object.keys(editData).length ? true : false}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={
            dirty["fileName"] && errors["fileName"][0]
              ? errors["fileName"]
              : ""
          }
          name={"fileName"}
          value={inputs.fileName ? inputs.fileName : ""}
          handleErrors={handleErrors}
          title={'File Name'}
          required={true}
          onChange={(data, name) => handleChange(data, name)}
        />
      </div>
      {showExcelWarehouseInForm ? <div className='po-intake-form-dock'>
        <p className='inputTitle'>Warehouse Name</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["excelWarehouseName"] && errors["excelWarehouseName"]
            ? errors["excelWarehouseName"]
            : ""}
          name={"excelWarehouseName"}
          value={inputs.excelWarehouseName ? inputs.excelWarehouseName : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
      </div> : ''}
      {showDockTypeInForm ? <div className='po-intake-form-dock'>
        <p className='inputTitle'>Dock Type</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["excelDockName"] && errors["excelDockName"][0]
            ? errors["excelDockName"]
            : ""}
          name={"excelDockName"}
          value={inputs.excelDockName ? inputs.excelDockName : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["excelDockName"] && errors["excelDockName"][0]
            ? errors["excelDockName"]
            : ""}
          name={"defaultDockName"}
          value={inputs.defaultDockName ? inputs.defaultDockName : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
      </div> : ''}
      <div className='po-intake-form-inside'>
        <p className='inputTitle'>Excel Read Position</p>
        <CustomFieldWithTtileSS
          type={"number"}
          showError=""
          name={"excelHeaderRow"}
          value={inputs.excelHeaderRow ? inputs.excelHeaderRow : ""}
          handleErrors={handleErrors}
          title={'Header Read Position'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"number"}
          showError=""
          name={"excelDataRow"}
          value={inputs.excelDataRow ? inputs.excelDataRow : ""}
          handleErrors={handleErrors}
          title={'Data Read Position'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <p className='inputTitle'>Po Number</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["poNumber"] && errors["poNumber"][0]
            ? errors["poNumber"]
            : ""}
          name={"poNumber"}
          value={inputs.poNumber ? inputs.poNumber : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["poNumber"] && errors["poNumber"][0]
            ? errors["poNumber"]
            : ""}
          name={"defaultPoNumber"}
          value={inputs.defaultPoNumber ? inputs.defaultPoNumber : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
          <p className='inputTitle'>Po Relation ID</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["poRelationID"] && errors["poRelationID"][0]
            ? errors["poRelationID"]
            : ""}
          name={"poRelationID"}
          value={inputs.poRelationID ? inputs.poRelationID : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["poRelationID"] && errors["poRelationID"][0]
            ? errors["poRelationID"]
            : ""}
          name={"defaultPoRelationID"}
          value={inputs.defaultPoRelationID ? inputs.defaultPoRelationID : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <p className='inputTitle'>Quantity</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["quantity"] && errors["quantity"][0]
            ? errors["quantity"]
            : ""}
          name={"quantity"}
          value={inputs.quantity ? inputs.quantity : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["quantity"] && errors["quantity"][0]
            ? errors["quantity"]
            : ""}
          name={"defaultQuantity"}
          value={inputs.defaultQuantity ? inputs.defaultQuantity : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />

        <p className='inputTitle'>Items Cases</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["itemsCases"] && errors["itemsCases"][0]
            ? errors["itemsCases"]
            : ""}
          name={"itemsCases"}
          value={inputs.itemsCases ? inputs.itemsCases : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["itemsCases"] && errors["itemsCases"][0]
            ? errors["itemsCases"]
            : ""}
          name={"defaultItemsCases"}
          value={inputs.defaultItemsCases ? inputs.defaultItemsCases : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />

        <p className='inputTitle'>Pallets</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["pallets"] && errors["pallets"][0]
            ? errors["pallets"]
            : ""}
          name={"pallets"}
          value={inputs.pallets ? inputs.pallets : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["pallets"] && errors["pallets"][0]
            ? errors["pallets"]
            : ""}
          name={"defaultPallets"}
          value={inputs.defaultPallets ? inputs.defaultPallets : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />

        <p className='inputTitle'>Vendor Name</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["vendorName"] && errors["vendorName"][0]
            ? errors["vendorName"]
            : ""}
          name={"vendorName"}
          value={inputs.vendorName ? inputs.vendorName : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["vendorName"] && errors["vendorName"][0]
            ? errors["vendorName"]
            : ""}
          name={"defaultVendorName"}
          value={inputs.defaultVendorName ? inputs.defaultVendorName : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />

        <p className='inputTitle'>Commodity</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["commodity"] && errors["commodity"][0]
            ? errors["commodity"]
            : ""}
          name={"commodity"}
          value={inputs.commodity ? inputs.commodity : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["commodity"] && errors["commodity"][0]
            ? errors["commodity"]
            : ""}
          name={"defaultCommodity"}
          value={inputs.defaultCommodity ? inputs.defaultCommodity : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />

        <p className='inputTitle'>Due Date</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["dueDate"] && errors["dueDate"][0]
            ? errors["dueDate"]
            : ""}
          name={"dueDate"}
          value={inputs.dueDate ? inputs.dueDate : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["dueDate"] && errors["dueDate"][0]
            ? errors["dueDate"]
            : ""}
          name={"defaultDueDate"}
          value={inputs.defaultDueDate ? inputs.defaultDueDate : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />

        <p className='inputTitle'>Load Type</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["loadType"] && errors["loadType"][0]
            ? errors["loadType"]
            : ""}
          name={"loadType"}
          value={inputs.loadType ? inputs.loadType : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["loadType"] && errors["loadType"][0]
            ? errors["loadType"]
            : ""}
          name={"defaultLoadType"}
          value={inputs.defaultLoadType ? inputs.defaultLoadType : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />

<p className='inputTitle'>Po Link</p>
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["poLink"] && errors["poLink"][0]
            ? errors["poLink"]
            : ""}
          name={"poLink"}
          value={inputs.poLink ? inputs.poLink : ""}
          handleErrors={handleErrors}
          title={'Excel Element Name'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />
        <CustomFieldWithTtileSS
          type={"text"}
          showError={dirty["poLink"] && errors["poLink"][0]
            ? errors["poLink"]
            : ""}
          name={"defaultPoLink"}
          value={inputs.defaultPoLink ? inputs.defaultPoLink : ""}
          handleErrors={handleErrors}
          title={'Default Value'}
          required={false}
          onChange={(data, name) => handleChange(data, name)}
        />


      </div>

      <div className="d-flex justify-content-end my-2">
        <CustomButtonSS
          isLoading={false}
          title={"Reset"}
          className={"btn-unstyled f-16"}
          onClick={() => setInputs({})}
        />
        <CustomButtonSS
          isLoading={loading}
          title={"Save"}
          className={"addButton"}
          onClick={submitForm}
        />
      </div>
    </>
  )
}

export default PoIntakeAssignmentForm