import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es'
import { AppConstant } from '../../Assests/AppConstant';
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../sagas/sagaActions';
import './ChooseWarehouse.css'
import CustomButton from '../Common/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { createNavBar } from '../../Assests/Utility';
import Loader from '../Loader/Loader';
import WarehouseSearch from '../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import NoData from '../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/Nodata';
import defaultWarehouseLogo from '../../Assests/Images/defaultWarehouse.png'

/**
 * This component create a list of Warehouse Card with Proceed button to allow SUPERADMIN 
 * to use any warehouse with some permissions
 * @param {*} props 
 * @returns 
 */
const ChooseWarehouse = (props) => {
    const { themeSelected, showWarehouseSearch } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userPermission = useSelector(getUserSelector);
    const { allWarehouseList, userWarehouseEmptyList, userType, selectedWarehouse, permissions, initateLoading, parentWarehouse } = userPermission;
    const loaderArrayRow = [0, 1, 2];
    let loaderArrayColumn = [...Array(3).keys()]
    const [allWarehouseListState, setAllWarehouseListState] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    
    /**
     * useEffect for warehouse management data and set data to state variable allWarehouseListState
     */
    useEffect(() => {
        if (allWarehouseList.length) {
            setAllWarehouseListState(allWarehouseList)
        }

    }, [allWarehouseList])

    /** useeEffect for search */
    useEffect(() => {
        if (searchStr.length > 3) {
            initiateSearch()
        } else if (searchStr === '') {
            setAllWarehouseListState(allWarehouseList)
        }
    }, [searchStr])

    /**
     * useEffect when warehouse is selected
     */
    useEffect(() => {
        // SUPERADMIN case
        if (userType === AppConstant.userPermission.usertypes.superAdmin || userType === AppConstant.userPermission.usertypes.administrator) {
            if (!_.isEmpty(selectedWarehouse)) {
                dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: createNavBar(permissions, AppConstant.userPermission.superwarehouse) })
                navigate('/loadmanagement')
            } else {
                dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: createNavBar(permissions, AppConstant.userPermission.usertypes.superAdmin) })
            }

        } else if (userType === AppConstant.userPermission.usertypes.user) {  // USER case
            if (!_.isEmpty(selectedWarehouse)) {
                dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: createNavBar(permissions, AppConstant.userPermission.superwarehouse) })
                if (!_.isEmpty(selectedWarehouse) && parentWarehouse.ID !== selectedWarehouse?.ID) {
                    navigate('/loadmanagement')
                }

            } else {
                dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: createNavBar(permissions, AppConstant.userPermission.usertypes.user) })
            }

        } else {
            // any other type of USER
            dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: createNavBar(permissions, AppConstant.userPermission.usertypes.superAdmin) })
        }
    }, [selectedWarehouse.ID])

    /**
     * Proceed button click handler function
     * @param {*} ele 
     */
    const onBtnClickHandler = (ele) => {
        const tempallWarehouseList = allWarehouseListState.map(el => {
            let tempEl = { ...el }
            if (ele.ID === el.ID) {
                tempEl.isloading = !ele.isloading
            }
            return tempEl
        })
        setAllWarehouseListState(tempallWarehouseList)
        if (userType === AppConstant.userPermission.usertypes.user && _.isEmpty(parentWarehouse)) {
            dispatch({ type: sagaActions.PARENT_WAREHOUSE, payload: ele })
        }
        dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: ele })
    }
    /**
     * 
     * @returns JSX for warehouse card
     */
    const createWarehouseCards = () => {
        const warehouseArr = allWarehouseListState.map((ele, index) => {
            return (
                <div key={ele + index} className='m-2 p-0 border border-dark border-0 rounded-4 justify-content-center text-center shadow col-2 flex-fill' >
                    <div className='m-1 p-2'>
                        {ele.WarehouseLogo ? <img className='p-0 m-0 warehouseIcon w-auto' src={ele.WarehouseLogo} alt="Warehouse Logo" /> : <img className='p-0 m-0 warehouseIcon w-auto' src={defaultWarehouseLogo} alt="Warehouse Logo" />}

                        <div className='seperator justify-content-center'></div>
                    </div>

                    <div className='warehouseCardText'>
                        <div className='m-0 p-0 fw-bold text-truncate'>{ele.WarehouseName || '-'}</div>
                        <div className='m-0 p-0 text-truncate'>{ele.Address || '-'}</div>
                        <div className='m-0 p-0 text-truncate'>{ele.City || '-'} {ele.Zip}</div>
                        <div className='m-0 p-0 text-truncate'>{ele.State || '-'}</div>
                        <div className='m-0 p-0 text-truncate'>{ele.Country || '-'}</div>
                        <div className='seperator justify-content-center'></div>
                        <CustomButton
                            isLoading={ele.isloading}
                            title={AppConstant.commonStrings.proceed}
                            className={`m-1 p-2 mt-2 mb-2 proceedButton`}
                            onClick={() => onBtnClickHandler(ele)}
                        />
                    </div>
                </div>
            )
        })
        return [...warehouseArr]
    }
    /**
     * 
     * @returns JSX for list warehouse card
     */
    const addWarehouseCards = () => {
        return (
            <div>
                <div className="d-flex flex-row justify-content-center row">
                    {createWarehouseCards()}
                </div>
            </div>

        )
    }
    /**
     * 
     * @returns JSX for loader
     */
    const showLoader = () => {
        return loaderArrayRow.map((_, i) => {
            return (
                <tr key={i}>

                    {
                        loaderArrayColumn.map((__, j) => {
                            return (
                                <td key={j} className='p-2'>
                                    <Loader height={200} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                                        baseColor={themeSelected === 'dark-mode' ? '#575757'
                                            : ''}
                                    />
                                </td>
                            )

                        })
                    }

                </tr>
            )
        })
    }
    /**
     * 
     * @returns JSX for no data available
     */
    const showNoDataError = () => {
        return (
            <div className='d-flex justify-content-center'><NoData themeSelected={themeSelected} /></div>
        )
    }
    /**
     * 
     * @returns JSX for loader/no data/list of warehouse card
     */
    const renderWarehouse = () => {
        if (userWarehouseEmptyList) {
            return showNoDataError()
        } else if (initateLoading) {
            return (
                <table>
                    <thead></thead>
                    <tbody>{showLoader()}</tbody>
                </table>
            )
        } else if (!initateLoading && allWarehouseList.length) {
            if(!allWarehouseListState.length){
                return showNoDataError()
            }
            return addWarehouseCards()
        }
    }
    /**
     * function to filter for data based on search string
     */
    function initiateSearch() {
        if (allWarehouseList && allWarehouseList.length && searchStr && searchStr.length) {
            const searchedArr = allWarehouseList.filter((whObj) => {
                if (_.includes(whObj.WarehouseName.toLowerCase().trim(), searchStr.toLowerCase().trim())) {
                    return whObj;
                }
            });
            setAllWarehouseListState(searchedArr);
        }
    }

    return (
        <div className={`container-fluid executor ${themeSelected}`}>
            <div>
                <div className='container-fluid d-flex flex-column justify-content-center align-items-center'>
                    <div className='container-fluid d-flex py-3 text-start justify-content-between'>
                        <div>{AppConstant.userPermission.warehouseListDropDownTitle}</div>
                        <div className='m-0 p-0 w-25'>
                            {showWarehouseSearch ? <WarehouseSearch
                                inputValue={searchStr}
                                placeholder={'Search Warehouse'}
                                cancelSearch={() => setSearchStr('')}
                                onChangeHandler={(text) => setSearchStr(text)} /> : ''}
                        </div>
                    </div>
                    <div className='m-0 p-0 warehouseListParent w-100 superwarehouse'>
                        {renderWarehouse()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseWarehouse;