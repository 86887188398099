import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { forEach } from 'lodash-es';
import { formDropDownData, showAlert, sortArrAplhabeticalyByKey } from '../../../../../Assests/Utility';
import { AppConstant } from '../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../sagas/sagaActions';
import WarehouseSearch from '../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../Components/DatePicker/DatePicker.css"
import { CustomPaginationSS } from '../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import DrilldownTableSS from '../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import { getGatePassselector } from '../../../../../../redux/reducers/reducerSlices/GatePassReducer'
import { toast } from 'react-toastify';
import "./GatePass.css";
import Popup from '../../../../Common/Popup/Popup';
import { CustomDynamicForm } from '../../../../Common/CustomForm/CustomDynamicForm';
import moment from 'moment-timezone';
import { customStylesLight } from '../../../../../Assests/Utility';
import CustomButtonSS from '../../../../Common/CustomButton/CustomButtonSS';


/**
 * This component is used to get the gatepass details and perform actions like create, edit, delete and select. 
 * And also this screen has search with warehouse name and pagination
 * @param {*} props 
 * @returns 
 */
const GatePass = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const [searchStr, setSearchStr] = useState('');
    const divRef = useRef(null);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration
    const gatepassReducer = useSelector(getGatePassselector)
    const { gatePassData, isLoading, operationObj, warehouseListForGatePass, gatePassDockList,isWarehouseLoading } = gatepassReducer;
    const [gatePassLoadArr, setGatePassLoadArr] = useState([]);
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [editData, setEditData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [particularFieldValue, setParticularFieldValue] = useState(null);
    const [resetFormData, setResetFormData] = useState(0);
    const [isListLoading, setListLoading] = useState(false);
    const [dockTypeList, setDockTypeList] = useState([]);
    const [warehouseListState, setWarehouseListState] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [selectedDoorHeader, setSelectedDoorHeader] = useState([]);
    const [selectedDoorInstr, setSelectedDoorInst] = useState([]);
    const [selectedYardHeader, setSelectedYardHeader] = useState([]);
    const [selecteYardInst, setSelectedYardInst] = useState([]);

    /** function to handle warehouse selection from drodown */
    const onWarehouseTypeSelcted = (props) => {
        if (props.length > 3) {
            dispatch({
                type: sagaActions.GET_WAREHOUSE_DETAILS_FOR_GATEPASS,
                payload: { searchParam: props },
            });
        }
    };
    const debounceFunc = useCallback(_.debounce(onWarehouseTypeSelcted, 1000), []);

    /**
     * Tabular array of headers to display
     */
    const titleDD = [
        { id: 'dockName', name: 'Dock name' },
        { id: 'language', name: 'Language' },
        { id: 'warehouseName', name: 'WH Name' },
        { id: 'createdOn', name: 'Created On' },
        { id: 'updatedOn', name: 'Updated On' },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    useEffect(() => {
        dispatch({
            type: sagaActions.CLEAR_GATEPASS,
        });
        return () => {
            dispatch({
                type: sagaActions.CLEAR_GATEPASS,
            });
        }
    }, []);

    /**
     * To handle API response types
     */
    useEffect(() => {
        if (operationObj && operationObj.type) {
            switch (operationObj.type) {
                case 'Delete GatePass':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.gatePassDetails.deleteSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.gatePassDetails.deleteFail);
                    }
                    break;
                case 'Edit GatePass':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.gatePassDetails.updateSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.gatePassDetails.updateFail);
                    }
                    break;
                case 'Create GatePass':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.gatePassDetails.insertSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, operationObj.message ? operationObj.message : AppConstant.gatePassDetails.insertFail);
                    }
                    break;
                case 'Fetch GatePass':
                    if (!operationObj.status) {
                        setListLoading(false);
                        setGatePassLoadArr([]);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    /**
      * useEffect to call fetch warehouse list data
      */
    useEffect(() => {
        if (_.size(warehouseListForGatePass)) {
            formWarehouseListData();
        }
    }, [warehouseListForGatePass]);
    /**
     * Helper to handle to the API response
     * @param {*} result 
     * @param {*} message 
     */
    const handleApiResponse = (result, message) => {
        setListLoading(false);
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')

        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            closeCurrentpopup();
            closeEditCurrentpopup();
            fetchData();

        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);

        }
        setLoading(false);
    }

    /**
   * used to filter and set the data to list if the search string is greater than 3 characters
   */
    useEffect(() => {
        if (searchStr.length > 3) {
            fetchData();
        } else if (searchStr === '') {
            fetchData();
        }
    }, [searchStr])

    /**
     * used to sort the dock list data when it has any changes
     */
    useEffect(() => {
        if (gatePassDockList) {
            setDockTypeList(sortArrAplhabeticalyByKey(gatePassDockList, 'dock_name'));
        }
        else {
            setDockTypeList([]);
        }
    }, [gatePassDockList]);

    /**
     * used to get the dock details based on the selected warehouse ID
     */
    useEffect(() => {
        if (selectedWarehouse) {
            dispatch({
                type: sagaActions.GET_GATEPASS_DOCK_DETAILS,
                payload: { warehouse_id: selectedWarehouse.value },
            });
        }

    }, [selectedWarehouse])

    /**
     * Used to set the gatepass data to the state variable
     */
    useEffect(() => {
        if (gatePassData && gatePassData?.data?.Items?.length) {
            setGatePassLoadArr(gatePassData)
        } else {
            setGatePassLoadArr([])
        }

    }, [gatePassData?.data?.Items])

    /**
     * form the gatepass language selection for drop down in form
     * @returns 
     */
    const getLangTypeList = () => formDropDownData(allConfigration, 'GatepassLang')

    /**
     * form the warehouse list to show in the selection
     */
    const formWarehouseListData = () => {
        if (_.size(warehouseListForGatePass)) {
            const warehouseData = warehouseListForGatePass.map((el) => {
                return {
                    label: el.name,
                    value: el.warehouse_id,
                };
            });
            setWarehouseListState([...warehouseData]);
        } else {
            setWarehouseListState([]);
        }
    };

    /**
     * on selected warehouse set it to setSelectedWarehouse state value
     * @param {*} props 
     */
    const onChangeValue = (props) => {
        setSelectedWarehouse(props)
    }

    /**
     * Gets the dock list and form the list to show in the drop down
     * @returns 
     */
    const getDockList = () => {
        return (
            dockTypeList &&
            dockTypeList.map((data, index) => {
                return (
                    data?.active &&
                    <option key={data.dock_id} value={data.dock_id}>
                        {data.dock_name}
                    </option>
                );
            })
        );
    };

    const onCreateDoorHeaderSelected = (props) => {
        setSelectedDoorHeader([...selectedDoorHeader, { label: props, value: props }])
    }

    const onCreateDoorInstSelected = (props) => {
        setSelectedDoorInst([...selectedDoorInstr, { label: props, value: props }])
    }
    const onCreateYardHeaderSelected = (props) => {
        setSelectedYardHeader([...selectedYardHeader, { label: props, value: props }])

    }
    const onCreateYardInstSelected = (props) => {
        setSelectedYardInst([...selecteYardInst, { label: props, value: props }])

    }
    const onWarehouseTypeChange = (props) => {
        return debounceFunc(props);
    }

    const handleRemoveOption = (option) => {
        // setSelectedOptions((prevOptions) => prevOptions.filter((o) => o.value !== option.value));
    };

    const handleRemoveDHOption = (option) => {
        setSelectedDoorHeader(option)
    }

    const handleRemoveDIOption = (option) => {
        setSelectedDoorInst(option)
    }
    const handleRemoveYHOption = (option) => {
        setSelectedYardHeader(option)

    }
    const handleRemoveYIOption = (option) => {
        setSelectedYardInst(option)

    }
    /**
     * List of Items to show on the create and edit popup 
     */
    const formFieldList = [
        {
            name: "warehouseSelection",
            type: "select_with_input_search_add_button_withoutcreate",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Select Warehouse",
            position: 1,
            title: AppConstant.gatePassDetails.selectWarehoue,
            customStylesLight: customStylesLight,
            placeholderText: "Type here...",
            onInputChange: onWarehouseTypeChange,
            onChange: (data) => onChangeValue(data),
            defaultValue: !_.isEmpty(selectedWarehouse)
                ? selectedWarehouse
                : "Select a warehouse from the list",
            isLoading: isWarehouseLoading,
            isClearable: Object.keys(editData).length ? true : false,
            isSearchable: true,
            options: warehouseListState,
            formatCreateLabel: () => { },
            // loadingMessage: "Please wait adding....",
            formValidation: {
                required: true,
            },
        },
        {
            name: "dockType",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title:
                AppConstant.gatePassDetails.dockType,
            formValidation: {
                required: true,
            },

            getSelectData: getDockList,
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "doorHeader",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Door Header",
            position: 3,
            title: AppConstant.gatePassDetails.doorHeaderTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onDoorHeaderinputchange,
            defaultValue: 'Select a door header from the list',
            isLoading: false,
            isClearable: true,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selectedDoorHeader,
            handleCreateOption: onCreateDoorHeaderSelected
        },
        {
            name: "doorInstructions",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Door Instructions",
            position: 4,
            title: AppConstant.gatePassDetails.doorInstructionsTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onDoorHeaderinputchange,
            defaultValue: 'Select a door instructions from the list',
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selectedDoorInstr,
            handleCreateOption: onCreateDoorInstSelected
        },
        {
            name: "yardHeader",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Yard Header",
            position: 5,
            title: AppConstant.gatePassDetails.yardHeaderTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onWarehouseTypeSelcted,
            // onChange: (data) =>  onChangeValue(data),
            defaultValue: "Select a yard header from the list",
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selectedYardHeader,
            handleCreateOption: onCreateYardHeaderSelected
        },
        {
            name: "yardInstructions",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Yard Instructions",
            position: 6,
            title: AppConstant.gatePassDetails.yardInstructionsTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onWarehouseTypeSelcted,
            // onChange: (data) =>  onChangeValue(data),
            defaultValue: "Select a yard instructions from the list",
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selecteYardInst,
            handleCreateOption: onCreateYardInstSelected
        },
        {
            name: "languageSelection",
            type: "select",
            size: "col-sm-6",
            position: 7,
            title:
                AppConstant.commonStrings.langType,
            formValidation: {
                required: true,
            },

            getSelectData: getLangTypeList,
            disabledField: Object.keys(editData).length ? true : false
        },
    ]

    /**
         * List of Items to show on the create and edit popup 
         */
    const editFormFieldList = [
        {
            name: "warehouseSelection",
            type: "text",
            size: "col-sm-6",
            position: 1,
            placeholder: '',
            title: AppConstant.fsWarehouse.whName,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "dockType",
            type: "text",
            size: "col-sm-6",
            position: 2,
            placeholder: '',
            title: AppConstant.gatePassDetails.dockType,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "doorHeader",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Door Header",
            position: 3,
            title: AppConstant.gatePassDetails.doorHeaderTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onDoorHeaderinputchange,
            defaultValue: 'Select a door header from the list',
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selectedDoorHeader,
            handleCreateOption: onCreateDoorHeaderSelected
        },
        {
            name: "doorInstructions",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Door Instructions",
            position: 4,
            title: AppConstant.gatePassDetails.doorInstructionsTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onDoorHeaderinputchange,
            defaultValue: 'Select a door instructions from the list',
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selectedDoorInstr,
            handleCreateOption: onCreateDoorInstSelected
        },
        {
            name: "yardHeader",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Yard Header",
            position: 5,
            title: AppConstant.gatePassDetails.yardHeaderTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onWarehouseTypeSelcted,
            // onChange: (data) =>  onChangeValue(data),
            defaultValue: "Select a yard header from the list",
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selectedYardHeader,
            handleCreateOption: onCreateYardHeaderSelected
        },
        {
            name: "yardInstructions",
            type: "select_with_input_search_with_multi_add_button_temp",
            size: "col-sm-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Enter Yard Instructions",
            position: 6,
            title: AppConstant.gatePassDetails.yardInstructionsTitle,
            customStylesLight: customStylesLight,
            placeholderText: "Add here...",
            // onInputChange: onWarehouseTypeSelcted,
            // onChange: (data) =>  onChangeValue(data),
            defaultValue: "Select a yard instructions from the list",
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: [],
            formatCreateLabel: () => { },
            loadingMessage: "Please wait adding....",
            formValidation: {
                required: false,
            },
            searchableSelectFormData: selecteYardInst,
            handleCreateOption: onCreateYardInstSelected
        },
        {
            name: "languageSelection",
            type: "text",
            size: "col-sm-6",
            position: 7,
            placeholder: '',
            title: AppConstant.commonStrings.langType,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
    ]


    /**
     * sets the data
     * @param {*} data 
     * @param {*} name 
     */
    const getFormFieldData = (data, name) => {

        if (name === "warehouseSelection") {
            setSelectedWarehouse(data)
        } else if (name === 'doorHeader') {
            setSelectedDoorHeader([...data])

        } else if (name === 'doorInstructions') {
            setSelectedDoorInst([...data])

        } else if (name === 'yardHeader') {
            setSelectedYardHeader([...data])

        } else if (name === 'yardInstructions') {
            setSelectedYardInst([...data])

        }

    }

    /**
     * This function used to get gatepass list data by connecting with API
     */
    const fetchData = (pageNo = 1, limit = PaginationDefaultLimit) => {
        setTimeout(() => {
            const operationObj = {
                type: '',
                status: ''
            }

            const params = {
                "currentPage": pageNo,
                "itemsPerPage": limit,
                "searchParam": searchStr,
                "crudType": 'S'
            }

            operationObj.type = 'Fetch GatePass'

            dispatch({ type: sagaActions.GET_GATEPASS_DETAILS, payload: params, operationObj: operationObj });
        }, 0)
    }

    /**
     * Helper to delete
     * @param {*} data 
     * @param {*} index 
     */
    const deleteHandler = (data, index) => {
        setSelectedObj(data, index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }

    /**
     * delete popup handler to delete the records based on the confirmation
     * @param {*} text 
     */
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else {
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                "dock_id": selectedObj.dock_id,
                "language": selectedObj.language,
                "crudType": 'D'
            }

            const operationObj = {
                type: 'Delete GatePass',
                status: ''
            }

            dispatch({ type: sagaActions.GET_GATEPASS_DETAILS, payload: apiParams, operationObj: operationObj })
        }
        setShowDeleteModel(false)
    }

    /**
     * Helper to show the delete conformation pop-up
     * @returns 
     */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.gatePassDetails.deleteGatePassTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.gatePassDetails.deleteGatePassTitle}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    /**
     * Helper function to get the submited data and forms it to create or update
     * @param {*} data 
     */
    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: ''
        }

        if (Object.keys(editData).length) {
            const apiTempParams1 = {
                "dock_id": data.dock_id,
                "language": _.lowerCase(data.languageSelection),
                "dock_name": data.dockType,
                "warehouse_name": data.warehouseSelection,
                "Warehouse_id": data.warehouse_Id,
                "door": {
                    "body": selectedDoorInstr.map(item => item.value),
                    "header": selectedDoorHeader.map(item => item.value)
                },
                "yard": {
                    "body": selecteYardInst.map(item => item.value),
                    "header": selectedYardHeader.map(item => item.value),
                }
            }
            operationObj.type = 'Edit GatePass'

            apiParams = {
                "crudType": 'U',
                "data": apiTempParams1
            }
        } else {
            const apiTempParams2 = {
                "dock_id": data.dockType,
                "language": _.lowerCase(data.languageSelection),
                "dock_name": findTheNameBasedOntheID(dockTypeList, data.dockType),
                "warehouse_name": data.warehouseSelection.label,
                "Warehouse_id": data.warehouseSelection.value,
                "door": {
                    "body": selectedDoorHeader.map(item => item.value),
                    "header": selectedDoorInstr.map(item => item.value)
                },
                "yard": {
                    "body": selectedYardHeader.map(item => item.value),
                    "header": selecteYardInst.map(item => item.value)
                }
            }

            operationObj.type = 'Create GatePass'

            apiParams = {
                "crudType": 'I',
                "data": apiTempParams2
            }
        }
        dispatch({ type: sagaActions.GET_GATEPASS_DETAILS, payload: apiParams, operationObj: operationObj })
    };

    /**
     * find the name based on the ID
     * @param {*} list 
     * @param {*} id 
     * @returns 
     */
    function findTheNameBasedOntheID(list, id) {
        const dockType = list.find(item => item.dock_id === id)
        return dockType?.dock_name
    }

    /**
     * Closes the popup
     */
    const closeCurrentpopup = () => {
        setShowModal(false);
        setEditData({});
        setSelectedWarehouse(null);
        setWarehouseListState([]);
        setSelectedDoorHeader([]); // on popup close clear the multi select values
        setSelectedDoorInst([]);
        setSelectedYardHeader([]);
        setSelectedYardInst([]);
        setDockTypeList([]);
    }
    /**
     * Helper function to show to popup to create the Gatepass
     * @returns 
     */
    const showCreatePOPopup = () => {
        const popupObj = {
            id: 'createGatePassFormPopup',
            modalLabel: 'createGatePassFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: AppConstant.gatePassDetails.createGatePass,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    /**
     * opens the create form
     */
    const openCreateForm = () => {
        setSelectedWarehouse(null);
        setWarehouseListState([]);
        setShowModal(true);
        setEditData({});
    }

    /**
     * Close the edit form
     */
    const closeEditCurrentpopup = () => {
        setShowEditModal(false);
        setEditData({});
        setSelectedWarehouse(null);
        setWarehouseListState([]);
        setSelectedDoorHeader([]); // on popup close clear the multi select values
        setSelectedDoorInst([]);
        setSelectedYardHeader([]);
        setSelectedYardInst([]);
        setDockTypeList([]);
    }
    /**
     * Helper function to show to popup to create the gatepass
     * @returns 
     */
    const showEditCreatePOPopup = () => {
        const popupObj = {
            id: 'createGatePassFormPopup',
            modalLabel: 'createGatePassFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: AppConstant.gatePassDetails.editGatePass,
            bodyText: editForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeEditCurrentpopup} themeSelected={themeSelected} showModal={showEditModal} />
    }


    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };
    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    /**
     * function to create form
     * @returns 
     */
    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                // cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                // getFormFieldData={(data, name) => getFormFieldData(data, name)}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

/**
 * function to open the edit form
 * @returns 
 */
    const editForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={editFormFieldList}
                sort={true}
                // cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    /**
     * Helper to get the data and populate on the screen while editing
     * @param {*} data 
     */
    const editClicked = (data) => {
        const doorHeader = formLabeValueList(data?.door?.body);
        const doorInst = formLabeValueList(data?.door?.header);
        const yardHeader = formLabeValueList(data?.yard?.body);
        const yardInst = formLabeValueList(data?.yard?.header);

        setSelectedDoorHeader(doorHeader)
        setSelectedDoorInst(doorInst)
        setSelectedYardHeader(yardHeader)
        setSelectedYardInst(yardInst)


        let editData1 = {
            "warehouseSelection": data?.warehouse_name,
            "warehouse_Id": data?.Warehouse_id,
            "dock_id": data?.dock_id,
            "dockType": data?.dock_name,
            "languageSelection": data?.language,
            "door": {
                "body": data?.door?.body,
                "header": data?.door?.header
            },
            "yard": {
                "body": data?.yard?.body,
                "header": data?.yard?.header
            }
        }

        setEditData(editData1);
        setShowEditModal(true);
        setParticularFieldValue({ ...editData1 })
    }
    const formLabeValueList = (list) => {
        return list.map(item => ({ label: item, value: item }));
    };

    /**
     * Function to create action buttons
     * @param {*} groupObj 
     * @param {*} index 
     * @returns 
     */
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <button
                        className={'addButton'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createGatePassFormPopup`}
                        onClick={() => editClicked(groupObj)}
                    >
                        {AppConstant.commonStrings.edit}
                    </button>
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                </span>
            </div>
        )
    }

    /**
     * function to show the formated list data on the screen
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                0: el.dock_name || '-',
                1: el.language || '-',
                2: el.warehouse_name || '-',
                3: el.createdOn ? `${moment(el.createdOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.createdOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` : AppConstant.commonStrings.hypen,
                4: el.updatedOn ? `${moment(el.updatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.updatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` : AppConstant.commonStrings.hypen,
                5: actionsButton(el, index),
            }
        })
    }

    /**
     * This function is used to get the data and validate
     * @returns 
     */
    const getBodyData = () => {
        if (gatePassLoadArr && gatePassLoadArr?.data?.Items?.length) {
            return formatListDataDrillDown(gatePassLoadArr?.data?.Items)
        }
        else {
            return []
        }
    }

    /**
     * Function used when page is changed in pagination then it fetches user rating deails
     * @param {*} pageNo 
     * @param {*} limit 
     */
    const pageChange = (pageNo, limit) => {
        fetchData(pageNo, limit)
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <button
                    className={'addButton'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}
                >
                    {AppConstant.gatePassDetails.createGatePass}
                </button>
                <div className='d-flex justify-content-end align-items-end'>
                    <div className='ms-2 w-100'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleWarehouseStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>

                </div>

            </div>
            <div className='gatePassContainer' ref={divRef}>
                <DrilldownTableSS
                    key={Math.random()}
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={titleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    hasCollapsableContent={false}
                    collapsableData={[]}
                    accordionPrimaryIndex={1}
                    drillDownReport={gatePassLoadArr?.data?.Items}
                    initiateDrillDownReport={isLoading}
                    loaderArray={6}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'FsWarehouseObj' }}
                    showUTCTime={true}
                />
                {showDeleteConfirmation()}

            </div>
            {showCreatePOPopup()}
            {showEditCreatePOPopup()}

            {!_.isEmpty(gatePassLoadArr) && gatePassLoadArr?.data?.Items?.length ?
                <div className='f-14'>
                    <CustomPaginationSS
                        pageNo={gatePassLoadArr.data.currentPage}
                        pageSize={gatePassLoadArr.data.itemsPerPage}
                        first={(Number(gatePassLoadArr.data.currentPage) === 1) ? true : false}
                        last={Math.ceil(gatePassLoadArr.data.totalCount / Number(gatePassLoadArr.data.itemsPerPage)) === (Number(gatePassLoadArr.data.currentPage)) ? true : false}
                        totalElements={gatePassLoadArr.data.totalCount}
                        totalPages={gatePassLoadArr.data.totalPages}
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                    />
                </div> : ""
            }

        </div>
    )
}

export default GatePass