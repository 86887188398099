import React, { useEffect, useState } from 'react'
import './SSAppointmentSearch.css'
import { useDispatch } from 'react-redux';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import InputWithTtileSS from '../../../../../../Common/CustomInput/InputWithTtileSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import _ from 'lodash'
export const SSAppointmentSearch = (props) => {
    const dispatch = useDispatch()
    const [highlighted, setHighlighted] = useState(false);
    const { searchAppointment, onEmailChange, initateLoading, themeSelected = 'light-mode', showPdfHelperPopup, userType, po_number, receivingZipcode, searchedPOErrorData,showHighLight=true } = props;
    const [isLoading, setIsLoading] = useState(initateLoading)

    useEffect(() => {
        // Clear Saerched PO results when components unmount
        // Commented because of new UI
        // return () => {
        //     dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        // }
        if (showHighLight) {
        // Highlight the div when the component mounts
        setHighlighted(true);

        // Remove the highlight after a specified time (e.g., 3 seconds)
        const timer = setTimeout(() => {
            setHighlighted(false);
        }, 3000);          
    }
    }, [])

    useEffect(() => {
        setIsLoading(initateLoading)
    }, [initateLoading])

    const emailAppiontment = () => {

    }

    return (
        <div className={highlighted ? 'appt-search-container highlighted-div' : 'appt-search-container'}>
            <div className='p-3'>
                <i className="fa-regular fa-calendar"></i> Book Appointment
            </div>
            <hr />
            <div className='px-3 py-2 d-flex flex-column h-85 justify-content-between'>

                <div className='form-block-ss'>
                    <InputWithTtileSS
                        errorMessage={AppConstant.poManagement.poDetails.errorMessage}
                        showError={false}
                        title={AppConstant.poManagement.poDetails.homeScreenPoName}
                        toolTipId='po-search-tool-tip'
                        titleToolTipText={''}
                        placeHolder={AppConstant.poManagement.poDetails.homeScreenPoName}
                        customClass='form-control-ss'
                        onEmailChange={(e) => onEmailChange(e, 'po')}
                        required={true}
                        titleIcon={false}
                        titleIconClass={''}
                        value={po_number ? po_number : ''}
                    />
                </div>
                <br />
                <div className='form-block-ss'>
                    <InputWithTtileSS
                        errorMessage={'Enter correct zip'}
                        showError={false}
                        toolTipId='po-search-zip-code-tool-tip'
                        title={'Facility Zip Code'} placeHolder={'Zip Code'}
                        titleToolTipText={AppConstant.poManagement.poTooltip.zip}
                        onEmailChange={(e) => onEmailChange(e, 'zipcode')} required={true}
                        customClass='form-control-ss'
                        titleIcon={false}
                        titleIconClass={''}
                        disabled={userType === 'CARRIER' ? false : true}
                        value={receivingZipcode ? receivingZipcode : ''}
                    />
                </div>
                <CustomButtonSS
                    isLoading={isLoading}
                    title={'Search'}
                    className={"submit-btn-ss"}
                    onClick={() => searchAppointment()}
                />
                {searchedPOErrorData && <span className='subText textRed py-2'>{searchedPOErrorData}</span>}
            </div>

        </div>
    )
}
