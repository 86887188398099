import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    listLoading: false,
    poIntakeList: [],
    responseObject: '',
    poIntakeLogList: [],
    poIntakeLogPayload: '',
    LastEvaluatedKey: null,
    LastPage: false
}

export const PoIntakeMangementReducer = createSlice({
    name: 'PoIntakeMangementReducer',
    initialState,
    reducers: {
        updateWarehouseList: (state, action) => {
            state.warehouseList = action.payload
        },
        updateDockList: (state, action) => {
            state.dockList = action.payload
        },
        updatePoIntakeList: (state, action) => {
            state.poIntakeList = action.payload
        },
        updateLoading: (state, action) => {
            state.loading = action.payload
        },
        updateListLoading: (state, action) => {
            state.listLoading = action.payload
        },
        updateResponseObject: (state, action) => {
            state.responseObject = action.payload
        },
        updateLogList: (state, action) => {
            state.poIntakeLogList.push(...action.payload)
        },
        updatePoIntakeLogPayload: (state, action) => {
            state.poIntakeLogPayload = action.payload
        },
        updateLastEvaluatedKey: (state, action) => {
            state.LastEvaluatedKey = action.payload
        },
        updateLastPage: (state, action) => {
            state.LastPage = action.payload
        },
        clearPoIntakeLog: (state, action) => {
            state.poIntakeLogList = []
        },
    }
});

export const { updateWarehouseList, updateDockList, updatePoIntakeList, updateLoading, updateListLoading, updateResponseObject, updateLogList, updatePoIntakeLogPayload, updateLastEvaluatedKey, updateLastPage, clearPoIntakeLog } = PoIntakeMangementReducer.actions;

// Selectors
export const getPoIntakeMangementSelector = (state) => {
    return state.PoIntakeMangementReducer;
};

export default PoIntakeMangementReducer.reducer;
