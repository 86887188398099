import React, { useEffect, useState } from 'react';
import DrilldownTable from '../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getAuthReducer } from '../../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../sagas/sagaActions';


const UserAssignRoleTable = (props) => {

    const { themeSelected, updateUserRolePayload, selectCompanyGroup, selectCompany, setWarehouseName, setRoleName, setSelectedUserAssign } = props;
    const [userAssignRoleData, setUserAssignRoleData] = useState([]);
    const user = useSelector(getUserSelector)
    const { initateLoading, userRoleDetailsList, selectedUser, arrangeOrder, arrangeKey } = user;
    const [loadingIndex, setLoadingIndex] = useState(-1);
    const userAuth = useSelector(getAuthReducer);
    const dispatch = useDispatch()

    const warehouseTitleDD = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'Email', name: 'Email' },
        { id: 'GroupName', name: 'Company Group' },
        { id: 'CompanyName', name: 'Company' },
        { id: 'WarehouseName', name: 'Warehouse' },
        { id: 'RoleName', name: 'Role' },
        { id: 'CreatedOn', name: 'Created on' },
        { id: 'actions', name: 'Actions' },
    ]

    useEffect(() => {
        if (loadingIndex === -1) {
            setTimeout(() => {
                dispatch({ type: sagaActions.UPDATE_USER_ROLE_ASSIGN, payload: updateUserRolePayload('S')});
            },[0])
        }
    }, [loadingIndex])

    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })
        setTimeout(() => {
            dispatch({ type: sagaActions.UPDATE_USER_ROLE_ASSIGN, payload: updateUserRolePayload('S') })
        },[0])
    }, [])

    useEffect(() => {
        setLoadingIndex(-1)
        if (userRoleDetailsList.length) {
            initateLoading && dispatch({ type: sagaActions.INITIATE_LOADING, payload: false })
            let data = formatListData(userRoleDetailsList)
            setUserAssignRoleData(data)
        }
    }, [userRoleDetailsList])


    const clickHandler = (key, user, index) => {

        setSelectedUserAssign(user)
        let obj = { enable_status: true, cu_id: null, wh_id: null, role_id: null, crud_type: null, updated_object: null, comp_group_id: null, comp_id: null, user_wh_role_id: null }
        switch (key) {
            case AppConstant.commonStrings.edit:
                selectCompanyGroup({ GroupName: user.GroupName, CompanyGroupId: user.CompanyGroupId })
                selectCompany({ CompanyName: user.CompanyName, CompanyId: user.CompanyId })
                setWarehouseName({ WarehouseName: user.WarehouseName, ID: user.WarehouseID })
                setRoleName({ RoleName: user.RoleName, RoleID: user.RoleID })
                break;
            case AppConstant.commonStrings.disable:
                obj.cu_id = selectedUser.CUID
                obj.crud_type = 'D'
                obj.wh_id = user.WarehouseID
                obj.enable_status = false
                obj.user_wh_role_id = user.UserWHRoleID
                obj.user_type = selectedUser.UserType;
                dispatch({ type: sagaActions.UPDATE_USER_ROLE_ASSIGN, payload: obj })
                break;
            case AppConstant.commonStrings.enable:
                obj.cu_id = selectedUser.CUID
                obj.crud_type = 'D'
                obj.wh_id = user.WarehouseID
                obj.enable_status = true
                obj.user_wh_role_id = user.UserWHRoleID
                obj.user_type = selectedUser.UserType;
                dispatch({ type: sagaActions.UPDATE_USER_ROLE_ASSIGN, payload: obj })
                break;

            default:
                break;
        }

        setLoadingIndex(index)
    }

    const actionsButton = (el, index) => {
        return (
            <div>
                <span className='px-1'>
                    <CustomButton
                        onClick={() => clickHandler(AppConstant.commonStrings.edit, el, index)}
                        title={AppConstant.commonStrings.edit}
                        className={'addButton'}
                        isLoading={false}
                        disabled={false}
                        dataBsToggle="modal"
                        dataBsTarget={`#editUserAssignRolesFormPopup`}>
                    </CustomButton>
                </span>
                <span className='px-1'>
                    <CustomButton
                        onClick={() => clickHandler(el.EnableStatus ? AppConstant.commonStrings.disable : AppConstant.commonStrings.enable, el, index)}
                        title={el.EnableStatus ? AppConstant.commonStrings.disable : AppConstant.commonStrings.enable}
                        className={el.EnableStatus ? 'deleteButton' : 'enableButton'}
                        isLoading={loadingIndex !== -1 && index === loadingIndex}
                        disabled={false}>
                    </CustomButton>
                </span>
            </div>
        )
    }

    const formatListData = (data) => {
        return data.map((el, index) => {
            return {
                id: el.CUID + el.UserWHRoleID || index,
                0: (index + 1),
                1: el.Email,
                2: el.GroupName,
                3: el.CompanyName,
                4: el.WarehouseName,
                5: el.RoleName,
                6: el.CreatedOn,
                7: actionsButton(el, index),
            }
        })
    }

    return (
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={userAssignRoleData}
            titleData={warehouseTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={userRoleDetailsList}
            initiateDrillDownReport={initateLoading}
            loaderArray={8}
            sortingAction={sagaActions.SORT_USER_TABLE}
            subRoot={{ pathName: 'userAssign' }}
            showUTCTime={false}
        />
    )
}

export default UserAssignRoleTable;