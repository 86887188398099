import React, { useEffect, useState } from "react";

const CustomTimeSelector = (props) => {
    const { format = "24-hour",name,value,disabledField,error,title,timeSlotStart = "NA" } = props

  const generateTimeOptions = () => {
    const options = [];
    const is24HourFormat = format === "24-hour";
    let [startHour, startMinute] = timeSlotStart!=="NA" ? timeSlotStart.split(":").map(Number):"00:00".split(":").map(Number);

    if (timeSlotStart !== "NA") {
      startMinute += 15;
      if (startMinute >= 60) {
        startMinute = 0;
        startHour += 1;
      }
    }

    while (startHour < (is24HourFormat ? 24 : 12)) {
      const hourString = is24HourFormat
        ? startHour.toString().padStart(2, "0")
        : ((startHour % 12) || 12).toString().padStart(2, "0");
      const minuteString = startMinute.toString().padStart(2, "0");
      const meridian = !is24HourFormat && startHour < 12 ? "AM" : "PM";

      options.push(
        is24HourFormat
          ? `${hourString}:${minuteString}`
          : `${hourString}:${minuteString} ${meridian}`
      );

      startMinute += 15;
      if (startMinute >= 60) {
        startMinute = 0;
        startHour += 1;
      }
    }

    return options;
  };



  return (
      <select value={value} name={name}
       className={error ? "customSelect customError" : "customSelect"}
        onBlur={(data) => props.handleErrors(data.target.name)}
        onChange={(data) => {
             props.onChange(data.target.value, data.target.name)}
        }
        disabled={disabledField}
       >
        <option value="">Select {title}</option>
        {generateTimeOptions().map((time, index) => (
          <option key={index} value={time}>
            {time}
          </option>
        ))}
      </select>
  );
};

export default CustomTimeSelector;
