import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import _ from 'lodash-es';
import { useDispatch, useSelector } from "react-redux";
import { sagaActions } from "../../../../../../../../sagas/sagaActions";
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import { getPoIntakeMangementSelector } from '../../../../../../../../redux/reducers/reducerSlices/PoIntakeMangementReducer';
import Header from '../../../../../../Header/Header';
import Navigationbar from '../../../../../../Navigationbar/Navigationbar';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import TinyLoader from '../../../../../../Common/TinyLoader/TinyLoader';
import { getRemoteConfigSelector } from '../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';

/**
 * Used to show the warehouse list items and actions
 */
const PoIntakeLog = (props) => {
    const { themeSelected } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const poIntakeMangementDetails = useSelector(getPoIntakeMangementSelector);
    const { listLoading, loading: isLoading, poIntakeLogPayload, poIntakeLogList, LastPage, LastEvaluatedKey } = poIntakeMangementDetails
    const divRef = useRef(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const first = useRef(true);
    const firstPageLoad = useRef(true);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration;
    const user = useSelector(getUserSelector);
    const { userType } = user
    useEffect(() => {
        dispatch({
            type: sagaActions.GET_PO_INTAKE_LOGS, payload: {
                CRUD: 'C'
            }
        })
    }, [])

    useEffect(() => {
        if (poIntakeLogPayload) {
    console.log("poIntakeLogPayload : ", poIntakeLogPayload)
            dispatch({
                type: sagaActions.GET_PO_INTAKE_LOGS, payload: {
                    CRUD: 'S',
                    CompanyID:poIntakeLogPayload?.CompanyID,
                    MappingFileName:poIntakeLogPayload?.FileName,
                    limit: 20,
                    lastEvaluatedKey: null

                }
            })
        }
    }, [poIntakeLogPayload])

    useEffect(() => {
        if (firstPageLoad.current) {
            firstPageLoad.current = false
            return
        }
        let tout;
        tout = setTimeout(() => {
            if (page) {
                fetchData(page)
            }
        }, 1000)

        return () => clearTimeout(tout)
    }, [page])

    /**
* This function used to fetch eclipse ops details by connecting with API
*/
    const fetchData = (pageNo = 1, limit = 20) => {
        if ((!LastPage || pageNo === 1) && !isLoading) {
            setTimeout(() => {

                // const eDate = moment(selectedEndDate).hours(23).minutes(59).seconds(59)

                const params = {
                    CRUD: 'S',
                    // IDWarehouse: poIntakeLogPayload?.IDWarehouse,
                    // IDDock: poIntakeLogPayload?.IDDock,
                    CompanyID:poIntakeLogPayload?.CompanyID,
                    MappingFileName:poIntakeLogPayload?.FileName,
                    limit: 20,
                    // lastEvaluatedKey: null,
                    "currentPage": pageNo,
                    "itemsPerPage": limit,
                    // "searchParam": '',
                    // "filterParam": selectedWarehouse?.warehouseName,
                    "lastEvaluatedKey": pageNo === 1 ? null : LastEvaluatedKey
                }
                dispatch({
                    type: sagaActions.GET_PO_INTAKE_LOGS, payload: params
                })
            }, 0)
        }

    }

    const handelInfiniteScroll = (e) => {
        try {
            if (e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 1 && !isLoading) {
                setLoading(true);
                setPage((prev) => prev + 1);
            }
        } catch (error) {
        }
    };


    const poIntakeLogTitleDD = [
        { id: 'Id', name: 'S.No' },
        // { id: 'WarehouseName', name: 'Warehouse' },
        // { id: 'DockName', name: 'Dock' },
        { id: 'OriginalFileName', name: 'File Name' },
        { id: 'TotalRecords', name: 'Total' },
        { id: 'PassedRecords', name: 'Passed' },
        { id: 'SkippedRecords', name: 'Skipped' },
        { id: 'FailedRecords', name: 'Failed' },
        { id: 'FailedRecordsFilePath', name: 'Failed File' },
        { id: 'JobStart', name: 'Job Start' },
        { id: 'JobEnd', name: 'Job End' },
        { id: 'JobStatus', name: 'Status' },
        { id: 'CreatedDate', name: 'Created Date' }
    ]


    const formatListData = (data) => {
        return data && data.map((el, index) => {
            return {
                id: el.IDJob,
                0: (index + 1),
                // 1: poIntakeLogPayload.WarehouseName || '-',
                // 2: poIntakeLogPayload.DockName || '-',
                1: el.OriginalFileName || '-',
                2: el.TotalRecords || '-',
                3: el.PassedRecords || '-',
                4: el.SkippedRecords || '-',
                5: el.FailedRecords || '-',
                6: el.FailedRecordsFilePath ? <span onClick={() => window.open(el.FailedRecordsFilePath, '_blank')}><a href={el.FailedRecordsFilePath}>Download</a></span> : '-',
                7: el.JobStart || '-',
                8: el.JobEnd,
                9: el.JobStatus || '-',
                10: el.CreatedDate || '-',
            }
        })
    }

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <div className='d-flex justify-content-between align-items-center'>
            <div className='my-3'>
                <Link to="/pointakemanagement" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                    <span className='m-2 p-2 cursor-pointer'><i className='fa fa-arrow-left'></i></span>
                </Link>
                <span className='bolderText'>{`${poIntakeLogPayload?.FileName}`} Logs</span>
            </div>
            <div>
            <CustomButtonSS
                                    isLoading={false}
                                    title={''}
                                    className={"btn-white f-12 h-100"}
                                    onClick={() => {fetchData(1)}}
                                    isIcon={true}
                                    iconClass='fa-solid fa-arrows-rotate'
                                />
            </div>
            </div>
            <div className='eclipseOpsListContainer' style={{ maxHeight: userType === "SUPERADMIN" ? "55vh" : "65vh" }} ref={divRef} onScroll={(e) => handelInfiniteScroll(e)}>
                <DrilldownTable
                    themeSelected={themeSelected}
                    bodyData={poIntakeLogList && poIntakeLogList.length ? formatListData(poIntakeLogList) : []}
                    titleData={poIntakeLogTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={false}
                    drillDownReport={poIntakeLogList}
                    initiateDrillDownReport={page === 1 && listLoading ? true : false}
                    loaderArray={11}
                    sortingAction={sagaActions.WAREHOUSE_LIST_SORTING}
                    subRoot={{ pathName: 'poIntakeManahementlogs' }}
                    showUTCTime={true}
                />
            </div>
            {listLoading ? <div className='d-flex justify-content-center w-100'>
                <TinyLoader />
            </div> : ""
            }
        </div>
    )
}

export default PoIntakeLog;