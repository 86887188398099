import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MyAppointmentsSS.css";
import { SSCarrierPO } from "../DashBoard/Children/SSCarrierPO/SSCarrierPO";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { calculatePageNumber, displayLoader, formParticularFieldValueForAssociatedPO, formatDate_MM_DD_YYYY, formatPOwithPORelationID } from "../../../../../Assests/Utility";
import { sagaActions } from "../../../../../../sagas/sagaActions";
import { AppConstant } from "../../../../../Assests/AppConstant";
import _ from "lodash";
import { getUserSelector } from "../../../../../../redux/reducers/reducerSlices/UserReducer";
import moment from "moment";
import { getPoSSselector } from "../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer";
import { fetchSSApptListCommonFunc } from "../../../../LandingPage/SmartScheduler/MyAppointments/Common/FetchSSApptList";
import { smartScheduleSelector } from "../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer";
import ScheduleProWrapper from "../../ScheduleProWrapper";
import SSPopup from "../../../../Common/Popup/SSPopup/SSPopup";
import WarehouseSearchSS from "../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS";
import DatePickerSS from "../../../../DatePickerSS/DatePickerSS";
import NoDataImg from '../../../../../Assests/Images/nodata-xsm.png'
import SendEmailSS from "../../../../sendEmailSS";
import SelectRescheuleReason from "../../../../LandingPage/SmartScheduler/BookAppointments/Components/SelectReasonForRescheduleOrCancel/SelectRescheuleReason";
import { CustomPaginationSS } from "../../../../Common/CustomPaginationSS/CustomPaginationSS";


const MyAppointmentsSS = (props) => {
    const { themeSelected = 'light-mode', showHeader = true, showNavBar = true, showAppointments = true, actionsButtonsArr = ['Email', 'Delete', 'Reschedule'] } = props;
    const dispatch = useDispatch();
    const [showCalendar, setShowCalendar] = useState(false)
    const [pastApptsList, setPastApptsList] = useState([]);
    const [upcomingApptsList, setUpcomingApptsList] = useState([]);
    const ss = useSelector(smartScheduleSelector);
    const { appointmentList, initateLoading, selectedDateForApptUpdates, appointmentConfirmation, appointmentDocumentsList } = ss;
    const [isListLoading, setListLoading] = useState(false);
    const pOSS = useSelector(getPoSSselector);
    const [searchStr, setSearchStr] = useState(null);
    const navigate = useNavigate();
    const { upcomingPastApptObj } = pOSS;
    const userObj = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id, userType, userAccountDetails } = userObj;
    const [showSendMailModal, setShowSendEmailModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPoObj, setSelectedPoObj] = useState({});
    const [deletePo, setDeletePO] = useState(false);
    const [currentApptList, setCurrentApptList] = useState(null);
    // const [currentFilterSelected, setCurrentFilterSelected] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const first=useRef(true)
    const [rescheduleReason, setRescheduleReason] = useState(null);
    const [showRescheduleReason, setShowRescheduleReason] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const PaginationDefaultLimit = 6
    
    useEffect(() => {
        setCurrentApptList('Upcoming')
        return () => {
            dispatch({type: sagaActions.CLEAR_UPLOAD_APPT_DOCS})
        }
    },[])
    useEffect(() => {
        setListLoading(true);
        dispatch({ type: sagaActions.RESET_SS_UPCOMING_PAST_APPTS_ACTION });
        dispatch({ type: sagaActions.CLEAR_APPOINTMENT })
        setUpcomingApptsList([])
        setPastApptsList([])
    }, [appointmentList.response])

    useEffect(() => {
        if (isListLoading && !initateLoading) {
            setListLoading(initateLoading)
        }
    }, [initateLoading])
    
    useEffect(() => {
        if (first.current) {
            first.current=false
            return
        }
        setPastApptsList([]);
        setUpcomingApptsList([]);
        fetchAppointmentsList();
    }, [selectedDate])

    useEffect(() => {
        if (Object.keys(selectedPoObj) && Object.keys(selectedPoObj).length > 0) {
            if (selectedPoObj.action === 'email') {
                setShowSendEmailModal(true);
            } else if (selectedPoObj.action === 'delete') {
                setShowDeleteModal(true);
            }
        }
    }, [selectedPoObj])

    useEffect(() => {
        // refetch data after delete appointment
        if (deletePo && appointmentConfirmation) {
            const pageNo = upcomingPastApptObj?.pageNo;
            const limit = upcomingPastApptObj?.limit;
            const totalElements = upcomingPastApptObj?.totalElements
            let newPageNo = calculatePageNumber(pageNo, limit, totalElements - 1);
            fetchAppointmentsList(newPageNo, limit)
            setDeletePO(false)
            dispatch({
                type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,
                payload: false,
              });
        }
    }, [appointmentConfirmation])

    useEffect(() => {
        if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length) {
            populateArr();
            setListLoading(false)
        } else {
            if (!upcomingPastApptObj) {
                setListLoading(false)
            }
        }
    }, [upcomingPastApptObj])

    const formatdata = (data, orderBy) => {
        // let result = []
        // let newlist = _.groupBy(data, 'PORelationID')
        // let finaldata = Object.values(newlist);
        // finaldata.forEach(element => {
        //     let l = element.sort((a, b) => {
        //         if (a.masterPo) {
        //             return -1;
        //         } else {
        //             return 1;
        //         }
        //     })
        //     result.push(l)
        // });
        let result = data.map(el => {
            if (el?.associatedPO.length) {
                return [el, ...el?.associatedPO]
            } else {
                return [el]
            }
        })
        let orderArray = [];
        orderArray.push(orderBy)

         // Sort each sub-array based on ApptEndTime
         const sortedResponse = result.map(subArray => _.orderBy(subArray, ['ApptEndTime'], orderArray));
         // Sort the main array based on the ApptEndTime of the first element in each sub-array
        let finalSortedResponse = _.sortBy(sortedResponse, subArray => subArray[0]?.ApptEndTime);
        // We need to reverse that array after sorting in case of PAST Appointment, 'desc' is passed as argument to format data in case of  PAST Appointment
        if (orderBy === 'desc') {
            finalSortedResponse = _.reverse(finalSortedResponse)
        }
         return finalSortedResponse;
    }

    const populateArr = () => {
        setShowDeleteModal(false)
        if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length) {
            if (upcomingPastApptObj.data.upcomingAppointments && upcomingPastApptObj.data.upcomingAppointments.length) {
                const tempArr = [...upcomingPastApptObj.data.upcomingAppointments].map((ele, i) => {
                    return ele.PoNumber === ele.PORelationID ? ({ ...ele, "isPastAppt": false, masterPo: true }) : ({ ...ele, "isPastAppt": false, masterPo: false })
                })
                setUpcomingApptsList(formatdata([...tempArr], 'asc'));
            } else {
                setUpcomingApptsList([]);
            }
            if (upcomingPastApptObj.data.pastAppointments && upcomingPastApptObj.data.pastAppointments.length) {
                const tempArr = [...upcomingPastApptObj.data.pastAppointments].map((ele, i) => {
                    return ele.PoNumber === ele.PORelationID ? ({ ...ele, "isPastAppt": true, masterPo: true }) : ({ ...ele, "isPastAppt": true, masterPo: false })
                })

                setPastApptsList(formatdata([...tempArr], 'desc'))
            } else {
                setPastApptsList([]);
            }
        } else {
            setListLoading(false)
        }
    }

    const pageChange = (pageNo, limit = PaginationDefaultLimit) => {
        fetchAppointmentsList(pageNo,limit)
    }

    const fetchAppointmentsList = (pageNo, limit) => {
        const id = (_.includes(userType, 'CARRIER') ? userAccountDetails?.IDCarrier : selectedWarehouse.ID);
        const userTypeTemp = (_.includes(userType, 'CARRIER') ? "CARRIER" : userType);
        const { apiParams } = fetchSSApptListCommonFunc(id, userTypeTemp, selectedDate ? selectedDate : null);
        apiParams.limit = limit || 6;
        apiParams.pageNo = pageNo || 1;
        apiParams.AppointmentType = currentApptList === 'Upcoming' ? 'FUTURE' : 'PAST';
        apiParams.IDWarehouse = selectedWarehouse?.ID
        apiParams.SearchParam = searchStr || null
        dispatch({ type: sagaActions.SS_UPCOMING_PAST_APPTS_ACTION, payload: apiParams });
        setListLoading(true)
    }

     /**
     * Handler function to dispatch payload for upload document
     * @param {*} data 
     * @param {*} docName 
     */
     const uploadDocsHandler = async (data, docName, rowData) => {
        dispatch({type: sagaActions.INTIATE_SS_LOADER, payload: true})
        let fd = new FormData();
        if (data && docName) {
            const ApptDocsCreatedBy = cu_id;
            // fd.append('file', data); // Assuming 'file' is the file object
            fd.append('contentType', data.type)
            fd.append('DocName', docName); // Append the document name
            fd.append('AppointmentNumber', rowData.AppointmentNumber)
            fd.append('ApptDocsCreatedBy', ApptDocsCreatedBy);
            fd.append('ApptDocsUpdatedBy', null);
            // fd.append('ID', null);
            fd.append('CRUDTYPE', 'I');
            
        } else {
            console.log('Data or DocName not present')
        }
        dispatch({
            type: sagaActions.UPLOAD_APPT_DOCS,
            payload: {
                CRUDTYPE: 'I',
                uploadData: fd,
                file: data,
                AppointmentNumber: rowData.AppointmentNumber
                
            }
        });
    }
    /**
     * Handler function to dispatch payload to delete document
     * @param {*} data 
     */
    const docDeleteHandler = (data, rowData) => {
        let fd = new FormData();
        fd.append('DocName', data.DocName); // Append the document name
        fd.append('AppointmentNumber', rowData.AppointmentNumber)
        fd.append('CRUDTYPE', 'BD');
        dispatch({
            type: sagaActions.UPLOAD_APPT_DOCS,
            payload: {
                CRUDTYPE: 'BD',
                uploadData: fd,
                DocName: data.DocName
            }
        });

    }

    const renderPastApptTableRows = () => {
        if (userType === 'USER') {
            // const formattedData = groupByDate(pastApptsList)
            const formattedData = pastApptsList;
            return formattedData && formattedData.map((ele, rowIndex) => {
                return (
                    <SSCarrierPO
                        isPast={true}
                        showUpload={false}
                        showDelete={false}
                        key={ele + rowIndex}
                        rowIndex={0}
                        rowData={ele[0]}
                        poList={ele}
                        addPOBtn={false}
                        ssSchedulehandler={(rowData) => goToSchedulerPage(rowData)}
                        actionsButtonsArr={actionsButtonsArr ? actionsButtonsArr : ['Email']}
                        onEmailClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'email' })}
                        onDeleteClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'delete' })}
                        appointmentDocumentsList={appointmentDocumentsList}
                        initateLoading={initateLoading}
                        uploadDocsHandler={(data, docName) => uploadDocsHandler(data, docName, ele[0])}
                        docDeleteHandler={(data) => docDeleteHandler(data, ele[0])}
                        userType={userType}
                    />
                )
            })
        } else if (userType === 'CARRIER') {
            return [...pastApptsList].map((ele, rowIndex) => {
                return (

                    <SSCarrierPO
                        isPast={true}
                        showUpload={false}
                        showDelete={false}
                        key={ele + rowIndex}
                        rowIndex={0}
                        rowData={ele[0]}
                        poList={ele}
                        addPOBtn={false}
                        ssSchedulehandler={(rowData) => goToSchedulerPage(rowData)}
                        actionsButtonsArr={actionsButtonsArr ? actionsButtonsArr : ['Email']}
                        onEmailClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'email' })}
                        onDeleteClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'delete' })}
                        appointmentDocumentsList={appointmentDocumentsList}
                        initateLoading={initateLoading}
                       uploadDocsHandler={(data, docName) => uploadDocsHandler(data, docName, ele[0])}
                        docDeleteHandler={(data) => docDeleteHandler(data, ele[0])} 
                        userType={userType}                       
                    />

                )
            })
        }

    }

    const goToSchedulerPage = (rowData) => {
        if (userType === AppConstant.userPermission.usertypes.carrier) {
            dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: rowData?.PoNumber, ReceivingZipCode: rowData?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : rowData?.IDWarehouse } })
            // dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [...rowData] })
            dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [rowData] })
            navigate('/bookappointments', { state: { type: 'rescheduling' } });
        } else {
            let ifApptExists = _.find(appointmentList, {
                PoNumber: rowData?.PoNumber,
            });
            let foundObject = [];
            if (!_.isEmpty(ifApptExists)) {
                let { PORelationID } = ifApptExists;
                foundObject = _.filter(appointmentList, {
                    PORelationID
                });
            }
            if (_.size(foundObject)) {
                //Needed when coming from myapointments
                dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: _.flatten(formatPOwithPORelationID(foundObject)) })
                // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: foundObject })
            } else {
                dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: rowData?.PoNumber, ReceivingZipCode: rowData?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : rowData?.IDWarehouse } })
                dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [rowData] })
            }
            navigate('/bookappointments', { state: { type: 'rescheduling' } });
        }
    }


    const renderUpcomingApptTableRows = () => {
        if (userType === 'USER') {
            // const formattedData = groupByDate(upcomingApptsList)
            const formattedData = upcomingApptsList;
            return formattedData && formattedData.map((ele, rowIndex) => {
                return (
                    <SSCarrierPO
                        isPast={false}
                        showUpload={true}
                        showDelete={true}
                        key={ele + rowIndex}
                        rowData={ele[0]}
                        poList={ele}
                        rowIndex={0}
                        searchedPOData={ele}
                        ssSchedulehandler={(rowData) => goToSchedulerPage(rowData)}
                        actionsButtonsArr={actionsButtonsArr}
                        onEmailClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'email' })}
                        onDeleteClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'delete' })}
                        appointmentDocumentsList={appointmentDocumentsList}
                        initateLoading={initateLoading}
                       uploadDocsHandler={(data, docName) => uploadDocsHandler(data, docName, ele[0])}
                        docDeleteHandler={(data) => docDeleteHandler(data, ele[0])}                        
                    />
                )
            })
        } else if (userType === 'CARRIER') {
            return upcomingApptsList.map((ele, rowIndex) => {

                return (
                    <SSCarrierPO
                        isPast={false}
                        showUpload={true}
                        showDelete={true}
                        key={ele + rowIndex}
                        rowData={ele[0]}
                        poList={ele}
                        rowIndex={0}
                        searchedPOData={ele}
                        ssSchedulehandler={(rowData) => goToSchedulerPage(rowData)}
                        actionsButtonsArr={actionsButtonsArr}
                        onEmailClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'email' })}
                        onDeleteClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'delete' })}
                        appointmentDocumentsList={appointmentDocumentsList}
                        initateLoading={initateLoading}
                       uploadDocsHandler={(data, docName) => uploadDocsHandler(data, docName, ele[0])}
                        docDeleteHandler={(data) => docDeleteHandler(data, ele[0])}                        
                    />
                )
            })
        }
    }

    const deletePoAppt = () => {

        const newItemPayload = {
            PoNumber: selectedPoObj.PORelationID,
            IDWarehouse: selectedPoObj.WarehouseData.ID,
            ReceivingZipCode: selectedPoObj.ReceivingZipCode,
            ApptStartDate: selectedPoObj.ApptStartDate,
            ApptStartTime: selectedPoObj.ApptStartTime,
            ApptEndDate: selectedPoObj.ApptEndDate,
            ApptEndTime: selectedPoObj.ApptEndTime,
            ApptStatus: selectedPoObj.ApptStatus,
            Door: selectedPoObj.Door,
            ApptCreatedBy: selectedPoObj.ApptCreatedBy,
            IDDock: selectedPoObj.IDDock,
            IDCarrier: selectedPoObj.IDCarrier,
            CRUDTYPE: 'D',
            DeletedPos: '',
            rescheduleReason: rescheduleReason,
            userType: userType
        }
        let fd = new FormData();
        fd.append('DocName', null); // Append the document name
        fd.append('AppointmentNumber', selectedPoObj?.AppointmentNumber)
        fd.append('CRUDTYPE', 'S');
        dispatch({
            type: sagaActions.ADD_APPOINTMENT, payload: {
                deleteApptData: newItemPayload,
                deleteUploadDocsData: fd,
                CRUDTYPE: 'D'
        } })
        setDeletePO(true);
        setListLoading(true);
        setSelectedPoObj({})
        dispatch({
            type: sagaActions.ADD_SELECTED_DATE_FOR_APPT_UPDATE,
            payload: selectedDateForApptUpdates,
        });
    }

    const getPastAppointmentsList = () => {
        if (pastApptsList.length) {
            return (
                <div className={userType === 'CARRIER' ? 'wrapperMyApptsCarrierSS' : 'wrapperMyApptsCarrierSS'}>
                    {renderPastApptTableRows()}
                </div>
            )
        } else {
            return showNoDataAvailable()
        }
    }

    const getUpcomingAppointmentsList = () => {
        if (upcomingApptsList.length) {
            return (
                <div className={userType === 'CARRIER' ? 'wrapperMyApptsCarrierSS' : 'wrapperMyApptsCarrierSS'}>
                    {renderUpcomingApptTableRows()}
                </div>
            )
        } else {
            return showNoDataAvailable()
        }
    }

    const showNoDataAvailable = () => {
        return (
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src={NoDataImg} alt='Opps....' />
                <br />
                <h6>{AppConstant.poManagement.emptyTable.text1}</h6>
            </div>
        )
    }

    const formFieldListViewAppointment = [
        {

            type: "string",
            size: "col-sm-12",
            position: 1,
            name: "Po Number",
            value: selectedPoObj.PoNumber,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 2,
            name: "Dock",
            value: selectedPoObj.DockName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 3,
            name: "Commodity",
            value: selectedPoObj.Commodity,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 4,
            name: "Buyer",
            value: selectedPoObj.BuyerName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 5,
            name: "Vendor",
            value: selectedPoObj.VendorName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 6,
            name: "Quantity",
            value: selectedPoObj.Quantity,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 7,
            name: "Cases",
            value: selectedPoObj.ItemsCases,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 8,
            name: "Pallets",
            value: selectedPoObj.Pallets,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 9,
            name: "Weight",
            value: selectedPoObj.Weight,
            formValidation: {
                required: true,
            },
            disabledField: true
        }, {

            type: "string",
            size: "col-sm-12",
            position: 10,
            name: "Warehouse",
            value: selectedPoObj.WarehouseName,
            formValidation: {
                required: true,
            },
            disabledField: true
        }, {

            type: "string",
            size: "col-sm-12",
            position: 10,
            name: "ZipCode",
            value: selectedPoObj.ReceivingZipCode,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 11,
            name: "Appointment Start",
            value: moment(selectedPoObj.ApptStartTime).format('DD/MM/YYYY HH:mm A'),
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 13,
            name: "Due Date",
            value: moment(selectedPoObj.DueDate).format('DD/MM/YYYY HH:mm A'),
            formValidation: {
                required: true,
            },
            disabledField: true
        }
    ]

    const openSendEmail = () => {
        return <SendEmailSS closeSendMailpopup={closeSendMailpopup} formFieldList={formFieldListViewAppointment} poDetails={selectedPoObj}></SendEmailSS>
    }

    const closeSendMailpopup = () => {
        setShowSendEmailModal(false);
        setSelectedPoObj({});
    }

    const showSendEmailPopup = () => {
        const popupObj = {
            id: 'createAppointment',
            modalLabel: 'sendEmail',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: 'Send Email',
            bodyText: openSendEmail(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeSendMailpopup} themeSelected={themeSelected} showModal={showSendMailModal} />
    }

    function createWarningText(mPO, associatedPO, arr) {
        return <div className='boldText'>
            <span> Are you sure, you want to Cancel this Appointment for PO/SO: {mPO} </span>
            {arr.length > 1 ? <><span> and its associated PO/Sos :  </span>
                <span className='textRed'>{associatedPO} ?</span></> : <span>?</span>}
        </div>; // This will render HTML
    }

    /**
     * Function to render delete po popup body
     * @param {*} mPO 
     * @param {*} associatedPO 
     * @param {*} arr 
     * @returns 
     */
    function deletePopupBody(mPO, associatedPO, arr){
        return(
            <>
            {createWarningText(mPO, associatedPO, arr)}
            <br/>
            {<SelectRescheuleReason rescheduleReason={rescheduleReason} setRescheduleReason={setRescheduleReason} onSubmit={()=>{setShowRescheduleReason(false)}} type='Cancel' errorMsg={errorMsg}/>}
            </>
        )
    }

    const showDeletePopup = () => {
        if (_.size(upcomingApptsList)) {
            const filteredArray = _.flatten(_.filter(upcomingApptsList, subArray => _.some(subArray, { PORelationID: selectedPoObj?.PORelationID })));
            if (_.size(filteredArray)) {
                const masterPO = filteredArray[0]
                const fieldDataAssociated = formParticularFieldValueForAssociatedPO(filteredArray)
                const popupObj = {
                    id: 'deleteAppointment',
                    modalLabel: 'deleteEmail',
                    showClose: true,
                    showfooter: true,
                    showHeader: true,
                    sendDriverNotification: false,
                    modalSize: 'md',
                    title: AppConstant.commonStrings.cancelApptTitle,
                    bodyText: deletePopupBody(masterPO?.PoNumber, fieldDataAssociated?.po_number, filteredArray),
                    subText: "",
                    keyboard: false,
                    backdrop: "static",
                    footerBtn: {
                        btn1: {
                            show: true,
                            text: AppConstant.commonStrings.yes
                        },
                        btn2: {
                            show: true,
                            text: AppConstant.commonStrings.no
                        }
                    },
                }

                return <SSPopup {...popupObj} popupBtnHandler={(text) => deletePoPopupHandler(text)} closepopup={closeDeletePopopup} themeSelected={themeSelected} showModal={showDeleteModal} />
            }
        }
    }

    const deletePoPopupHandler = (text) => {

        if (text === AppConstant.commonStrings.yes) {
            if (!rescheduleReason) {
                setErrorMsg(AppConstant.help.required)
            }
            else{
                deletePoAppt()
                setRescheduleReason(null)
                setErrorMsg(null)
            }
        } else {
            setShowDeleteModal(false)
            setSelectedPoObj({})
            setRescheduleReason(null)
            setErrorMsg(null)
        }
    }

    const closeDeletePopopup = () => {
        setShowDeleteModal(false);
        setRescheduleReason(null)
        setErrorMsg(null)
    }

    useEffect(() => {
        if (searchStr?.length > 3) {
            fetchAppointmentsList()
            
        } else if (searchStr === '') {
            fetchAppointmentsList()
        }
    }, [searchStr])

    const showCurrentApptView = () => {
        switch (currentApptList) {
            case 'All':
                return (
                    <div>
                        <div className='fw-bold titleNavMyAppts'>
                            {/* {AppConstant.smartScheduler.myAppointments.upcomingAppointmentsTitle} */}
                        </div>
                        {upcomingPastApptObj && !isListLoading && getUpcomingAppointmentsList()}
                        <div className='fw-bold titleNavMyAppts'>
                            {/* {AppConstant.smartScheduler.myAppointments.pastAppointmentsTitle} */}
                        </div>
                        {upcomingPastApptObj && !isListLoading && getPastAppointmentsList()}
                    </div>
                );
            case 'Upcoming':
                return (
                    <div>
                        {/* <div className=' my-2'>{AppConstant.smartScheduler.myAppointments.upcomingAppointmentsTitle}</div> */}
                        {upcomingPastApptObj && !isListLoading && getUpcomingAppointmentsList()}
                    </div>
                );
            case 'Past':
                return (
                    <div>
                        {/* <div className='py-2'>{AppConstant.smartScheduler.myAppointments.pastAppointmentsTitle}</div> */}
                        {upcomingPastApptObj && !isListLoading && getPastAppointmentsList()}
                    </div>
                );
            default:
                return (
                    showNoDataAvailable()
                )
        }
    }

    // useEffect(() => {
    //     onFilterItemSelected()
    // }, [currentFilterSelected])

    useEffect(() => {
        if (currentApptList) {
            // userType === 'CARRIER' ? setCurrentFilterSelected({ "WarehouseData": { 'Name': 'ALL' } }) : setCurrentFilterSelected({ "CarrierName": 'ALL' })
            fetchAppointmentsList()
        }
    }, [currentApptList])

    // const onFilterItemSelected = () => {
    //     if (userType === 'CARRIER') {
    //         //Carrier Start
    //         if (currentApptList === 'All') {
    //             if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length > 0) {
    //                 if (upcomingPastApptObj.data.upcomingAppointments && upcomingPastApptObj.data.upcomingAppointments.length > 0 && currentFilterSelected) {
    //                     const upcomingTempArr = upcomingPastApptObj.data.upcomingAppointments;
    //                     const filterTempArr = upcomingTempArr.filter((ele, index) => {
    //                         if (currentFilterSelected && (ele.WarehouseData.Name === currentFilterSelected.WarehouseData.Name)) {
    //                             return ele
    //                         }
    //                     })
    //                     if (currentFilterSelected) {
    //                         setUpcomingApptsList(currentFilterSelected.WarehouseData.Name === 'ALL' ? upcomingTempArr : [...filterTempArr])
    //                     } else {
    //                         setPastApptsList(upcomingTempArr)
    //                     }
    //                 }
    //                 if (upcomingPastApptObj.data.pastAppointments && upcomingPastApptObj.data.pastAppointments.length > 0 && currentFilterSelected && currentFilterSelected.WarehouseData) {
    //                     const pastTempArr = upcomingPastApptObj.data.pastAppointments;
    //                     const filterTempArr = pastTempArr.filter((ele, index) => {
    //                         if (currentFilterSelected && (ele.WarehouseData.Name === currentFilterSelected.WarehouseData.Name)) {
    //                             return ele
    //                         }
    //                     })
    //                     if (currentFilterSelected) {
    //                         setPastApptsList(currentFilterSelected.WarehouseData.Name === 'ALL' ? [...pastTempArr] : [...filterTempArr])
    //                     } else {
    //                         setPastApptsList(pastTempArr)
    //                     }
    //                 }
    //             }
    //         } else if (currentApptList === 'Upcoming') {
    //             if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length > 0) {
    //                 if (upcomingPastApptObj.data.upcomingAppointments && upcomingPastApptObj.data.upcomingAppointments.length > 0) {
    //                     const upcomingTempArr = upcomingPastApptObj.data.upcomingAppointments;
    //                     const filterTempArr = upcomingTempArr.filter((ele, index) => {
    //                         if (currentFilterSelected && (ele.WarehouseData.Name === currentFilterSelected.WarehouseData.Name)) {
    //                             return ele
    //                         }
    //                     })
    //                     if (currentFilterSelected) {
    //                         setUpcomingApptsList(currentFilterSelected.WarehouseData.Name === 'ALL' ? formatdata([...upcomingTempArr], 'asc') : formatdata([...filterTempArr], 'asc'))
    //                     } else {
    //                         setPastApptsList(formatdata([...upcomingTempArr], 'desc'))
    //                     }
    //                 }
    //             }
    //         } else if (currentApptList === 'Past') {
    //             if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length > 0) {
    //                 if (upcomingPastApptObj.data.pastAppointments && upcomingPastApptObj.data.pastAppointments.length > 0) {
    //                     const pastTempArr = upcomingPastApptObj.data.pastAppointments;
    //                     const filterTempArr = pastTempArr.filter((ele, index) => {
    //                         if (currentFilterSelected && (ele.WarehouseData.Name === currentFilterSelected.WarehouseData.Name)) {
    //                             return ele
    //                         }
    //                     })
    //                     if (currentFilterSelected) {
    //                         setPastApptsList(currentFilterSelected.WarehouseData.Name === 'ALL' ? formatdata([...pastTempArr], 'desc') : formatdata([...filterTempArr],'desc'))
    //                     } else {
    //                         setPastApptsList(formatdata(pastTempArr, 'desc'))
    //                     }
    //                 }
    //             }
    //         }
    //         //Carrier End
    //     } else {
    //         //Warehouse Start
    //         if (currentApptList === 'All') {
    //             if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length > 0) {
    //                 if (upcomingPastApptObj.data.upcomingAppointments && upcomingPastApptObj.data.upcomingAppointments.length > 0) {
    //                     const upcomingTempArr = upcomingPastApptObj.data.upcomingAppointments;
    //                     const filterTempArr = upcomingTempArr.filter((ele, index) => {
    //                         if (ele.CarrierName === currentFilterSelected.CarrierName) {
    //                             return ele
    //                         }
    //                     })
    //                     setUpcomingApptsList(currentFilterSelected.CarrierName === 'ALL' ? formatdata(upcomingTempArr, 'asc') : formatdata([...filterTempArr], 'asc'))
    //                 }

    //                 if (upcomingPastApptObj.data.pastAppointments && upcomingPastApptObj.data.pastAppointments.length > 0) {
    //                     const pastTempArr = upcomingPastApptObj.data.pastAppointments;
    //                     const filterTempArr = pastTempArr.filter((ele, index) => {
    //                         if (ele.CarrierName === currentFilterSelected.CarrierName) {
    //                             return ele
    //                         }
    //                     })
    //                     setPastApptsList(currentFilterSelected.CarrierName === 'ALL' ? formatdata([...pastTempArr], 'desc') : formatdata([...filterTempArr], 'desc'))
    //                 }
    //             }
    //         } else if (currentApptList === 'Upcoming') {
    //             if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length > 0) {
    //                 if (upcomingPastApptObj.data.upcomingAppointments && upcomingPastApptObj.data.upcomingAppointments.length > 0) {
    //                     const upcomingTempArr = upcomingPastApptObj.data.upcomingAppointments;
    //                     const filterTempArr = upcomingTempArr.filter((ele, index) => {
    //                         if (ele.CarrierName === currentFilterSelected.CarrierName) {
    //                             return ele.PoNumber === ele.PORelationID ? ({ ...ele, "isPastAppt": false, masterPo: true }) : ({ ...ele, "isPastAppt": false, masterPo: false })
    //                         }
    //                     })
    //                     setUpcomingApptsList(currentFilterSelected.CarrierName === 'ALL' ? formatdata(upcomingTempArr, 'asc') : formatdata([...filterTempArr], 'asc'))
    //                 }
    //             }
    //         } else if (currentApptList === 'Past') {
    //             if (upcomingPastApptObj && Object.keys(upcomingPastApptObj).length > 0) {
    //                 if (upcomingPastApptObj.data.pastAppointments && upcomingPastApptObj.data.pastAppointments.length > 0) {
    //                     const pastTempArr = upcomingPastApptObj.data.pastAppointments;
    //                     const filterTempArr = pastTempArr.filter((ele, index) => {
    //                         if (ele.CarrierName === currentFilterSelected.CarrierName) {
    //                             return ele.PoNumber === ele.PORelationID ? ({ ...ele, "isPastAppt": true, masterPo: true }) : ({ ...ele, "isPastAppt": true, masterPo: false })
    //                         }
    //                     })
    //                     setPastApptsList(currentFilterSelected.CarrierName === 'ALL' ? formatdata(pastTempArr, 'desc') : formatdata([...filterTempArr], 'desc'))
    //                 }
    //             }
    //         }
    //         //Warehouse End
    //     }
    // }
    const clearDateHandler = () => {
        setSelectedDate(null)
    }

    return (
        <ScheduleProWrapper showAppointments={showAppointments} showNavBar={showNavBar}>
            <div className="my-appintmentsSS-wrapper" data-testid='my-appointments'>
                <div className="d-flex flex-column py-3 px-2 justify-content-center w-100">
                    <div className="flex-grow-1 d-flex justify-content-between w-100">
                        <h4>{AppConstant.smartScheduler.myAppointments.myAppointmentsTitle}</h4>
                    </div>
                    {/* Selctor Buttons section */}
                    <div className=" pt-2 mt-2 d-flex justify-content-between gap-3 my-appt-actions">
                        <div className=" d-flex justify-content-start my-appt-actions-left">
                            <div
                                className="d-flex justify-content-start"
                                style={{ borderRadius: "10px" }}
                            >
                                <button
                                    onClick={() => {
                                        setShowCalendar(false)
                                        setMinDate(new Date())
                                        setMaxDate(null)
                                        setCurrentApptList("Upcoming")
                                    }}
                                    className={
                                        currentApptList === "Upcoming"
                                            ? "selected-button"
                                            : "unselected-button"
                                    }
                                >
                                    Upcoming
                                </button>
                                <button
                                    onClick={() => {
                                        setShowCalendar(false)
                                        setMinDate(null)
                                        setMaxDate(new Date())
                                        setCurrentApptList('Past')
                                    }}
                                    className={
                                        currentApptList === "Past"
                                            ? "selected-button"
                                            : "unselected-button"
                                    }
                                >
                                    Past
                                </button>
                                {/* <button
                                onClick={() => handleSelectedAppointments("tomorrow")}
                                className={
                                    selectedAppointments === "tomorrow"
                                        ? "selected-button"
                                        : "unselected-button"
                                }
                            >
                                All
                            </button> */}
                            </div>
                            <div className="pos-rel">
                                <button
                                    className={`date-picker-btn h-100 ${isListLoading && 'disabled'}`}
                                    onClick={() => setShowCalendar(!showCalendar)}
                                    disabled={isListLoading}
                                    data-testid="selectDate-btn"
                                >
                                    <span className="btn-title">{selectedDate ?
                                        formatDate_MM_DD_YYYY(selectedDate) : 'Select Date'}</span>
                                    <i
                                        className="fa-regular fa-calendar"
                                        style={{ marginInline: "9px" }}
                                    ></i>

                                </button>
                                {
                                    showCalendar && <div className="myappt-ss-datePicker"  data-testid="date-picker-wrapper">
                                        <DatePickerSS minDate={new Date(`${minDate?.getMonth() + 1},${minDate?.getDate()},${minDate?.getFullYear()}`)} maxDate={maxDate} setSelectedDate={setSelectedDate} selectedDate={selectedDate} setLoading={setListLoading} closeCalendar={() => { setShowCalendar(!showCalendar) }} />
                                    </div>
                                }
                            </div>
                            {selectedDate && <div className="ms-2 d-flex jsutify-content-center align-items-center cursor-pointer f-16" onClick={clearDateHandler}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>}
                        </div>
                        <div className="flex-grow-1 d-flex flex-row justify-content-end align-items-center gap-3 my-appt-actions-right">

                            <WarehouseSearchSS
                                placeholder={'Search...'}
                                inputValue={searchStr}
                                cancelSearch={() => setSearchStr('')}
                                onChangeHandler={(text) => setSearchStr(text)} />

                        </div>
                    </div>
                    {/* Cards section */}
                    <div
                        className="w-100 mt-1 myappointment-ss-grid-wrapper">
                        {
                            !isListLoading ?
                                <div className="myappointment-ss-grid  ">
                                    <div className='fw-bold fs-6'>
                                        {/* {userType !== 'CARRIER' && addHeader()} */}

                                        {showCurrentApptView()}
                                        
                                    </div>
                                </div>
                                :
                                <div className='w-100'>{displayLoader(themeSelected, 50, 11)}</div>
                        }
                    </div>
                    {currentApptList === 'Upcoming' &&  upcomingPastApptObj && upcomingPastApptObj?.data?.upcomingAppointments.length || currentApptList === 'Past' &&  upcomingPastApptObj && upcomingPastApptObj?.data?.pastAppointments.length  ?
                                        <CustomPaginationSS
                                        pageChange={(pageNo,limit)=>{pageChange(pageNo,limit)}}
                                        pageNo={upcomingPastApptObj.pageNo}
                                        pageSize={upcomingPastApptObj.limit}
                                        totalElements={upcomingPastApptObj.totalElements}
                                        totalPages={Math.ceil(upcomingPastApptObj.totalElements/upcomingPastApptObj.limit)}
                                        last={upcomingPastApptObj.last}
                                        first={upcomingPastApptObj.first}
                                        data={upcomingPastApptObj.data}
                                        isLoading={isListLoading}
                                        paginationLimitArray={[6,12,18,24]}
                                            />:""
                                        }
                </div>
            </div>
            {showSendEmailPopup()}
            {showDeletePopup()}
        </ScheduleProWrapper>
    );
};

export default MyAppointmentsSS;
