import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { fetchDockListCommonFunc } from '../Common/FetchDockList'
import { toast } from 'react-toastify';
import { getCurrentDate } from '../../../../../../../../Utils/DateUtils';
import { showAlert, initiateSearch, changeFormatWithoutTz } from '../../../../../../../Assests/Utility';
import moment from "moment";
import './DockList.css';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import EditIcon from '../../../../../../../Assests/Images/edit-icon-new.png'
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';
import CustomTooltip from '../../../../../../Common/Tooltip/CustomTooltip';


const DockListSS = (props) => {
    const dispatch = useDispatch();
    const { themeSelected, allowPermissionE = true, editDockAction, searchStr, whObj } = props;
    const navigate = useNavigate();
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { whMetaDocksList, operationObj, arrangeOrder, arrangeKey, preCheckList, dockConflictObj, isLoading } = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [metaDockArr, setMetaDockArr] = useState([]);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [deleteClick, setOnDeleteClick] = useState(false);
    const [showPreCheckModal, setShowPreCheckModal] = useState(false);


    useEffect(() => {
        if (preCheckList && preCheckList.length > 0) {
            setCurrentItemIndex("NA")
            setCurrentDeleteIndex('NA')
        }
    }, [preCheckList])

    const dockTimeslotsTitleDD = [
        { id: 'Name', name: AppConstant.poManagement.tableHeader.dock },
        { id: 'PPH', name: "Capacity/Hr" },
        { id: 'StartTime', name: 'Start Time' },
        { id: 'EndTime', name: 'End Time' },
        { id: 'BlockHours', name: 'Block Time (In min)' },
        { id: 'ApptLifeTime', name: 'Appt Life Time (In hour)' },
        { id: 'ApptLeadTime', name: 'Appt Lead Time (In hour)' },
        { id: 'ApptFrequency', name: 'Appt Frequency (In min)' },
        { id: 'CreatedDate', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'status', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: 'operational_days', name: AppConstant.dock.operational_daysTitle },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    //Used to reset the dock conflict details object
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_DOCK_CONFLICT_DETAILS });
    }, [])


    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Update status':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.docks.updateDockStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.updateDockStatusFailure);
                    }
                    break;
                case 'Delete Dock':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.docks.deleteDockSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.deleteDockFailure);
                    }
                    break;
                case 'Fetch Dock':
                    setListLoading(false)
                    setMetaDockArr([])
                    if (operationObj.status) {
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.updateDockStatusFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])


    const handleApiResponse = (result, message) => {
        setListLoading(false);
        setCurrentItemIndex('NA');
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchDockList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });

    }

    useEffect(() => {
        setListLoading(true);
        setMetaDockArr([])
        dispatch({ type: sagaActions.RESET_WAREHOUSE_META_DOCK_LIST_ACTION })
        setTimeout(() => {
            fetchDockList();
        }, [1000])
    }, [])

    const fetchDockList = () => {
        const { apiParams, operationObj } = fetchDockListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    useEffect(() => {
        if (whMetaDocksList && whMetaDocksList.length) {
            setListLoading(false);
            setCurrentItemIndex('NA');
            setCurrentDeleteIndex('NA')
            setMetaDockArr(whMetaDocksList);
        }
    }, [whMetaDocksList])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(whMetaDocksList, searchStr, 'GroupName');
            setMetaDockArr(searchedArr);
        } else if (searchStr === '') {
            setMetaDockArr(whMetaDocksList)
        }
    }, [searchStr])

    const disableDoorAction = (data, index) => {
        setCurrentItemIndex(index)

        const apiParams = {
            "DockName": data.Name,
            "PPH": parseInt(data.PPH),
            "DockStatus": !data.DockStatus,
            "ID": data.ID,
            "CRUDTYPE": "U",
            "IDWarehouse": data.IDWarehouse,
            "StartTime": data.StartTime,
            "EndTime": data.EndTime,
            "CreatedOn": data.CreatedDate,
            "CreatedBy": cu_id,
            "UpdatedBy": cu_id,
            "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "operational_days":{
                sunday:data.sunday,
                monday:data.monday,
                tuesday:data.tuesday,
                wednesday:data.wednesday,
                thursday:data.thursday,
                friday:data.friday,
                saturday:data.saturday
            },
            "BlockHours":data?.BlockHours,
            "CapacityModeling": data?.CapacityModeling,
            "ApptFrequency": data?.ApptFrequency
        }
        const operationObj = {
            type: 'Update status',
            status: ''
        }
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj });
    }

    /**
     * function to get the dock conflict details
     * @param {*} data 
     */
    const dispatchDockConflict = (data) => {
        const params = {
            "IDWarehouse": data.IDWarehouse,
            "ID": data.ID
        }
        dispatch({ type: sagaActions.GET_DOCK_CONFLICT_DETAILS, payload: params });
    }


    /**
     * validating the dock conflict details object to show which pop up
     */
    useEffect(() => {
        if (deleteClick) {
            if (!isLoading && dockConflictObj) {
                const allEmpty = Object.values(dockConflictObj).every(array => array.length === 0);
                if (allEmpty) {
                    setShowDeleteModel(true)
                } else {
                    setShowPreCheckModal(true)
                }
                setOnDeleteClick(false)
            }
        }
    }, [dockConflictObj])

    /**
     * Helper function
     * @param {*} data 
     * @param {*} index 
     */
    const deleteHandler = (data, index) => {
        setCurrentDeleteIndex(index)
        setOnDeleteClick(true)
        setSelectedObj(data, index)
        setSelectedIndex(index)
        dispatchDockConflict(data)
    }

    /**
     * Function is used to hadle onclick popup button 
     */
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            isLoading(false)
            setSelectedObj(null)
            setSelectedIndex(null)
            setCurrentDeleteIndex('NA')
        }
        else {
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                "DockName": selectedObj.Name,
                "PPH": parseInt(selectedObj.PPH),
                "DockStatus": true,
                "ID": selectedObj.ID,
                "CRUDTYPE": "D",
                "IDWarehouse": selectedObj.IDWarehouse,
                "StartTime": selectedObj.StartTime,
                "EndTime": selectedObj.EndTime,
                "CreatedOn": selectedObj.CreatedDate,
                "CreatedBy": cu_id,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
            const operationObj = {
                type: 'Delete Dock',
                status: ''
            }
            dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj });
        }
        setShowDeleteModel(false)

    }
    /**
     * Function used to create pop up dialog box
     * @returns 
     */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.docks.deleteDockTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.smartScheduler.docks.deleteDockTitle}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false); setCurrentDeleteIndex('NA') }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-around align-items-center'>
                    <img src={EditIcon} alt='edit' className='editIconPO-ss' data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editDockAction(groupObj, index)} />
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                    <CustomButtonSS
                        isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                        title={`${!groupObj.DockStatus ? AppConstant.commonStrings.enable : AppConstant.commonStrings.disable}`}
                        className={`${!groupObj.DockStatus ? ' btnEnable-ss' : 'btnDisable-ss'} `}
                        onClick={() => disableDoorAction(groupObj, index)}
                    />

                </span>
            </div>
        )
    }

    /**
     * Function to render operational days
     * @param {*} data 
     * @returns 
     */
    const getOperationalDays = (data) => {
        let operationalDays = {
            sunday: data.sunday,
            monday: data.monday,
            tuesday: data.tuesday,
            wednesday: data.wednesday,
            thursday: data.thursday,
            friday: data.friday,
            saturday: data.saturday
        }
        return (
            <>
                <div className='d-flex justify-content-center gap-1 f-wrap'
                    data-testid={AppConstant.dataTestId.operational_days}
                    data-tip={AppConstant.dock.dockToolTip.operational_daysTooltip}
                    data-for={AppConstant.dataTestId.operational_days}
                >
                    {Object.keys(operationalDays)?.map((el, index) => {
                        return (
                            <span key={index} className={operationalDays[el] ? 'color-green' : 'color-red'}>{el[0].toUpperCase()}</span>
                        )
                    })}
                </div>
                <CustomTooltip
                    id={AppConstant.dataTestId.operational_days}
                    position="top"
                    type="dark"
                    multiline={true}
                />
            </>
        )
    }

    const renderCapacity=(data)=>{
        switch (data.CapacityModeling) {
            case "TPH":
                return `${data.CapacityModeling}-${data.TPH}`
                break;
            case 'Productivity':
                return `${data.CapacityModeling} PPH-${data.PPH} CPH-${data.CPH}`
                break;
            default:
                return "-"
                break;
        }
    }


    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: index,
                0: el.Name || '-',
                1: renderCapacity(el) || '-',
                2: changeFormatWithoutTz(el.StartTime, AppConstant.commonStrings.HH_mm_dateFormat) || '-',
                3: changeFormatWithoutTz(el.EndTime, AppConstant.commonStrings.HH_mm_dateFormat) || '-' || '-',
                4: el.BlockHours || '-',
                5: el.ApptLifeTime || '-',
                6: el.ApptLeadTime || '-',
                7: el.ApptFrequency || '-',
                8: `${moment(el.CreatedDate).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedDate).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                9: el.DockStatus ? "Enabled" : 'Disabled',
                10: getOperationalDays(el),
                11: actionsButton(el, index),
            }
        })
    }

    /**
     * Dock conflict Precheck pop up 
     * @returns 
     */
    const showPreCheck = () => {
        const popupObj = {
            id: 'adminUpdateOperationPreCheck',
            modalLabel: 'adminUpdateOperationPreCheck',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.docks.deleteDockTitle,
            bodyText: AppConstant.smartScheduler.docks.preCheckBockerMsg,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.viewDetailsStr
                },
                btn2: {
                    show: false,
                    text: AppConstant.commonStrings.close
                },

            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupPreCheckBtnHandler(text) }} closepopup={() => { closePreCheckpopup() }} themeSelected={themeSelected} showModal={showPreCheckModal} />
    }

    /**
     * function used to hit make API call
     * @param {*} text 
     */
    const popupPreCheckBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.viewDetailsStr) {
            //goto the new dock conflict component
            const data = {
                "IDWarehouse": selectedObj.IDWarehouse,
                "ID": selectedObj.ID
            }
            navigate('/dockconflict', { state: { dockObj: data } })
        }
        else {
            setShowPreCheckModal(false)
        }
    }

    const closePreCheckpopup = () => {
        setShowPreCheckModal(false)
        setCurrentDeleteIndex('NA')

    }

    return (
        <>
            <DrilldownTableSS
                themeSelected={themeSelected}
                bodyData={metaDockArr.length && formatListDataDrillDown(metaDockArr)}
                titleData={dockTimeslotsTitleDD}
                showCollapse={false}
                showInput={false}
                showSort={true}
                arrangeOrder={arrangeOrder}
                arrangeKey={arrangeKey}
                drillDownReport={metaDockArr}
                initiateDrillDownReport={isListLoading}
                loaderArray={12}
                sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
                subRoot={{ pathName: 'whMetaDocksArr' }}
                showUTCTime={false}
            />
            {showDeleteConfirmation()}
            {showPreCheck()}
        </>
    )
}

export default DockListSS;