import React, { memo, useEffect, useState } from 'react';
import { AppConstant } from '../../../../Assests/AppConstant';
import { ReactComponent as DownloadIconLight } from '../../../../Assests/Svg/download-icon-light.svg';
import { ReactComponent as DownloadIconDark } from '../../../../Assests/Svg/download-icon-dark.svg';
import './DownloadSectionSS.css';
import SSPopup from '../../../Common/Popup/SSPopup/SSPopup';
import { CustomDynamicFormSS } from '../../../Common/CustomForm/CustomDynamicFormSS';
import { showAlert } from '../../../../Assests/Utility';
import { toast } from 'react-toastify';
import Popup from '../../../Common/Popup/Popup';
import { CustomDynamicForm } from '../../../Common/CustomForm/CustomDynamicForm';
import moment from 'moment';
import _ from 'lodash-es';


const DownloadSectionBECustomFeilds = (props) => {
    const { downloadData, themeSelected = "light-mode", isDisabled, isSchedulePro = false, isLoading, columnList, downloadReport, showEmail = false, showDateRange = false, monthLimit = 6, showApptStatus = false } = props;

    const getApptStatusList = () => {
        const filterDataArr = [
            {
                name: 'All',
                id: 1
            },
            {
                name: 'Completed',
                id: 2
            },
            {
                name: 'Scheduled',
                id: 3
            },
            {
                name: 'Rescheduled',
                id: 4
            },
            {
                name: 'Cancelled',
                id: 5
            }

        ]

        const apptList = filterDataArr && filterDataArr.length && filterDataArr.map((ele, index) => {
            if (ele) {
                return (
                    <option key={ele.name} value={ele.name}>
                        {ele.name}
                    </option>
                );
            } else {
                return null;
            }
        })
        return _.compact(apptList);
    }
    //Initial Download Options Form JSON
    const formFieldList = [
        {
            name: "downloadType",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Select File Type:',
            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "Pdf",
                value: "pdf"
            },
            {
                label: "Excel",
                value: "excel",
            }]

        },
        ...(showApptStatus ? [{
            name: "apptStatus",
            type: "select",
            size: "col-sm-12",
            position: 0,
            title: AppConstant.commonStrings.appointmentStatus,
            formValidation: {
                required: true,
            },
            getSelectData: getApptStatusList,
        }] : []),
        ...(showDateRange ? [{
            name: "startDate",
            type: "date",
            size: "col-sm-6",
            position: 0,
            title: 'Start Date',
            formValidation: {
                required: showDateRange,
            },
        }] : []),
        ...(showDateRange ? [{
            name: "endDate",
            type: "date",
            size: "col-sm-6",
            position: 0,
            title: 'End Date',
            formValidation: {
                required: showDateRange,
            },
        }] : []),
        ...(showEmail ? [{
            name: "email",
            type: "email",
            size: "col-sm-12",
            position: 0,
            title: 'Email',
            formValidation: {
                required: showEmail,
                validateEmail: showEmail,
            },

        }] : []),
        {
            name: "columnType",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Select Columns:',
            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "All",
                value: AppConstant.commonStrings.allStr
            },
            {
                label: "Specific Columns",
                value: "custom",
            }]

        },

    ]
    const [showModal, setShowModal] = useState(false);
    const [editData, setEditData] = useState({ columnType: AppConstant.commonStrings.allStr });
    const [resetFormData, setResetFormData] = useState(0);
    const [particularFieldValue, setParticularFieldValue] = useState({});
    const [columnHidden, setColumnHidden] = useState(true)
    const [formColumnList, setFormColumnList] = useState(formFieldList)

    useEffect(() => {
        genrateFormColumns(false)
        setEditData({ columnType: AppConstant.commonStrings.allStr, email: props?.emailValue })
    }, [])


    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
        className: isSchedulePro ? "" : 'download-seconday-btn'
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.downloadSection.downloadTitleStr,
        className: isSchedulePro ? "" : 'btn btn-approve'
    };

    //Genrate Columns checkbox form
    const genrateFormColumns = (displayStatus) => {
        let col = [...formFieldList]
        columnList.length && columnList.forEach((el) => {
            let obj = {
                name: el.value,
                type: "checkbox",
                size: "col-sm-4 column-select-checkbox",
                position: 1,
                placeholder: '',
                title: el.name,
                formValidation: {
                    required: false,
                },
                hideElement: {
                    name: el.value,
                    isHidden: displayStatus,
                },
            }
            col.push(obj)
        })
        setFormColumnList(col)
    }

    //Genrate payload and API Call
    const getSummitedFormData = (data) => {
        let payload = downloadData()
        let df = []

        if (showDateRange) {
            const start = moment(data.startDate);
            const end = moment(data.endDate);
            start.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            end.utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
            let MonthsFromStart = start.clone().add(monthLimit, 'months');
            let formattedMonthsFromStart = MonthsFromStart.format('YYYY-MM-DD');
            if (start > end) {
                showAlert(toast.TYPE.ERROR, `Start Date must be earlier than End Date.`);
                return
            }
            if (end > moment(formattedMonthsFromStart)) {
                showAlert(toast.TYPE.ERROR, `The date range must be within ${monthLimit} months.`);
                return
            }

            payload.options.startDate = start.toISOString()
            payload.options.endDate = end.toISOString()

        }
        if (showEmail) {
            payload.userDetails.email = data.email
        }
        if(showApptStatus){
            payload.options.apptStatus = data.apptStatus
        }
        if (data.columnType === AppConstant.commonStrings.allStr) {
            if (columnList.length > 15 && payload.downloadType === 'pdf') {
                df = columnList.slice(0, 15)
            } else {
                df = columnList
            }

        }
        else {
            columnList.forEach((el) => {
                if (data[el.value]) {
                    df.push(el)
                }
            })
        }
        payload.columnType = data.columnType
        payload.downloadType = data.downloadType
        payload.downloadFilters = df;
        if (payload.columnType === "custom" && payload.downloadFilters.length < 3) {
            showAlert(toast.TYPE.ERROR, "Select atleast three column or Select All !");
        }
        else {
            if (payload.downloadType === 'pdf' && payload.downloadFilters.length > 15) {
                showAlert(toast.TYPE.ERROR, "Max 15 columns allowed for PDF Format !");
            }
            else {
                downloadReport(payload)
                closeFilterPopup()
            }
        }
    }
    const getFormFieldData = (data, name) => {
        if (name === 'columnType') {
            if (data && data === AppConstant.commonStrings.allStr) {
                setColumnHidden(true)
                genrateFormColumns(false)
            }
            else {
                setColumnHidden(false)
                genrateFormColumns(true)
            }

        }

    };

    //Genrate Filters Form
    const CreateOptionsForm = () => {
        return (
            <>
                <div className='downlaod-popup'>
                    <CustomDynamicFormSS
                        formFieldList={formColumnList}
                        sort={true}
                        cancelBtn={cancelbtndata}
                        submitbtn={submitbtnData}
                        themeSelected={themeSelected}
                        getSummitedFormData={getSummitedFormData}
                        getFormFieldData={getFormFieldData}
                        editFormData={editData}
                        loading={false}
                        resetFormData={resetFormData}
                        setParticularFieldValue={particularFieldValue}
                    />
                    <div className='f-10 color-red'>Note: PDF format support 15 columns max.</div>
                </div>
            </>
        );
    }

    //Close Filter Popup
    const closeFilterPopup = () => {
        setShowModal(false)
        setFormColumnList(formFieldList)
    }


    //Downlaod Options Popup
    const showPopup = () => {

        const popupObj = {
            id: 'downloadreport1',
            modalLabel: 'downloadreport1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.downloadSection.downloadTitleStr,
            bodyText: CreateOptionsForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes,

                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return isSchedulePro ?
            <SSPopup {...popupObj} popupBtnHandler={(text) => { }} closepopup={() => closeFilterPopup()} themeSelected={themeSelected} showModal={showModal} />
            : <Popup {...popupObj} popupBtnHandler={(text) => { }} closepopup={() => closeFilterPopup()} themeSelected={themeSelected} showModal={showModal} />

    }


    return isSchedulePro ? (
        <>
            <button className={`${isDisabled ? 'disabled' : 'pointer'} download-section-ss p-1 px-2 pointer`} aria-expanded="false" disabled={isDisabled} onClick={() => setShowModal(true)}>

                <button className={`${isDisabled ? 'disabled' : 'pointer'} d-flex justify-content-start align-items-center me-2 downloadSection no-bg no-border`} >
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        />
                    }
                    <span className='font-default'> <i className="fa-solid fa-download"></i> </span>
                    <div className={`downloadButton-ss ms-1 bg-light ${themeSelected}`}>
                        {AppConstant.downloadSection.downloadTitleStr}</div>
                </button>
            </button>
            {showPopup()}
        </>
    )
        : (
            <>
                <button className={`${isDisabled ? 'disabled' : 'pointer'} dropdown border border-dark rounded-2 p-1 px-2 d-flex justify-content-start align-items-center`} aria-expanded="false" disabled={isDisabled} onClick={() => setShowModal(true)}>

                    <button className={`${isDisabled ? 'disabled' : 'pointer'} d-flex justify-content-start align-items-center me-2 downloadSection  no-bg no-border`} >
                        {
                            isLoading &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            />
                        }
                        {themeSelected === 'light-mode' ? <DownloadIconLight className='me-2' /> : <DownloadIconDark className='me-2' />}
                        <div className={`downloadButton ${themeSelected}`}>{AppConstant.downloadSection.downloadTitleStr}</div>
                    </button>
                </button>
                {showPopup()}
            </>
        )

}

export default memo(DownloadSectionBECustomFeilds);