import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import _ from 'lodash-es';
import { useDispatch, useSelector } from "react-redux";
import { sagaActions } from "../../../../../../../../sagas/sagaActions";
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';

/**
 * Used to show the warehouse list items and actions
 */
const PoIntakeAssignmentList = (props) => {
    const {  themeSelected, poIntakeListData, editClicked,changeStatus,deleteClicked,loading} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const warehouseTitleDD = [
        { id: 'Id', name: 'S.No' },
        { id: 'company', name: 'Company' },
        { id: 'warehouse', name: 'Warehouse' },
        { id: 'dock', name: 'Dock' },
        { id: 'fileName', name: 'File Name' },
        { id: 'status', name: 'Status' },
        { id: 'actions', name: 'Actions' },
    ]



    const actionsButton = (test, index) => {
        return (
            <div className='m-0 p-0 gap-1 d-flex justify-content-center'>
                <CustomButton
                    title={'Edit'}
                    className={'addButton'}
                    isLoading={false}
                    onClick={() => editClicked({...test})}
                    disabled={false}
                />
               <CustomButton
                    title={'Delete'}
                    className={'addButton'}
                    isLoading={false}
                    onClick={() => deleteClicked({...test})}
                    disabled={false}
                />
                 <CustomButton
                    title={'Logs'}
                    className={'addButton'}
                    isLoading={false}
                    onClick={() => {
                        dispatch({ type: sagaActions.STORE_POINTAKE_LOG_PAYLOAD, payload: test })
                        navigate(`/pointakemanagement/pointakemanagementlog/${test.FileName}`)
                    }}
                    disabled={false}
                />
            </div>
        )
    }

    const getToggleSwitch = (value, el) => {
        return (
            <div className='d-flex justify-content-center align-items-center py-1'>
                  <div className="form-check form-switch " data-testid='common_toogle_switch'>
                  <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={value}
                        id="flexSwitchCheckChecked"
                        checked={value}
                        onChange={(event) => changeStatus(el)}
                        disabled={false}
                        data-testid='common_toogle_switch_enabled'
                    />
                    </div>
            </div>
        )
    }

    const formatListData = (data) => {

        return data && data.map((el, index) => {
            return {
                id: el.Id,
                0: (index + 1),
                1:el.CompanyName || '-',
                // 2: el.WarehouseName || '-',
                2: el.WarehouseName ? el.WarehouseName : '-',
                // 3: el.DockName || '-',
                3: el.DockName ? el.DockName : (el.DefaultDockName ? el.DefaultDockName : '-'),
                4: el.FileName || '-',
                5: getToggleSwitch(el.IsActive,el) || '-',
                6: actionsButton(el, index),
            }
        })
    }

    return (
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={poIntakeListData && formatListData(poIntakeListData)}
            titleData={warehouseTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={false}
            drillDownReport={poIntakeListData}
            initiateDrillDownReport={loading}
            loaderArray={7}
            sortingAction={sagaActions.WAREHOUSE_LIST_SORTING}
            subRoot={{ pathName: 'warehouseAdmin' }}
            showUTCTime={false}
        />
    )
}

export default PoIntakeAssignmentList;