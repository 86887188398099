import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { getPoSSselector } from '../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { usePrevious } from '../../../../../../Common/CustomHooks/usePrevious';
import './AllAppointmentsListSS.css';
import moment from 'moment-timezone';
import DrilldownTableSS from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import WarehouseSearchSS from '../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import { CustomPaginationSS } from '../../../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { getRemoteConfigSelector } from '../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import DownloadSectionBECustomFeilds from '../../../../DownloadSection/DownloadSectionBECustomFeilds';
import CustomDropDown from '../../../../../../Common/CustomDropDown/CustomDropDown';
import AppointmentReportHistory from './Components/AppointmentReportHistory';
import ModalSS from '../../../../../../SmartSchedule/SchedulePro/Components/Common/ModalSS/ModalSS';
import UploadSproDocumentsPopup from '../../../../../../Common/UploadSproDocumentsPopup/UploadSproDocumentsPopup';
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';
import PoDetailsPopup from '../../../../../../Common/PoDetailsPopup/PoDetailsPopup';


const AllAppointmentsListSS = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const [allApptsArr, setAllApptsArr] = useState([]);
    const pOSS = useSelector(getPoSSselector);
    const { myAppointmentsList, arrangeOrder, arrangeKey, myAppointmentsListResponse,apptsReportHistoryList, isLoading } = pOSS;
    const userObj = useSelector(getUserSelector);
    const [searchStr, setSearchStr] = useState(null);
    const { selectedWarehouse, userType, userAccountDetails } = userObj;
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const previousTable = usePrevious(currentTable);
    const ss = useSelector(smartScheduleSelector);
    const { isDownloadLoading, initateLoading, appointmentDocumentsList, isActiveCommanDownloadPopup, commanDownloadMessage } = ss;
    moment.tz.setDefault(selectedWarehouse?.TimeZone)
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration;
    const [currentFilterSelected, setCurrentFilterSelected] = useState(null);
    const [showAppointmentHistoryModal, setShowAppointmentHistoryModal] = useState(false);
    const [apptNumber, setapptNumber] = useState(null);
    const [showUploadDocsModal, setShowUploadDocsModal] = useState(false)
    const [popupshowMorePoObj, setPopupshowMorePoObj] = useState(null)
    const [showPoDetailsModal, setShowPoDetailsModal] = useState(false)
    const [selectedPo, setSelectedPo] = useState(null);

    useEffect(() => {
        dispatch({type: sagaActions.CLOSE_COMMAN_DOWNLOAD_MESSAGE_POPUP})
        return () => {
            dispatch({type: sagaActions.CLOSE_COMMAN_DOWNLOAD_MESSAGE_POPUP})
        }
    },[])
    
    useEffect(() => {
        if (showUploadDocsModal) {
           fetchUploadDocsDataForAppt()
        }

    }, [showUploadDocsModal])

    useEffect(() => {
        if (isActiveCommanDownloadPopup) {
            const popupshowMorePoObj = {
                id: 'popupshowMorePoObj',
                modalLabel: 'showMorePo',
                showClose: true,
                showfooter: true,
                showHeader: true,
                sendDriverNotification: false,
                modalSize: 'md',
                title: 'Download Report Notification',
                bodyText: <div>{commanDownloadMessage}</div>,
                subText: "",
                keyboard: false,
                backdrop: "static",
                footerBtn: {
                    btn1: {
                        show: true,
                        text: AppConstant.commonStrings.close
                    },
                    btn2: {
                        show: false,
                        text: AppConstant.commonStrings.no
                    }
                },
            }
            setPopupshowMorePoObj({...popupshowMorePoObj})
        } else {
            setPopupshowMorePoObj(null)
        }
    },[isActiveCommanDownloadPopup])


    const apptsTitleDD = [
    
        { id: 'serial_num', name: 'S.No' },
        { id: 'ApptNumber', name: 'Appt Number' },
        { id: 'PoNumber', name: 'PO/SO' },
        { id: userType === 'CARRIER' ? 'WarehouseName' : 'CarrierName', name: userType === 'CARRIER' ? 'Warehouse' : 'Carrier' },
        { id: 'DueDate', name: 'Due Date' },
        { id: 'ApptStartTime', name: 'Appt Date & Time' },
        { id: 'DockName', name: 'Dock' },
        { id: 'BuyerName', name: 'Buyer' },
        { id: 'Pallets', name: 'Pallets' },
        { id: 'ItemsCases', name: AppConstant.smartScheduler.poSection.cases  },
        { id: 'ApptCreatedName', name: 'Scheduled By' },
        { id: 'ApptScheStatus', name: 'Status' },
        { id: 'Reason', name: 'Reason' },
        { id:'uploadDocs', name: 'Actions' },
        { id: 'Accordion', name: 'Multi PO/SO' }
    ]

    const apptsTitleDDWH = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'ApptNumber', name: 'Appt Number' },
        { id: 'PoNumber', name: 'PO/SO' },
        { id: userType === 'CARRIER' ? 'WarehouseName' : 'CarrierName', name: userType === 'CARRIER' ? 'Warehouse' : 'Carrier' },
        { id: 'DueDate', name: 'Due Date' },
        { id: 'ApptStartTime', name: 'Appt Date & Time' },
        { id: 'DockName', name: 'Dock' },
        { id: 'BuyerName', name: 'Buyer' },
        { id: 'Pallets', name: 'Pallets' },
        { id: 'ItemsCases', name: AppConstant.smartScheduler.poSection.cases  },
        { id: 'ApptScheStatus', name: 'Status' },
        { id: 'Reason', name: 'Reason' },
        { id:'uploadDocs', name: 'Actions' },
        { id: 'Accordion', name: 'Multi PO/SO' }
    ]

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_APPTS_LIST_ACTION });
        setShowLoading(true);
        setAllApptsArr([]);
        setCurrentFilterSelected({ name: 'All' })
        // setTimeout(() => {
        //     fetchAppts()
        // }, [500])
    }, [])

    useEffect(() => {
        if (myAppointmentsListResponse && myAppointmentsListResponse.data.length) {
            setShowLoading(false);
            setAllApptsArr(myAppointmentsListResponse.data)
        } else {
            setAllApptsArr([])
            setShowLoading(false)
        }

    }, [myAppointmentsListResponse])

    //used to fetch appointments based on the filters
    useEffect(() => {
        if (currentFilterSelected) {
            fetchAppts(1,PaginationDefaultLimit,searchStr)
        }
    },[currentFilterSelected])

    //used to fetch appointments report list
    const fetchAppts = (pageNo = 1, limit = PaginationDefaultLimit, searchParams) => {
        setShowLoading(true);
        const apiParams = {
            "ID": userType === 'CARRIER' ? userAccountDetails?.IDCarrier : selectedWarehouse.ID,
            "type": userType === 'CARRIER' ? 'carrier' : 'warehouse',
            "pageNo": pageNo,
            "limit": limit,
            "searchParam": searchParams ? searchParams : null,
            "ApptStatus": currentFilterSelected?.name ? currentFilterSelected?.name : null
        }
        dispatch({ type: sagaActions.MY_APPTS_LIST_ACTION, payload: apiParams });
    }

    const fetchApptsReportHistoryDetails = (apptNumber) => {
        // const apiParams = {
        //     "AppointmentNumber": apptNumber
        // }
        // dispatch({ type: sagaActions.GET_APPOINTMENT_REPORT_HISTORY, payload: apiParams });
        setapptNumber(apptNumber)
        setShowAppointmentHistoryModal(true)
    }


    useEffect(() => {
        if (searchStr && searchStr.length > 3) {
            setTimeout(() => {
                fetchapptList(searchStr)
            }, 1000)
        } else if (searchStr === '') {

            fetchapptList(null)
        }
    }, [searchStr])
    
    const fetchapptList = (searchParams=null,pageNo=1,limit=PaginationDefaultLimit,) => {
        fetchAppts(pageNo,limit,searchParams);
    }

    const getApptSchStatusColor = (str) => {
        if (str === 'Completed') {
            return <p className='text-success'>{str}</p>
        } else if (str === 'Complete') {
            str='Completed';
            return <p className='text-success'>{str}</p>
        } else if (str === 'Schedule') {
            str='Scheduled';
            return <p className='notScheStatusText'>{str}</p>
        } else if (str === 'Delete') {
            return <p className='text-danger'>{cancelObj[str]}</p>
        } else if (str === 'Re-Schedule') {
            return <p className='notScheStatusText'>{cancelObj[str]}</p>
        } else {
            return <p className='notScheStatusText'>{str}</p>
        }
    }

    const documentModelHandler = (el) => {
        if (showUploadDocsModal) {
            setapptNumber(null)
        } else {
            setapptNumber(el?.AppointmentNumber)
        }
        setShowUploadDocsModal(!showUploadDocsModal)
    }

    
    const fetchUploadDocsDataForAppt = () => {
        if (apptNumber) {
            dispatch({type: sagaActions.INTIATE_SS_LOADER, payload: true})
            let fd = new FormData();
            fd.append('AppointmentNumber', apptNumber)
            fd.append('file', null); // Assuming 'file' is the file object
            fd.append('docName', null); // Append the document name
            fd.append('ApptDocsCreatedBy', null);
            fd.append('ApptDocsUpdatedBy', null);
            fd.append('CRUDTYPE', 'S');
            dispatch({
                type: sagaActions.UPLOAD_APPT_DOCS,
                payload: {
                    CRUDTYPE: 'S',
                    uploadData: fd
                }
            });
        }
    }

    const getActions=(el)=>{
        return(
            <div className='d-flex justify-content-evenly'>
            <span onClick={() => documentModelHandler(el)}><i className="fa-solid fa-file text-grey fa-lg" title='Show Attachments'></i></span>
            <span onClick={() => {
                setSelectedPo(el)
                setShowPoDetailsModal(true)
            }}><i className="fa-solid fa-circle-info text-grey fa-lg" title='Show More Details'></i></span>

            </div>
        )
    }

    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            if (userType === 'CARRIER') {
                return {
                    id: el.RoleID,
                    0: index + 1,
                    1: <span style={{textDecoration: 'underline',color: 'blue'}} onClick={() => fetchApptsReportHistoryDetails(el.AppointmentNumber)}>{el.AppointmentNumber}</span>,
                    2: el.PoNumber,
                    3: el?.WarehouseDetails?.Name || '-',
                    4: el.DueDate ? moment(el.DueDate).utc().format('MM/DD/YYYY') : '-',
                    5: el.ApptStartTime ? moment(el.ApptStartTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '-',
                    6: el.DockName || '-',
                    7: el.BuyerName || '-',
                    8: el.Pallets || '-',
                    9: el.ItemsCases || '-',
                    10: el.ApptCreatedName ? el.ApptCreatedName : '-',
                    11: el.ApptScheStatus ? getApptSchStatusColor(el.ApptScheStatus) : '-',
                    12: el.Reason ? el.Reason : '-',
                    13: getActions(el)
                }
            }
            else {
                return {
                    id: el.RoleID,
                    0: index + 1,
                    1: <span style={{textDecoration: 'underline',color: 'blue'}} onClick={() => fetchApptsReportHistoryDetails(el.AppointmentNumber)}>{el.AppointmentNumber}</span>,
                    2: el.PoNumber,
                    3: el?.CarrierName || '-',
                    4: el.DueDate ? moment(el.DueDate).utc().format('MM/DD/YYYY') : '-',
                    5: el.ApptStartTime ? moment(el.ApptStartTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '-',
                    6: el.DockName || '-',
                    7: el.BuyerName || '-',
                    8: el.Pallets || '-',
                    9: el.ItemsCases || '-',
                    10: el.ApptScheStatus ? getApptSchStatusColor(el.ApptScheStatus) : '-',
                    11: el.Reason ? el.Reason : '-',
                    12: getActions(el)
                }
            }

        })
    }

    const cancelObj = {
        'Delete': 'Cancelled',
        'Re-Schedule': 'Rescheduled'
    }
    const toggleModal = () => {
        setShowAppointmentHistoryModal(!showAppointmentHistoryModal);
    }

    const formatdata = (data) => {
        if (!data.length) {
            return;
        }
        // let result = []
        // const tempArr = data.map(ele => {
        //     if (ele.PORelationID === ele.PoNumber) {
        //         return ({ ...ele, masterPO: true })
        //     }
        //     else {
        //         return ({ ...ele, masterPO: false })
        //     }
        // })
        // let newlist = _.groupBy(tempArr, 'AppointmentNumber')
        // let finaldata = Object.values(newlist);
        // finaldata.forEach(element => {
        //     let l = element.sort((a, b) => {
        //         if (a.masterPO) {
        //             return -1;
        //         } else {
        //             return 1;
        //         }
        //     })
        //     result.push(l)
        // });
        let result = data?.map(el => {
            if (el?.associatedPO && el?.associatedPO.length) {
                return [el, ...el?.associatedPO]
            } else {
                return [el]
            }
        })
         // Sort each sub-array based on ApptStartTime
        //  const sortedResponse = result.map(subArray => _.orderBy(subArray, ['ApptEndTime'], ['asc']));
         const sortedResponse = result.map(subArray => [subArray[0],..._.orderBy(subArray.splice(1,_.size(subArray)-1), ['ApptEndTime'], ['asc'])]);
         // Sort the main array based on the ApptStartTime of the first element in each sub-array
         const finalSortedResponse = _.sortBy(sortedResponse, subArray => subArray[0]?.ApptEndTime);
         return finalSortedResponse;
    }


    const getBodyData = () => {
        if (allApptsArr.length) {
            const formatedData = formatdata(allApptsArr);
            return (formatedData.map((data, index) => {
                return (
                    formatListDataDrillDown(data, index)
                )
            }))
        }
        else {
            return []
        }
    }
    const pageChange = (pageNo, limit = PaginationDefaultLimit) => {
        fetchAppts(pageNo, limit, searchStr)
    }

    const downloadData = (type) => {
        let payload = {
            heading: "Appointment report",
            subDomain: "appointmentreport",
            downloadType: type,
            fileName: "AppointmentReport-" + userAccountDetails?.UserName,

            options: {
                startDate: null,
                endDate: null,
                ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : selectedWarehouse.ID,
                type: userType === 'CARRIER' ? 'carrier' : 'warehouse',
            },
            userDetails:{
                CUID:userAccountDetails?.CUID,
            }
        }
        return payload
      }

         //Call Download Report API
         const downloadReport=(payload)=>{
            dispatch({ type: sagaActions.DOWNLOAD_REPORT_COMMON, payload });
          }

          //Colums List for download column specific report
    const columnListWarehouse=[
        {
            name: "Appt Number",
            value: "AppointmentNumber"
        },
        {
            name:"PO",
            value:"PoNumber"
        },
        {
            name:"Carrier",
            value:"CarrierName"
        },
        {
            name:"Due Date",
            value:"DueDate",
            isDate:true
        },
        {
            name:"Appt-Time",
            value:"ApptStartTime",
            isDate:true,
            isTime:true
        },
        {
            name:"Dock",
            value:"DockName"
        },
        {
            name:"Buyer",
            value:"BuyerName"
        },
        {
            name:"Pallets",
            value:"Pallets"
        },
        {
            name:"Status",
            value:"ApptScheStatus"
        },
        {
            name: "Reason",
            value: "Reason"
        },
        {
            name: "Vehicle Type",
            value: "vehicleType"
        },
        {
            name: "Load Type",
            value: "orderType"
        }
    ]

    const columnListCarrier=[
        {
            name: "Appt Number",
            value: "AppointmentNumber"
        },
        {
            name:"PO",
            value:"PoNumber"
        },
        {
            name:"Warehouse",
            value:"WarehouseName"
        },
        {
            name:"Due Date",
            value:"DueDate",
            isDate:true
        },
        {
            name:"Appt-Time",
            value:"ApptStartTime",
            isDate:true,
            isTime:true
        },
        {
            name:"Dock",
            value:"DockName"
        },
        {
            name:"Buyer",
            value:"BuyerName"
        },
        {
            name:"Pallets",
            value:"Pallets"
        },
        {
            name:"Scheduled By",
            value:"ApptCreatedName"
        },
        {
            name:"Status",
            value:"ApptScheStatus"
        },
        {
            name: "Reason",
            value: "Reason"
        },
        {
            name: "Vehicle Type",
            value: "vehicleType"
        },
        {
            name: "Load Type",
            value: "orderType"
        }
    ]

    /**
     * used for the appointment report status filter
     * @returns 
     */
    const showCustomDropDownForFilters = () => {
        const filterDataArr = [
            {
                name: 'All',
            },
            {
                name: 'Completed',
            },
            {
                name: 'Scheduled',
            },
            {
                name: 'Rescheduled',
            },
            {
                name: 'Cancelled',
            }
        
        ]
        return (
            <div className='d-flex align-items-center'>
                <CustomDropDown themeSelected={themeSelected} data={filterDataArr} placeHolder={currentFilterSelected?.name} onSelect={(event) => setCurrentFilterSelected(event)} keyStr={'name'} />
            </div>
        )
    }

    const closeSelectPopopup = () => {        
        dispatch({type: sagaActions.CLOSE_COMMAN_DOWNLOAD_MESSAGE_POPUP})
    }
    return (
        <div className='appointments-report-ss-wrapper p-3'>
            <div className='d-flex justify-content-between align-items-center my-2 mx-3'>
                <div className='f-16'>{'Appointments'}</div>
                <div className='d-flex justify-content-end all-appt-list-action-right'>
                { currentFilterSelected?.name ? 
                        showCustomDropDownForFilters() : ''
                    }
                    <div className='pos-rel ps-2'>
                        <WarehouseSearchSS
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    <div className='d-flex align-items-center mx-3'>
                        <DownloadSectionBECustomFeilds isSchedulePro={true} downloadData={downloadData} isLoading={isDownloadLoading} isDisabled={isDownloadLoading} columnList={userType === 'CARRIER' ? columnListCarrier : columnListWarehouse} downloadReport={downloadReport}
                        showEmail={true}
                        emailValue={userAccountDetails && userAccountDetails.Email ?userAccountDetails.Email:""}
                        showDateRange={true}
                        showApptStatus={true}
                        />
                        {/* <DownloadSectionBE isSchedulePro={true} downloadData={downloadData} isLoading={isDownloadLoading} isDisabled={isDownloadLoading}/> */}
                        {/* <DownloadSectionSS singlePageDownload={true} root={''} subRoot={''} name={'Appointments Report'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} /> */}
                    </div>
                </div>

            </div>
            <div className='allApptsListContainer-ss' ref={divRef}>
                <DrilldownTableSS
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={userType === 'CARRIER' ? apptsTitleDD : apptsTitleDDWH}
                    showCollapse={false}
                    showInput={false}
                    showSort={false}
                    hasCollapsableContent={true}
                    collapsableData={formatdata(allApptsArr)}
                    accordionPrimaryIndex={1}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={myAppointmentsList}
                    initiateDrillDownReport={showLoading}
                    loaderArray={userType === 'CARRIER' ? 15: 14}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'allApptsList' }}
                    showUTCTime={true}
                />
            </div>
            {!_.isEmpty(myAppointmentsListResponse) && myAppointmentsListResponse?.data?.length ?
                <div>
                    <CustomPaginationSS
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                        pageNo={myAppointmentsListResponse.pageNo}
                        pageSize={myAppointmentsListResponse.limit}
                        totalElements={myAppointmentsListResponse.totalElements}
                        totalPages={Math.ceil(myAppointmentsListResponse.totalElements / myAppointmentsListResponse.limit)}
                        last={myAppointmentsListResponse.last}
                        first={myAppointmentsListResponse.first}
                        data={myAppointmentsListResponse.data}
                    />
                </div>:""
            }
            {showAppointmentHistoryModal &&
                        <ModalSS toggleModal={toggleModal}>
                            <AppointmentReportHistory  
                            themeSelected={themeSelected}
                            data={apptNumber}
                            />
                        </ModalSS>

            }
            {showUploadDocsModal ? <UploadSproDocumentsPopup showModal={showUploadDocsModal} setCloseModal={setShowUploadDocsModal} uploadDocsHandler={() => {}} docDeleteHandler={() => {} } appointmentDocumentsList={appointmentDocumentsList} showDelete={false} showUpload={false} initateLoading={initateLoading} /> : ''}
            {showPoDetailsModal ? <PoDetailsPopup showModal={showPoDetailsModal} setCloseModal={setShowPoDetailsModal} poData={{...selectedPo,WarehouseData:selectedPo.WarehouseDetails}} initateLoading={initateLoading} /> : ''}

            {isActiveCommanDownloadPopup ? <SSPopup {...popupshowMorePoObj} popupBtnHandler={() => dispatch({type: sagaActions.CLOSE_COMMAN_DOWNLOAD_MESSAGE_POPUP})} closepopup={closeSelectPopopup} themeSelected={themeSelected} showModal={isActiveCommanDownloadPopup} />: ''}
        </div>
    )
}

export default AllAppointmentsListSS