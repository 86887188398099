// src/components/DocumentUploadPopup.js
import React, { useEffect, useState, useRef } from 'react';
import { AppConstant } from '../../../Assests/AppConstant';
import SSPopup from '../Popup/SSPopup/SSPopup';
import CustomButtonSS from '../CustomButton/CustomButtonSS';
import _ from 'lodash-es';

import CustomFieldWithTtileSS from '../CustomField/CustomFieldWithTtileSS';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../sagas/sagaActions';

const initialErrors={
    vehicleType:'',
    loadType:'',
}

const VehicleAndLoadTypePopup = (props) => {
    const { themeSelected, showModal, setCloseModal, initateLoading,poDetails, setScheduleScreenType } = props;
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [loading,setLoading] = useState(false)
    const [inputs,setInputs]=useState({
        vehicleType:"",
        loadType:"",

    })

    useEffect(()=>{
        if (poDetails) {
            setInputs({vehicleType:poDetails.vehicleType,loadType:poDetails.orderType})
        }
    },[poDetails])

    const [errors,setErrors]  = useState(initialErrors)

    const [dirty,setDirty]  = useState({
        vehicleType:false,
        loadType:false,
 
    })

    /**
     * useEffect to show and hide modal
     */
    useEffect(() => {
        setShow(showModal)
    }, [showModal])

    /**
     * useEffect to for loader
     */
    useEffect(() => {
        setLoading(initateLoading)
    }, [initateLoading])
    


    /**
     * fuction to close modal
     */
    const handleClose = () => {
        setShow(false)
        setCloseModal(false)
        dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
        setScheduleScreenType('text')
    };
    
    let handleChange = (data, name) => {
        if (typeof data === 'object') {
            data = data.toString();
        }
        setInputs((inputs) => ({ ...inputs, [name]: data }));

    };

    const handleErrors = (name) => {
        setDirty((dirty) => ({ ...dirty, [name]: true }));
        validate(name);
    };

    const validate=(name) => {

        let valid=true
        let errorData={...initialErrors}
        if (!inputs.vehicleType) {
            errorData.vehicleType=AppConstant.help.required
            valid=false
        }
        if (!inputs.loadType) {
            errorData.loadType=AppConstant.help.required
            valid=false
        }
        setErrors(errorData)
        return valid
    }

    const submitForm = () => {
        let dirtyData = dirty;
        Object.keys(dirty).forEach((control) => {
            dirtyData[control] = true;
        });
        
        
        setDirty(dirtyData);
        if (validate() ) {
            dispatch({type:sagaActions.UPDATE_VEHICLE_AND_LOAD_TYPE,payload:inputs})
            setShow(false)
            setCloseModal(false)
        }
    };

    const getVehicalTypeList = () => {
        const list = [
            {
                id:1,
                name:"Truck"
            },
            {
                id:2,
                name:"Container"
            },
        ]
        return list.map((ele, index) => {
            return (
                <option key={ele.id} value={ele.name}>
                    {ele.name}
                </option>
            );
        })
    }

    const getLoadTypeList = () => {
        const list = [
            {
                id:1,
                name:"Palletized"
            },
            {
                id:2,
                name:"Floor Loaded"
            },
        ]
        return list.map((ele, index) => {
            return (
                <option key={ele.id} value={ele.name}>
                    {ele.name}
                </option>
            );
        })
    }

 const QustionerForm = () =>{
    return(

                  <div className='d-flex flex-column w-100 gap-2'>
            <CustomFieldWithTtileSS
              type={"select"}
              showError={
                dirty["vehicleType"] && errors["vehicleType"][0]
                  ? errors["vehicleType"]
                  : ""
              }
              name={"vehicleType"}
              value={inputs["vehicleType"] ? inputs["vehicleType"] : ""}
              handleErrors={handleErrors}
              title={"Vehicle Type"}
              required={true}
              onChange={(data, name) => handleChange(data, name)}
              listData={getVehicalTypeList}
              disabledField={false}
            />
              <CustomFieldWithTtileSS
              type={"select"}
              showError={
                dirty["loadType"] && errors["loadType"][0]
                  ? errors["loadType"]
                  : ""
              }
              name={"loadType"}
              value={inputs["loadType"] ? inputs["loadType"] : ""}
              handleErrors={handleErrors}
              title={"Load Type"}
              required={true}
              onChange={(data, name) => handleChange(data, name)}
              listData={getLoadTypeList}
              disabledField={false}
            />
                 <div className="d-flex justify-content-end my-2">
            <CustomButtonSS
                isLoading={false}
                title={"Close"}
                className={"btn-unstyled"}
                onClick={handleClose}
              />
              <CustomButtonSS
                isLoading={loading}
                title={"Next"}
                className={"btn-styled"}
                onClick={submitForm}
              />
            </div>
            </div>

    )
 }

    


    const QuestionerObj = {
        id: "QuestionerObj",
        modalLabel: "QuestionerObj",
        showClose: false,
        showfooter: false,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "md",
        title: "Vehicle And Load Type",
        bodyText: QustionerForm(),
        subText: "",
        keyboard: false,
        backdrop: "static",
        alignType: 'start',
        type: 'info',
        footerBtn: {
            btn1: {
                show: false,
                text: AppConstant.commonStrings.yes,
            },
            btn2: {
                show: false,
                text: AppConstant.commonStrings.no,
            },
        },
    }

    return (
        <>
            {show ? <SSPopup {...QuestionerObj} popupBtnHandler={() => { }} closepopup={handleClose} themeSelected={themeSelected} showModal={show} /> : ''}

        </>
    );
};

export default VehicleAndLoadTypePopup;
