import React, { useEffect, useState } from 'react'
import './SchedulerNewSS.css'
import Week from './Components/Week'
import DatePickerSS from '../../DatePickerSS/DatePickerSS'
import { createWPMetaData, displayLoader, formatDate_MM_DD_YYYY } from '../../../Assests/Utility'
import { convertToJSDate } from './helpers/genrrals'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { smartScheduleSelector } from '../../../../redux/reducers/reducerSlices/SmartScheduleReducer'
import { AppConstant } from '../../../Assests/AppConstant'
import moment from 'moment'
import CustomDropDownSS from '../../Common/CustomDropDown/CustomDropDownSS'
import { sagaActions } from '../../../../sagas/sagaActions'
import { getUserSelector } from '../../../../redux/reducers/reducerSlices/UserReducer'
import ScheduleProWrapper from '../SchedulePro/ScheduleProWrapper'
import CustomButtonSS from '../../Common/CustomButton/CustomButtonSS'
import CommonToggleSwitch from '../../Common/CommonToggleSwitch/CommonToggleSwitch'
import { fetchLeavesDocksListCommonFunc } from '../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/LeavesForm/Common/FetchLeavesList'
import { getWhMetaDataReducerSlice } from '../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice'
import { fetchBreakListCommonFunc, fetchBreaksDocksListCommonFunc } from '../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/BreaksForm/Common/FetchBreakList'
import { SSAppointmentSearch } from '../SchedulePro/Components/DashBoard/Children/SSAppointmentSearch/SSAppointmentSearch'
import ModalSS from '../SchedulePro/Components/Common/ModalSS/ModalSS'
import { fetchDockListCommonFunc } from '../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/DocksMeta/Common/FetchDockList'
import { closeUpdatesSocketConnection, initializeUpdatesSocketConnection } from '../../../../Utils/updateSocket'
import { addDaysToDate } from '../../../../Utils/DateUtils'
import { format } from 'date-fns'

function SchedulerNewSS(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ss = useSelector(smartScheduleSelector);
    const {
      appointmentList,
      initateLoading,
      searchedPOData,
      searchedPOErrorData,
      defaultDockValue
    } = ss;

    const user = useSelector(getUserSelector);
    const { cu_id, userType, selectedWarehouse } = user;
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { whMetaLeavesList ,whMetaBreaksList ,whMetaDocksList} = whMetaData;
  const [selectedDate, setSelectedDate] = useState(new Date())
    const [showCalendar, setShowCalendar]= useState(false);
    const [apptList,setApptList]= useState([])
    const [dockList,setDockList] = useState([])
    const [eventList,setEventList]=useState([])
    const [startHour,setStartHour]=useState(null);
    const [endHour,setEndHour]=useState(null);
    const [allBreaksList,setAllBreaksList] = useState([])
    const [breakList,setBreakList]=useState([])
    const [whBreaks,setWhBreaks]= useState([])
    const [dockBreaks,setDockBreaks]= useState([])
    const [showScheduleModal,setShowScheduleModal] = useState(false)
    const [scheduleScreenType, setScheduleScreenType] = useState('');
    const [issearched, setIsSearched] = useState(false);
    const [po_number, setPo_number] = useState(null);
    const [receivingZipcode, setReceivingZipcode] = useState(null);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [didSearched, setDidSearched] = useState(false);
    const [searchPoDataState, setSearchPoDataState] = useState(null);
    const [selectedDock,setSelectedDock]=useState(null)
    const [shouldRender, setShouldRender] = useState(false)
    const [placeHolderDock, setPlaceHolderDock] = useState(false)
  const [defaultDockValueState, setDefaultDockValueState] = useState(false)
  const [socketRef, setSocketRef] = useState(null)
  
    const onEmailChange = (e, type) => {
      if (type === 'po') {
          setPo_number(e)
      } else if (type === 'zipcode') {
          setReceivingZipcode(e)
      }
  }
  
  const displayAppointmentsList = () => {
    setSearchPoDataState([])
    // dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [] })
    // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
    if (po_number && receivingZipcode) {
        setDidSearched(true)
        dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true })
        dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: po_number, ReceivingZipCode: receivingZipcode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : selectedWarehouse?.ID } })
    } else {
        dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO_ERROR, payload: '* Both PO and Zip Code are mandatory' })
        // showAlert(toast.TYPE.WARNING, 'Both PO and Zip Code are mandatory', 'light')
    }
  
  }

  /**
   * Function to fetch dock list
   */
  const fetchDockList = () => {
    const { apiParams, operationObj } = fetchDockListCommonFunc(selectedWarehouse, cu_id);
    dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
}
  
  useEffect(() => {
    setDidSearched(false)
    setShowHelpModal(false);
    dispatch({ type: sagaActions.CLEAR_APPOINTMENT_TRACKER })
    dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
  
    if (userType === 'USER') {
        setReceivingZipcode(selectedWarehouse?.Zip)
    }
  
    // Moved to location useEffect
    // dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
    // Commented due API's firing 2 times on load(depends on trackerBtnType)
    // dispatch({ type: sagaActions.GET_APPOINTMENT_TRACKER, payload: { Date: trackerBtnType?.date, CUID: cu_id } })
    return () => {
        dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
        dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO_ERROR, payload: null });
    }
  }, [])

  useEffect(() => {

    return () => {
      if (socketRef) {
        closeUpdatesSocketConnection(socketRef, selectedWarehouse.ID)
      }
    }
  }, [])
  
  /**
    * Method is used to handle truck update and fetch latest truck data
    */
  const onUpdateSocketReceived = (message, date) => {
    let parsedMessage = JSON.parse(message)
    if (typeof parsedMessage === 'string') {
      parsedMessage = JSON.parse(parsedMessage)
    }
    
    if (!_.isEmpty(parsedMessage)) {
      const { type } = parsedMessage;
      switch (type) {

        case 'APPT_END_TIME':
          dispatch({
            type: sagaActions.GET_SCHEDULER_DATA,
            payload: {
              IDWarehouse: selectedWarehouse?.ID, DateSelected: date,
            CalendarView: 'WEEK'},
          });
          break;
        case 'DOCK_STATUS':
          fetchDockList();
          break;
        case 'DOCK_PPH':
          fetchDockList();
          break;
        case 'DOCK_START_TIME':
          // Trigger API and dom highlight function
          // fetchDockList();
          // Currently in week Dock start and End time are not highlighted
          break;
        case 'DOCK_END_TIME':
          // Trigger API and dom highlight function
          // fetchDockList();
          // Currently in week Dock start and End time are not highlighted
          break;
        case 'DOCK_BREAK_STATUS':
          fetchBreakDockList();
          break;
        case 'DOCK_BREAK_START_TIME':
          fetchBreakDockList();
          break;
        case 'DOCK_BREAK_END_TIME':
          fetchBreakDockList();
          break;
        case 'DOCK_BREAK_DESCRIPTION':
          fetchBreakDockList();
          break;
        case 'DOCK_BREAK_DELETED':
          fetchBreakDockList();
          break;
        case 'DOCK_LEAVE_STATUS':
          fetchDocksLeaveList();
          break;
        case 'DOCK_LEAVE_START_TIME':
          fetchDocksLeaveList();
          break;
        case 'DOCK_LEAVE_END_TIME':
          fetchDocksLeaveList();
          break;
        case 'DOCK_LEAVE_DESCRIPTION':
          fetchDocksLeaveList();
          break;
        case 'DOCK_LEAVE_DELETED':
          fetchDocksLeaveList();
          break;
        case 'DOCK_OPERATION_DAY':
          fetchDockList();
          break;
        case 'DOCK_DOOR_STATUS':
          fetchDockList();
          break;
        case 'DOCK_DOOR_TIMESLOTNAME':
          fetchDockList();
          break;
        case 'DOCK_DOOR_TIMESLOTSTATUS':
          fetchDockList();
          break;
        case 'DOCK_DOOR_DELETED':
          fetchDockList();
          break;
        default:
          break;
      }
    }

 
}


/**
 * Method is used to captcure socket error and re initate the connection 
 * @param {*} error 
 */
const onUpdateSocketError = (error) => {
    validateURLandFetchAppointments()
}

/**
 * Method is used to capture socket close
 * @param {*} data 
 */
  const onUpdateSocketClose = (data) => { 
      
    //check if user is on same page and if it NORMAL closure or not or IDLE session closure
    //initiatesocket connection
    // refetch appointment data
    if (data.code !== 1000) {
      validateURLandFetchAppointments()
    }
  }
  
  const validateURLandFetchAppointments = () => {
    
    if (window.location.pathname === '/wpscheduleappointments') {
      let socketRefTemp = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose, selectedDate)
      setSocketRef(socketRefTemp)
      dispatch({
        type: sagaActions.GET_SCHEDULER_DATA,
        payload: {
          IDWarehouse: selectedWarehouse?.ID, DateSelected: selectedDate,
        CalendarView: 'WEEK'},
      });

    }
  }
   
  
   const toggleIsSearched = () => {
        setIsSearched(!issearched)
    }
    useEffect(()=>{
      if (searchedPOData.length) {
        setShowScheduleModal(false)
      }
    },[searchedPOData])

    useEffect(()=>{
      if (whMetaBreaksList.length) {
        setAllBreaksList([...whMetaBreaksList])
      }
      else{
        setAllBreaksList([])
        setDockBreaks([])
        setBreakList([])
        setWhBreaks([])
      }
    },[whMetaBreaksList])
  useEffect(() => {
      if (allBreaksList) {
        let whBreaksList=allBreaksList.filter((el)=>el.IDWHBreak  && el.Status)
        setWhBreaks(whBreaksList)
        let dockBreakList=allBreaksList.filter((el)=>el.IDDockBreak && el.Status)
        setDockBreaks(dockBreakList)
        setBreakList(whBreaksList)
        if (_.size(selectedDock)) {
          let dockselectedbreakslist=[];   
          dockselectedbreakslist=dockBreakList.filter((el)=>{
            if (el.IDDock===selectedDock[0]?.dockId && el.Status) {
              return el
            }
            })
          if (dockselectedbreakslist) {
            setBreakList(dockselectedbreakslist)
          } 
        }
      }
    },[allBreaksList])

    const fetchDocksLeaveList = () => {
      const { apiParams, operationObj } = fetchLeavesDocksListCommonFunc(selectedWarehouse);
      dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
  }
  const fetchBreakDockList = () => {
    const { apiParams, operationObj } = fetchBreaksDocksListCommonFunc(selectedWarehouse);
    dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
}

const fetchBreakList = () => {
    const { apiParams, operationObj } = fetchBreakListCommonFunc(selectedWarehouse);
    dispatch({ type: sagaActions.WAREHOUSE_META_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
}

    useEffect(() => {
      dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        setTimeout(() => {
          if (selectedWarehouse?.ID) {
            dispatch({
              type: sagaActions.GET_SCHEDULER_DATA,
              payload: {
                IDWarehouse: selectedWarehouse?.ID, DateSelected: selectedDate,
              CalendarView: 'WEEK'},
            });
    
            const wpCapacityData = createWPMetaData("wpCapacityData", "S", {
              IDWarehouse: selectedWarehouse?.ID,
            });
            const wpBreakData = createWPMetaData("wpBreakData", "S", {
              IDWarehouse: selectedWarehouse?.ID,
            });
            const wpLeaveData = createWPMetaData("wpLeaveData", "S", {
              IDWarehouse: selectedWarehouse?.ID, Status:1
            });
    
            dispatch({
              type: sagaActions.INITIATE_WAREHOUSE_METADATA,
              payload: { payload: wpCapacityData, key: "wpCapacityData" },
            });
            // dispatch({
            //   type: sagaActions.INITIATE_WAREHOUSE_METADATA,
            //   payload: { payload: wpBreakData, key: "wpBreakData" },
            // });
            dispatch({
              type: sagaActions.INITIATE_WAREHOUSE_METADATA,
              payload: { payload: wpLeaveData, key: "wpLeaveData" },
            });
            fetchDocksLeaveList()
            // fetchBreakList()
            fetchBreakDockList()
            fetchDockList()
          }
        }, [0]);
        return()=>{
          dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        }
    }, [])
  
  useEffect(() => {
    if (selectedDate) {
      if (socketRef) {
        closeUpdatesSocketConnection(socketRef, selectedWarehouse.ID)
      }
      const socketRefTemp = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose,selectedDate)
      setSocketRef(socketRefTemp)
      setShouldRender(false)
      dispatch({
        type: sagaActions.GET_SCHEDULER_DATA,
        payload: {
          IDWarehouse: selectedWarehouse?.ID, DateSelected: moment(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        CalendarView: 'WEEK'},
      });
    }
  }, [selectedDate])


    const createDockList=(docklist) => {
        let startHourList =docklist.map((el)=>el.StartTime)
        let endHourList =docklist.map((el)=>el.EndTime)
        let minStartHour=_.min(startHourList)
        let maxEndHour=_.max(endHourList)
      
        let newDockList=[]
        let sorted_docklist = _.sortBy(docklist,
          [function (o) { return o.Name; }]);
          sorted_docklist.forEach(el=>{
            if (el.DockStatus) {
              let obj={};
        obj.dockId=el.dockId;
        obj.dockName=el.Name
        obj.dockStart=convertToJSDate(moment(el.StartTime).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getHours()
        obj.dockEnd=convertToJSDate(moment(el.EndTime).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getHours()
            newDockList.push(obj)
            }
            
        })
        let alldock={
          dockId:"0000",
          dockName:"ALL",
          dockStart:convertToJSDate(moment(minStartHour).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getHours(),
          dockEnd:convertToJSDate(moment(maxEndHour).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getHours()
        }
        newDockList.push(alldock)
        setDockList(newDockList)
    }

    const formatDataForScheduler=(data)=>{
      let updatedList=[]
      data.length && data.forEach(el => {
          let res={...el}
          res.start=convertToJSDate(moment(el.ApptStartTime).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss));
          res.end=convertToJSDate(moment(el.ApptEndTime).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss));
          res.event_id=el.IDPO;
          res.disabled=false;
          updatedList.push(res)
      });
      return updatedList
  }

  const formatBreaksForScheduler=(data)=>{
    let updatedList=[]
    data.length && data.forEach(el => {
      if (el.Status) {
        let res={...el}
        res.start=convertToJSDate(moment(el.BreakStart).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss));
        res.end=convertToJSDate(moment(el.BreakEnd).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss));
        res.event_id=el.IDWarehouse+"_"+(el.IDDockBreak || el.IDWHBreak) ;
        res.disabled=true;
        updatedList.push(res)
      }
    });
    return updatedList
}

    // const formatLeaveDataForScheduler=(data,start=startHour,end=endHour)=>{
    //   let updatedList=[];
    //   if (start && end) {
    //     data.length && data.forEach(el=>{
    //       if (el.Status) {
    //         let res={...el}
    //     let LeaveEndTime=`${el.LeaveEnd.split("T")[0]}T${end}:00:00.000Z`;
    //     let LeaveStartTime=`${el.LeaveStart.split("T")[0]}T${start<10?"0"+start:start}:00:00.000Z`;
    //     res.start=convertToJSDate(moment(LeaveStartTime).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss));
    //     res.end=convertToJSDate(moment(LeaveEndTime).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss));
    //     res.event_id=el.CreatedOn+"_"+el.IDWHLeave;
    //     res.disabled=true;
    //     res.title=el.Purpose || el.Description;
    //     updatedList.push(res)
    //       }
    //   })
    // }
    //   return updatedList
  // }
  const checkIfDockIsActive = (dataArray, sel) => {
    // Assuming your data is stored in dataArray
    const filteredData = _.filter(dataArray, (el) => {
      return el.ID === sel.dockId && el.
        DockStatus
        === true;
    });
    if (_.size(filteredData)) {
      return true
    } else {
      return false
    }

  }

    useEffect(()=>{
      if (whMetaDocksList.length) {
        let startHourList =whMetaDocksList.map((el)=>el.StartTime)
        let endHourList =whMetaDocksList.map((el)=>el.EndTime)
        let minStartHour=_.min(startHourList)
        let maxEndHour=_.max(endHourList)
        setStartHour(convertToJSDate(moment(minStartHour).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getHours())
        setEndHour(convertToJSDate(moment(maxEndHour).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getHours())
        const data = whMetaDocksList[0];
        const { DefaultIDDock } = data;
        dispatch({
          type: sagaActions.UPDATE_DEFAULT_DOCK,
          payload: {
            CUID: cu_id,
              IDWarehouse: selectedWarehouse?.ID,
              DefaultIDDock: DefaultIDDock,
              CRUDTYPE: 'U'
          }
        })
        createDockList(whMetaDocksList)
        if (_.size(selectedDock)) {
        if (checkIfDockIsActive(whMetaDocksList, selectedDock[0])) {
            if (selectedDock[0].dockId !== "0000") {
              setSelectedDock(() => whMetaDocksList.filter((el) => el.dockId === selectedDock[0]?.dockId))
            }
            else {
              setSelectedDock(null)
            }
          }
        } else {
          if (_.size(dockList) ) {
            const allDock = _.filter(dockList, el => el.dockId === '0000')
            if (_.size(allDock)) {
              onDockSelected(allDock[0])
            }
          }
        }
    }
    }, [whMetaDocksList])
  
    useEffect(() => {
      if (defaultDockValue) {
       
        setDefaultDockValueState(defaultDockValue)
      } else {
        setDefaultDockValueState(null)
      }
    }, [defaultDockValue, dockList])
    
    useEffect(() => {
  
      if (defaultDockValueState) {
         if (defaultDockValue && _.size(dockList)) {
        if (defaultDockValue && _.isEmpty(selectedDock)) {
          if (_.size(dockList)) {
            const filterDock = _.filter(dockList, { dockId: defaultDockValue })
            if (filterDock) {
              onDockSelected(filterDock[0])
            }
          }
        }
        else if (!_.isEmpty(selectedDock) && selectedDock?.dockName !== 'ALL') {
          if (_.size(dockList)) {
            const filterDock = _.filter(dockList, { dockId: defaultDockValue })
            if (filterDock) {
              onDockSelected(filterDock[0])
            }
          }
        }
      }
      } 
  
     
    }, [defaultDockValueState, dockList])
  
    useEffect(() => {
      if (_.size(selectedDock)) {
        // if defaultDockValue and selectedDock idDock are equal then make Default Dock as checked
        if (defaultDockValue && defaultDockValue === selectedDock[0]?.ID) {
          setDefaultDockValueState(defaultDockValue)
        } else {
          setDefaultDockValueState(null)
        }
      }
    },[selectedDock])

    useEffect(()=>{
        if (appointmentList.length) {
          // let response=formatLeaveDataForScheduler([...wpLeaveData])
          //   setApptList([..._.orderBy(formatDataForScheduler(appointmentList),"end","asc"),...response])
          if (_.size(selectedDock) && selectedDock[0].dockId !== '0000') {
            let dockselectedlist = appointmentList.filter((el) => el.IDDock === selectedDock[0].dockId)
            setApptList([..._.orderBy(formatDataForScheduler(dockselectedlist),"start","asc")])
          } else {
            
            setApptList([..._.orderBy(formatDataForScheduler(appointmentList),"start","asc")])
          }
        } else if (!appointmentList.length && !shouldRender) {
          setApptList([])
        }
      
    }, [appointmentList, whMetaDocksList])
  

  const onDockSelected = (data) => {
        if (data?.dockId && data?.dockId!=="0000") {
          setSelectedDock(() => whMetaDocksList.filter((el) => el.dockId === data.dockId))
          const filterData = whMetaDocksList.filter((el) => el.dockId === data.dockId)
          if(_.size(filterData)){
            
            setPlaceHolderDock(filterData[0].Name)
          }
        }
        else{
          setSelectedDock(null)
          setPlaceHolderDock("ALL")
        }
        let dockselectedlist=[]
        if (appointmentList && appointmentList.length) {
          if (data?.dockId==="0000") {
            dockselectedlist=[...appointmentList]
            setStartHour(data.dockStart)
            setEndHour(data.dockEnd)
            if (whBreaks) {
              setBreakList(whBreaks)
            }
        //     if (wpLeaveData.length) {
        // let response=formatLeaveDataForScheduler([...wpLeaveData],data.dockStart,data.dockEnd)
        //       setEventList([])
        //       setApptList([..._.orderBy(formatDataForScheduler(appointmentList),"end","asc"),...response])
              
        //     }
        //     else{
        //       setEventList([])
        //       setApptList([..._.orderBy(formatDataForScheduler(appointmentList),"end","asc")])
        //     }
            setEventList([])
            setApptList([..._.orderBy(formatDataForScheduler(appointmentList),"start","asc")])
          }
          else{
            setStartHour(data.dockStart)
            setEndHour(data.dockEnd)
            dockselectedlist=appointmentList.filter((el)=>el.IDDock===data.dockId)
            let dockselectedleavelist=[];
            let dockselectedbreakslist=[];
            dockselectedleavelist=whMetaLeavesList.filter((el)=>{
              if (el.IDDock===data.dockId && el.Status) {
                return el
              }
              })
              dockselectedbreakslist=dockBreaks.filter((el)=>{
                if (el.IDDock===data.dockId && el.Status) {
                  return el
                }
                })
              if (dockselectedbreakslist) {
                setBreakList(dockselectedbreakslist)
              }      
          // if (dockselectedlist.length || dockselectedleavelist.length) {  
             
          //   setEventList([])
          //   setApptList([..._.orderBy([...formatDataForScheduler(dockselectedlist),...formatLeaveDataForScheduler(dockselectedleavelist,data.dockStart,data.dockEnd)],"end","asc")])
          //   }
          // else{
          //   setEventList([])
          //     setApptList([])
          //   }
            if (dockselectedlist.length ) {  
             
              setEventList([])
              setApptList([..._.orderBy([...formatDataForScheduler(dockselectedlist)])])
              }
            else{
              setEventList([])
                setApptList([])
              }
        }
      }

      }

    // export const isBetween=(data,start,end)=>{
    //     if (data>=start && data<=end) {
    //         return true
    //     }
    //     else{
    //     return false;
    // }
    // }

  //   const isOverlap=(data,list) => {

  //     const overlapedEvents=[]
  //     let newlist=list.filter((el)=>el.event_id!==data.event_id)
  //     if (newlist.length) {

  //     for (let index = 0; index < newlist.length; index++) {
  //         const element = newlist[index];
  //         let startTime=element.start.getTime()
  //         let endTime=element.end.getTime()
          
  //         let currentStart=data.start.getTime()
  //         let currentEnd=data.end.getTime()
  //         if (isBetween(currentStart,startTime,endTime) || isBetween(currentEnd,startTime,endTime)) {
  //             // if (currentStart>=startTime) {
  //             //     overlapedEvents.push(data)
  //             // }
  //             // else{
  //             overlapedEvents.push(element)
  //         }
  //     //    }
          
  //     }
              
  //   }
  //     return overlapedEvents
  // }


//   //const eventList=[]
//   const genrateEventList=(data) => {

//     let overlapFixedEvents=[]
//   if (data.length) {
//       for (let index = 0; index < data.length; index++) {
//           const element = data[index];
//           if (element) {
//           let overlap=[];
//           let isResolved=overlapFixedEvents.filter((el)=>el.event_id===element.event_id)
//           if (!isResolved.length) {
//           overlap= isOverlap(element,data)
//           if (overlap.length>0) {
//                 let newlist=eventList;
//               let res={...element};
//               res.show_more=false;
//               let moreEvents=[];
//             //   let neweve=[]
//               for(let i=0;i<overlap.length;i++){
//                  //neweve=event.filter((el)=>el.event_id!=overlap[i].event_id)
//                 overlapFixedEvents.push(overlap[i])
//                 if (element.PORelationID!==overlap[i].PORelationID) {
//                     res.show_more=true;
//                     moreEvents.push(overlap[i])
//                 }
//                   //setEvent(neweve)
//               }
//               res.more_event_list=[...moreEvents]
//               newlist.push(res)
              
//               setEventList([..._.uniqBy(newlist,'event_id')])
            
//           }
//           else{
//             let newlist=eventList;
//               let res={...element};
//               res.show_more=false;
//               res.more_event_list=[]
//               newlist.push(res)
//               setEventList([..._.uniqBy(newlist,"event_id")])
//           }
                
//         }
//     }
       
//       }
//   }
//   else{

//     setEventList([])
//   }

// }


// Function to check if two appointments overlap
function doAppointmentsOverlap(appointment1, appointment2) {
  return (
      appointment1.start < appointment2.end &&
      appointment2.start < appointment1.end
  );
}

// Function to find overlapping appointments for each appointment
function findOverlappingGroups(appointments) {
  const overlappingGroups = [];

  for (let i = 0; i < appointments.length; i++) {
      const currentAppointment = appointments[i];
      let foundGroup = false;

      for (let j = 0; j < overlappingGroups.length; j++) {
          const group = overlappingGroups[j];

          for (const appointment of group) {
              if (doAppointmentsOverlap(currentAppointment, appointment)) {
                  group.push(currentAppointment);
                  foundGroup = true;
                  break;
              }
          }

          if (foundGroup) {
              break;
          }
      }

      if (!foundGroup) {
          overlappingGroups.push([currentAppointment]);
      }
  }

  return overlappingGroups;
}

  useEffect(() => {
  let tout;
  const stopLoading=()=>{
    tout = setTimeout(() => {
   setShouldRender(true)
 },2000)
}
  if (apptList.length) {
    
    // genrateEventList([...apptList])
    //new 

// Find overlapping groups
let appointmentListArray=[..._.orderBy(apptList,"start","asc")]
const overlappingGroups = findOverlappingGroups(appointmentListArray.filter((el)=>el.PoNumber===el.PORelationID));
    setEventList(overlappingGroups)
   stopLoading()
  }
  else{
    setEventList([])
    stopLoading()
  }

  return(()=>clearTimeout(tout))
},[apptList,startHour,endHour])


const getToggleSwitch = (value, index) => {
  return (
      <div className='d-flex justify-content-center align-items-center py-1'>
          <CommonToggleSwitch value={false}
        onChangeValue={(value) => {
          dispatch({
            type: sagaActions.UPDATE_DEFAULT_DOCK,
            payload: {
              CUID: cu_id,
                IDWarehouse: selectedWarehouse?.ID,
                DefaultIDDock: null,
                CRUDTYPE: 'U'
            }
          })
          setTimeout(() => {
            navigate('/schedulerweek')
          }, 0);
        }} 
              allowPermissionD={true}
              />
      </div>
  )
  }
  
   /** Function to update Default Dock */ 
  const onDefaultDockChange = (data, checked) => {
    if (_.size(selectedDock)) {
      dispatch({
        type: sagaActions.UPDATE_DEFAULT_DOCK,
        payload: {
          CUID: cu_id,
            IDWarehouse: selectedWarehouse?.ID,
            DefaultIDDock: selectedDock[0]?.ID,
            CRUDTYPE: 'I'
        }
      })
    }
  }

  const weekShiftHandler = (type) => {
    if (type === 'prev') {
      const newDate = addDaysToDate(selectedDate, -7)
      setSelectedDate(newDate)
    } else if (type === 'next') {
      const newDate = addDaysToDate(selectedDate, 7)
      setSelectedDate(newDate)
    }
  }

  return (
    <ScheduleProWrapper showAppointments={false} showNavBar={true}>
      <div className="my-scheduler-ss-wrapper p-3">
        <div className="rs__scheduler__wrapper">
          <div className="d-flex justify-content-between">
            <p className="f-16 fw-semibold ms-3"></p>

            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="pos-rel">
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="date-picker-btn h-100 ms-1"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    <span className="btn-title ">
                   
                      {selectedDate
                        ?`${format(new Date(selectedDate),"PPP")}`
                        : "Select Date"}
                    </span>
                    {showCalendar ? (
                      <span
                        className="font-default"
                        onClick={() => setShowCalendar(false)}
                      >
                        <i
                          className="fa-solid fa-xmark"
                          style={{ marginInline: "9px" }}
                        ></i>
                      </span>
                    ) : (
                      <span className="font-default">
                        <i
                          className="fas fa-calendar-week"
                          style={{ marginInline: "9px" }}
                        ></i>
                      </span>
                    )}
                  </button>
                  <div className='cursor-pointer ms-2' onClick={() => {weekShiftHandler('prev')}}>
                  <span title="Previous Week"> <i
                          className="fa-solid fa-angle-left fa-lg"
                          style={{ marginInline: "9px" }}
                        ></i></span>
                </div>
                  <div className='cursor-pointer' onClick={() => {weekShiftHandler('next')}}>
                  <span title="Next Week"> <i
                          className="fa-solid fa-angle-right fa-lg"
                          style={{ marginInline: "9px" }}
                        ></i></span>
                  </div>
                  <div className='cursor-pointer ms-2' onClick={() => {
                    setSelectedDate(new Date())
                  }}>
                    <span>Today</span>
                    <i
                          className="fas fa-calendar-day"
                          style={{ marginInline: "9px" }}
                        ></i>
                  </div>
              </div>
                  {showCalendar && (
                    <div
                      className="myappt-ss-datePicker"
                      style={{ zIndex: "999999999" }}
                    >
                      <DatePickerSS
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        setLoading={false}
                        closeCalendar={() => {
                          setShowCalendar(!showCalendar);
                        }}
                      />
                      
                    </div>
                  )}
              </div>
              <div className='d-flex justify-content-end gap-2'>
              <CustomButtonSS
                  disabled={false}
                  isLoading={false}
                  className={`carrier-po-btn-small`}
                  onClick={() => {navigate(0)}}
                  isIcon={true}
                  iconClass={"fa-solid fa-rotate-right"}
                />

                <CustomButtonSS
                  isLoading={false}
                  title={
                    AppConstant.smartScheduler.searchAppointments.newApptBtnText
                  }
                  className={"btn-highlighted f-12 me-3"}
                  onClick={() => {
                    setShowScheduleModal(true)
                  }}
                  isIcon={true}
                  iconClass={"fa-solid fa-plus fa-lg color-white"}
                />
             
              <div className='d-flex align-items-center'>
              <p className="me-2 mb-1 fw-600 f-14">Weekly Calendar View</p>
                {getToggleSwitch()}
                </div>
              </div>
              </div>
          </div>
          <div className="table-ss p-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
              
                {shouldRender ? <div
                  className="d-flex flex-row fs-6 align-items-center ms-1"
                  style={{ zIndex: "998" }}
                >
                  <p className="f-12">Select a Dock &nbsp; </p>{" "}
                  <CustomDropDownSS
                    themeSelected={props.themeSelected}
                    data={dockList}
                    placeHolder={placeHolderDock || 'ALL'}
                    onSelect={onDockSelected}
                    keyStr={"dockName"}
                  />
                </div> :''}
                {dockList && selectedDock && _.size(dockList) && shouldRender && selectedDock?.dockName !== 'ALL' ? <div className='ms-2'>
          {
            <div className='mt-2'>
              <div className="default-dock f-12">
              <label htmlFor="defaultDock">
                <span className="btn_title">{AppConstant.commonStrings.defaultDock}</span>
                </label>{" "}
                <div className='ms-2'>
                <input
                    type='checkbox'
                    value={defaultDockValueState}
                    name='defaultDock'
                  className={'customTextInputError'}
                  checked={defaultDockValueState ? true : false }
                    // onBlur={(data) => props.handleErrors(data.target.name)}
                    onInput={(data) => onDefaultDockChange(data.target.value, data.target.checked)}
                    // disabled={disabled || ''}
                />
                  </div>
            </div>
            </div>
          }
        </div> : ''}
              </div>
              <div className="d-flex gap-1">
                <div className="d-flex gap-2 me-4">
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code__schedule" />
                    Scheduled
                  </div>
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code__reschedule" />
                    Rescheduled
                  </div>
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code_completed" />
                    Completed
                  </div>
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code__Leaves" />
                    Holiday
                  </div>
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code_closed" />
                    Closed
                  </div>
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code__break" />
                    Breaks
                  </div>
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code_po_inbound" />
                    Inbound
                  </div>
                  <div className="f-12 fw-600 d-flex align-items-center gap-2">
                    <div className="code_po_outbound" />
                    Outbound
                  </div>
                </div>
              </div>
            </div>
            {shouldRender ? (
              <Week
                dateSelected={selectedDate}
                // events={[..._.uniqBy(eventList, "event_id")]}
                events={eventList}
                startHour={0}
                endHour={24}
                selectedDock={selectedDock}
                breakList={formatBreaksForScheduler(breakList)}
                showScheduleModal={showScheduleModal}
                setShowScheduleModal={setShowScheduleModal}
                leaveList={whMetaLeavesList}
              />
            ):
            <div className="w-100 p-3">{displayLoader('light-mode', 600, 1)}</div>
            }
            {/* <div className="d-flex gap-3 mx-3">
              <div className="f-12 fw-600 d-flex align-items-center gap-2">
                <div className="code__schedule" />
                Scheduled
              </div>
              <div className="f-12 fw-600 d-flex align-items-center gap-2">
                <div className="code__reschedule" />
                Rescheduled
              </div>
              <div className="f-12 fw-600 d-flex align-items-center gap-2">
                <div className="code_closed" />
                Closed
              </div>
              <div className="f-12 fw-600 d-flex align-items-center gap-2">
                <div className="code__break" />
                Breaks
              </div>
            </div> */}
          </div>
        </div>
        {showScheduleModal && searchedPOData.length===0 &&
       <ModalSS toggleModal={()=>{
        setShowScheduleModal(!showScheduleModal);
        setPo_number("")
        }}>
          <div className='p-2' style={{width:"400px",height:"auto"}}>
                                    <SSAppointmentSearch toggleIsSearched={toggleIsSearched}
                                        {...props} searchAppointment={() => displayAppointmentsList()}
                                        onEmailChange={(e, type) => onEmailChange(e, type)} initateLoading={initateLoading}
                                        scheduleScreenType={scheduleScreenType} setScheduleScreenType={setScheduleScreenType}
                                        showPdfHelperPopup={() => setShowHelpModal(true)}
                                        userType={userType} po_number={po_number} receivingZipcode={receivingZipcode} searchedPOErrorData={searchedPOErrorData}
                                        showHighLight={false}
                                    />
                                </div>
      </ModalSS>
      }
      </div>
    </ScheduleProWrapper>
  );
}

export default SchedulerNewSS