import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    initateLoading: false,
    intiateAppointmentLoading: false,
    initiateMultiplePO: false,
    arrangeOrder: 'asc',
    arrangeKey: 'CreatedOn',
    searchedPOData: [],
    searchedPOErrorData: null,
    poForAppt: [],
    appointmentList: [],
    wpSchedulerData: [],
    wpCapacityData: [],
    wpLeaveData: [],
    wpBreakData: [],
    ssRecommendationsArr: [],
    carrierList: [],    
    validationType: 'DOCK', 
    guardSearchPoData:[],
    appointmentConfirmation: false,
    driverAppointData:[],
    darrangeOrder: 'desc',
    darrangeKey: 'Appointment',
    appointmentTrackerData: null,
    appointmentTrackerDataError: null,
    appointmentListByDate: [],
    selectedDateForApptUpdates: new Date(),
    isDownloadLoading:false,
    appointmentReportSummary: null,
    cityData: null,
    appointmentHistory: [],
    mcDetails:null,
    startDate:new Date().setDate(new Date().getDate()-7),
    endDate: new Date(),
    defaultDockValue: null,
    dateValidationResponse: null,
    addPOResponse:null,
    appointmentDocumentsList: [],
    commanDownloadMessage: null,
    isActiveCommanDownloadPopup: false,
    deleteApptResponse: null,
    vehicleAndLoadType:null,
}

export const SmartScheduleReducerSlice = createSlice({
    name: 'smartScheduleReducer',
    initialState,
    reducers: {
        intiateSSLoader: (state, action) => {
            state.initateLoading = action.payload;
        },
        intiateSSAppointmentLoader: (state, action) => {
            state.intiateAppointmentLoading = action.payload;
        },
        initiateMultiplePOLoader: (state, action) => {
            state.initiateMultiplePO = action.payload;
        },
        getSSSearchedPO: (state, action) => {
            state.searchedPOData = action.payload;
            state.searchedPOErrorData = null;
        },
        updateSearchedPOError: (state, action) => {
            state.searchedPOErrorData = action.payload;
        },
        selectedPOforAppt:  (state, action) => {
            state.poForAppt = action.payload;
        },
        clearSSSearchedPO: (state, action) => {
            state.searchedPOData = [];
        },
        addAppointment: (state, action) => {
            state.appointmentList = action.payload;
        },
        addAppointmentConfirmation: (state, action) => {
            state.appointmentConfirmation = action.payload
        },
        getWPSchedulerData: (state, action) => { 
            state.wpSchedulerData = action.payload;
        },
        wpCapacityData: (state, action) => {
            state.wpCapacityData = action.payload;
        },
        wpLeaveData: (state, action) => {
            state.wpLeaveData = action.payload;
        },
        wpBreakData: (state, action) => {
            state.wpBreakData = action.payload;
        },
        updateSSRecommendationsArr: (state, action) => {
            state.ssRecommendationsArr = action.payload;
        },
        updateCarrierList: (state, action) => {
            state.carrierList = action.payload;
        },
        clearAppointment: (state) => {
            state.appointmentList = [];
        },
        getDriverearchedPO: (state, action) => {
            state.guardSearchPoData = action.payload;
        },
        getDriverPOAppoint: (state, action) => {
            state.driverAppointData = action.payload;
        },
        sortDriverAppointTableData: (state, action) => {
            const { drillDownReport, arrange, key } = action.payload;
            state.driverAppointData = drillDownReport
            state.darrangeKey = arrange.key
            state.darrangeOrder = arrange.order
        },
        getAppointmentTrackerDetails: (state, action) => {
            state.appointmentTrackerData = action.payload
        },
        updateAppointmentTrackerDetailsError: (state, action) => {
            state.appointmentTrackerDataError = action.payload
        },
        addAppointmentByDate: (state, action) => {
            state.appointmentListByDate = action.payload;
        },
        addSelectedDateForApptUpdate: (state, action) => {
            state.selectedDateForApptUpdates = action.payload;
        },
        updateIsDownloadLoading: (state, action) => {
            state.isDownloadLoading = action.payload;
        },
        updateCommanDownloadMessage: (state, action) => {
            state.commanDownloadMessage = action.payload;
        },
        updateIsActiveCommanDownloadPopup: (state, action) => {
            state.isActiveCommanDownloadPopup = action.payload;
        },
        resetDataToInitial: (state, action) => {
            state.initateLoading = false;
            state.intiateAppointmentLoading = false;
            state.initiateMultiplePO = false;
            state.arrangeOrder = 'asc';
            state.arrangeKey = 'CreatedOn';
            state.searchedPOData = [];
            state.searchedPOErrorData = null;
            state.poForAppt = [];
            state.appointmentList = [];
            state.wpSchedulerData = [];
            state.wpCapacityData = [];
            state.wpLeaveData = [];
            state.wpBreakData = [];
            state.ssRecommendationsArr = [];
            state.carrierList = [];
            state.validationType = 'DOCK';
            state.guardSearchPoData =[];
            state.appointmentConfirmation = false;
            state.driverAppointData =[];
            state.darrangeOrder = 'desc';
            state.darrangeKey = 'Appointment';
            state.appointmentTrackerData = null;
            state.appointmentTrackerDataError = null;
            state.appointmentListByDate = [];
            state.selectedDateForApptUpdates = new Date();
            state.isDownloadLoading = false;
            state.cityData = null;
            state.defaultDockValue = null;
            state.appointmentDocumentsList = [];
            state.commanDownloadMessage = null;
            state.isActiveCommanDownloadPopup = false
                },
        updateAppointmentReportSummary: (state, action) => {
            state.appointmentReportSummary = action.payload;
        },
        updateCityWithZipcode: (state, action) => {
            state.cityData = action.payload;
        },
        updateAppointmentHistory: (state, action) => {
            state.appointmentHistory = action.payload;
        },
        updateStartDate:(state,action) => {
            state.startDate = action.payload;
        },
        updateEndDate:(state,action) => {
            state.endDate = action.payload;
        },
        updateDefaultDockValue: (state, action) => {
            state.defaultDockValue = action.payload;
        },
        updateMCDetails:(state,action) => {
            state.mcDetails = action.payload;
        },
        updateDateValidationResponse:(state,action) => {
            state.dateValidationResponse = action.payload;
        },
        updateAddPOResponse:(state,action) => {
            state.addPOResponse = action.payload;
        },
        updateAppointmentDocuments:(state,action) => {
            state.appointmentDocumentsList = action.payload;
        },
        clearAppointmentDocuments:(state,action) => {
            state.appointmentDocumentsList = [];
        },
        updateDeleteApptResponse:(state,action)=>{
            state.deleteApptResponse=action.payload;
        },
        updateVehicleAndLoadType:(state,action)=>{
            state.vehicleAndLoadType=action.payload;
        },
    }
});

export const { getSSSearchedPO, intiateSSLoader, selectedPOforAppt,
    clearSSSearchedPO, addAppointment, clearAppointment, getWPSchedulerData,
    wpCapacityData, wpLeaveData, wpBreakData, updateSSRecommendationsArr, updateCarrierList,getDriverearchedPO, addAppointmentConfirmation,getDriverPOAppoint,sortDriverAppointTableData, intiateSSAppointmentLoader, initiateMultiplePOLoader, getAppointmentTrackerDetails, updateAppointmentTrackerDetailsError, addAppointmentByDate, updateSearchedPOError,addSelectedDateForApptUpdate, updateIsDownloadLoading, resetDataToInitial,
    updateAppointmentReportSummary, updateCityWithZipcode,updateAppointmentHistory, updateStartDate, updateEndDate, updateMCDetails, updateDefaultDockValue, updateDateValidationResponse, updateAppointmentDocuments, clearAppointmentDocuments ,updateAddPOResponse, updateCommanDownloadMessage, updateIsActiveCommanDownloadPopup,updateDeleteApptResponse,updateVehicleAndLoadType} = SmartScheduleReducerSlice.actions;

// Selectors
export const smartScheduleSelector = (state) => {
    return state.smartScheduleReducer;
};


export default SmartScheduleReducerSlice.reducer;