import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import DrilldownTable from '../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../../Common/CustomButton/CustomButton';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { changeFormatWithoutTz, initiateSearch, showAlert } from '../../../../../../Assests/Utility';
import { getCurrentDate, getFormattedDate } from '../../../../../../../Utils/DateUtils';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import moment from 'moment';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import EditIcon from '../../../../../../Assests/Images/edit-icon-new.png'
import { toast } from 'react-toastify';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';

const DockTypeList = (props) => {

    const { themeSelected, editGroupAction, searchStr, allowPermissionE, allowPermissionD, editDockTypeAction, whObj, currentFilterSelected } = props;
    const dispatch = useDispatch();
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { dockTypeList, operationObj, arrangeOrder, arrangeKey } = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [dockTypeListArr, setDockTypeListArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const dockTypeDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'DockType', name: AppConstant.smartScheduler.docks.dockType },
        { id: 'Created On', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    useEffect(() => {
        setListLoading(true);
        setDockTypeListArr([])
    }, [])

    useEffect(() => {

        setDockTypeListArr(dockTypeList)
    }, [dockTypeList])

    useEffect(() => {
        if (searchStr.length > 1) {
            const searchedArr = initiateSearch(dockTypeList, searchStr, 'GroupName');
            setDockTypeListArr(searchedArr);
        } else if (searchStr === '') {
            setDockTypeListArr(dockTypeList);
        }
    }, [searchStr])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Fetch dock type':
                    setListLoading(false)
                    if (operationObj.status) {
                        break;
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.updateDockStatusFailure);
                    }
                    break;
                case 'Delete dock Type':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.docks.deleteDockTypeSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.deleteDockTypeFailure);
                    }
                    break;
                case 'Update dock type':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.docks.updateDockTypeSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.updateDockTypeFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])


    const handleApiResponse = (result, message) => {
        setListLoading(false);
        setCurrentItemIndex('NA');
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchDockTypeList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }

    }

    const fetchDockTypeList = () => {
        const apiParams = {
            "IDWarehouse": selectedWarehouse.ID,
            "DockType": null,
            "CRUDTYPE": "S",
            "CreatedDate": null,
            "CreatedBy": null,
            "UpdatedDate": null,
            "UpdatedBy": null,
            "Status": null,
            "Deleted": null
        }
        const operationObj = {
            type: 'Fetch dock type',
            status: '',
        }

        dispatch({ type: sagaActions.DOCK_TYPE_LIST_ACTION, payload: apiParams, operationObj: operationObj });
    }
    const disableDockTypeAction = (data, index) => {
        setCurrentItemIndex(index);
        const apiParams = {
            "ID": data.ID,
            "IDWarehouse": data.IDWarehouse,
            "DockType": data.DockType,
            "CRUDTYPE": "U",
            "CreatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "CreatedBy": cu_id,
            "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "UpdatedBy": cu_id,
            "Status": !data.Status,
            "Deleted": false
        }

        const operationObj = {
            type: 'Update dock type',
            status: ''
        }

        dispatch({ type: sagaActions.DOCK_TYPE_LIST_ACTION, payload: apiParams, operationObj: operationObj });
    }
    const deleteHandler = (data, index) => {
        setSelectedObj(data)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else {
            setCurrentDeleteIndex(selectedIndex)

            const apiParams = {
                "ID": selectedObj.ID,
                "IDWarehouse": selectedObj.IDWarehouse,
                "DockType": selectedObj.DockType,
                "CRUDTYPE": "D",
                "CreatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "CreatedBy": cu_id,
                "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "UpdatedBy": cu_id,
                "Status": false,
                "Deleted": true
            }

            const operationObj = {
                type: 'Delete dock Type',
                status: ''
            }

            dispatch({ type: sagaActions.DOCK_TYPE_LIST_ACTION, payload: apiParams, operationObj: operationObj });
        }
        setShowDeleteModel(false)
    }
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.docks.deleteDockTypeTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} delete ${AppConstant.commonStrings.dockType} ?`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex align-items-center justify-content-center'>
                    <img src={EditIcon} alt='edit' className='editIconPO-ss'
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editDockTypeAction(groupObj)}
                    />
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => {
                            deleteHandler(groupObj, index)
                        }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                    <CustomButtonSS
                        isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                        title={`${!groupObj.Status ? AppConstant.commonStrings.enable : AppConstant.commonStrings.disable}`}
                        className={`${!groupObj.Status ? ' btnEnable-ss ' : 'btnDisable-ss'} mx-2`}
                        onClick={() => {
                            disableDockTypeAction(groupObj, index)
                        }}
                    />
                </span>
            </div>
        )
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: index,
                0: index + 1,
                1: el.DockType,
                2: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                3: actionsButton(el, index),
            }

        })
    }

    return (
        <>
            {dockTypeListArr &&
                <DrilldownTable
                    themeSelected={themeSelected}
                    bodyData={dockTypeListArr.length && formatListDataDrillDown(dockTypeListArr)}
                    titleData={dockTypeDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={dockTypeListArr}
                    initiateDrillDownReport={isListLoading}
                    loaderArray={4}
                    // sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
                    subRoot={{ pathName: 'dockTypeArr' }}
                    showUTCTime={false}
                />}
            {showDeleteConfirmation()}
        </>
    )
}

export default DockTypeList;