import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import { ReactComponent as NoDataLightMode } from '../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../Assests/Svg/NoDataDarkMode.svg';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import './WhMetaDataMainScreen.css';
import ScheduleProWrapper from '../../../SmartSchedule/SchedulePro/ScheduleProWrapper';
import DailyOperationsSS from './Forms/DailyOperations/DailyOperationsSS';
import DocksSS from './Forms/DocksMeta/DocksSS';
import DockDoorsFormSS from './Forms/DockDoorsForm/DockDoorsFormSS';
import LeavesMetaDataFormSS from './Forms/LeavesForm/LeavesFormSS';
import BreaksMetaDataFormSS from './Forms/BreaksForm/BreaksFormSS';
import { shouldHavePermission } from '../../../../Assests/Utility';
import DockType from './Forms/DockType/DockType';

const WhMetaDataMainScreenSS = (props) => {
    const { themeSelected } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedButton, setSelectedButton] = useState('Docks');
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, permissions } = user;
    const [allowPermissionDock, setAllowPermissionDock] = useState(false);
    const [allowPermissionDockTimeslot, setAllowPermissionDockTimeslot] = useState(false);
    const [allowPermissionFcClosure, setAllowPermissionFcClosure] = useState(false);
    const [allowPermissionBreaks, setAllowPermissionBreaks] = useState(false);

    useEffect(() => {
        if (permissions.length) {
          let tempDock = shouldHavePermission(permissions, '171')
          setAllowPermissionDock(tempDock);
          let tempDockTimeslot = shouldHavePermission(permissions, '172')
          setAllowPermissionDockTimeslot(tempDockTimeslot);
          let tempFcClosure = shouldHavePermission(permissions, '173')
          setAllowPermissionFcClosure(tempFcClosure);
          let tempBreaks = shouldHavePermission(permissions, '174')
          setAllowPermissionBreaks(tempBreaks);
        }
      }, [])
    

    useEffect(() => {
        showCurrentReport()
    }, [selectedButton])

    const showTilesList = () => {
        return (

            <div
                className="d-flex justify-content-start"
                style={{ borderRadius: "10px" }}
            >
                {/* <button
                    onClick={() => {
                        setSelectedButton('DailyOperations')
                    }}
                    className={selectedButton === 'DailyOperations' ? "selected-button" : "unselected-button"}>
                    {'Schedule Admin'}
                </button> */}
                <button
                    onClick={() => { setSelectedButton('Docks') }}
                    className={selectedButton === 'Docks' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionDock ? '' : 'disabled'}
                >
                    {'Docks'}
                </button>
                <button
                    onClick={() => { setSelectedButton('TimeSlot') }}
                    className={selectedButton === 'TimeSlot' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionDockTimeslot ? '' : 'disabled'}
                >
                    {'Docks & Timeslot'}
                </button>
                <button
                    onClick={() => { setSelectedButton('Leaves') }}
                    className={selectedButton === 'Leaves' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionFcClosure ? '' : 'disabled'}
                >
                    {'Facility Closures'}
                </button>
                <button
                    onClick={() => { setSelectedButton('Breaks') }}
                    className={selectedButton === 'Breaks' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionBreaks ? '' : 'disabled'}
                >
                    {'Breaks'}
                </button>
                <button
                    onClick={() => { setSelectedButton('DockType') }}
                    className={selectedButton === 'DockType' ? "selected-button" : "unselected-button"}
                    disabled={true ? '' : 'disabled'}
                >
                    {'Dock Type'}
                </button>
            </div>


        )
    }

    const showNoDataError = () => {
        return (
            <div className=' d-flex justify-content-center'>
                <div className='flex-column my-5'>
                    <div className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</div>
                    <div className={`no-data-text1 ${themeSelected} graphHeading text-center`}>{AppConstant.reports.selectReportTitleString}</div>
                </div>
            </div>
        )
    }

    const showCurrentReport = () => {
        const whObjTemp = selectedWarehouse && selectedWarehouse.ID ? selectedWarehouse : location && location.state && location.state.props;
        if ((whObjTemp && whObjTemp.ID)) {
            switch (selectedButton) {
                case "DailyOperations":
                    return (<DailyOperationsSS {...props} whObj={whObjTemp} />);
                case "Docks":
                    return (<DocksSS {...props} whObj={whObjTemp} />)
                case "TimeSlot":
                    return (<DockDoorsFormSS {...props} whObj={whObjTemp} />);
                case "Leaves":
                    return (<LeavesMetaDataFormSS {...props} whObj={whObjTemp} />)
                case "Breaks":
                    return (<BreaksMetaDataFormSS {...props} whObj={whObjTemp} />)
                case "DockType":
                    return (<DockType {...props} whObj={whObjTemp} />)
                default:
                    return showNoDataError();
            }
        } else {
            showNoDataError()
        }
    }

    return (
        <ScheduleProWrapper>
            <div>
                {/* <Header {...props} />
            <Navigationbar {...props} /> */}
                <div className=' warehouseMeta-wrapper-ss p-3'>
                    <div className="mb-4">
                        {showTilesList()}
                    </div>
                    {showCurrentReport()}
                </div>
            </div>
        </ScheduleProWrapper>
    )
}

export default WhMetaDataMainScreenSS;