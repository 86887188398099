import React from 'react';
import Header from '../../../../Header/Header';
import Navigationbar from '../../../../Navigationbar/Navigationbar';
import SproWarehouseAdminScreen from './Components/PoIntakeAssignmentAdminScreen/PoIntakeAssignmentAdminScreen';
import PoIntakeAssignmentAdminScreen from './Components/PoIntakeAssignmentAdminScreen/PoIntakeAssignmentAdminScreen';

/**
 * Used to show the action item on the nav bar and when user clicks on 
 * that it will take the user to the particular component
 * @param {*} props 
 * @returns 
 */
const PoIntakeAssignmentAdmin = (props) => {

    const { themeSelected } = props;

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <PoIntakeAssignmentAdminScreen className='mt-3' themeSelected={themeSelected} />
        </div>
    )
}
export default PoIntakeAssignmentAdmin