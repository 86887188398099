import React, { useEffect, useState } from 'react';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getAuthReducer } from '../../../../../../redux/reducers/reducerSlices/AuthReducer';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import DrilldownTable from '../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CommonToggleSwitch from '../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import { initiateSearch, formatContactNumNNN, showAlert } from '../../../../../Assests/Utility';
import _ from 'lodash';
import Popup from '../../../../Common/Popup/Popup';
import { toast } from 'react-toastify';


const UserTable = (props) => {
    const navigate = useNavigate();
    const { themeSelected, allowPermissionA, allowPermissionD, editUserAction, searchStr, allowPermissionR } = props;
    const [userListData, setUserListData] = useState([]);
    const [resenEmailLoader, setResenEmailLoader] = useState(false)
    const [resetUserPassLoader, setResetUserPassLoader] = useState(false)
    const user = useSelector(getUserSelector)
    const { initateLoading, userList, userType, arrangeOrder, arrangeKey, cu_id, parentWarehouse, resendEmail, resetUserPass } = user;
    const userAuth = useSelector(getAuthReducer);
    const { user_name } = userAuth;
    const dispatch = useDispatch()
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [selectedObj, setSelectedObj] = useState(null)

    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })
        setTimeout(() => {
            dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: createPayload() })
        },[0])
    }, [])

    useEffect(() => {
        if (userList.length) {
            initateLoading && dispatch({ type: sagaActions.INITIATE_LOADING, payload: false })
            if (searchStr) {
                const searchedArr = initiateSearch(userList, searchStr, 'UserName');
                setUserListData(searchedArr);
            } else {
                setUserListData(userList);
            }
            setSelectedObj(null)
        }
    }, [userList])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(userList, searchStr, 'UserName');
            setUserListData(searchedArr);
        } else if (searchStr === '') {
            setUserListData(userList)
        }
    }, [searchStr])

    useEffect(() => {
        if(resendEmail){
            setResenEmailLoader(false)
            dispatch({ type: sagaActions.UPDATE_RESEND_EMAIL, payload: false})
        }
    },[resendEmail])

    useEffect(() => {
        if(resetUserPass){
            setResetUserPassLoader(false)
            dispatch({ type: sagaActions.UPDATE_RESET_USER_PASSWORD, payload: false})
        }
    },[resetUserPass])

    const createPayload = () => {
        return {
            user_name: user_name, email: user_name, user_type: userType, enable_status: 0, cu_id:  null, crud_type: 'S', first_name: null, last_name: null, phone: null,
            p_cu_id: userType === AppConstant.userPermission.usertypes.superAdmin ? null : cu_id,
            warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID
        }
    }

    const updatePayload = (user) => {
        return {
            user_name: user.UserName, email: user.Email, user_type: user.UserType, enable_status: user.EnableStatus, cu_id: user.CUID, crud_type: 'U', first_name: user.FirstName, last_name: user.LastName, phone: user.Phone,
            p_cu_id: userType === AppConstant.userPermission.usertypes.superAdmin ? null : cu_id,
            warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID
        }
    }
    const warehouseTitleDD = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'UserName', name: 'Username' },
        { id: 'UserType', name: 'User Type' },
        { id: 'Email', name: 'Email' },
        { id: 'FirstName', name: 'First Name' },
        { id: 'LastName', name: 'Last Name' },
        { id: 'Phone', name: 'Phone Number' },
        { id: 'CreatedOn', name: 'Created on' },
        { id: 'LastLogon', name: 'Last Logon' },
        { id: 'status', name: 'Status' },
        { id: 'actions', name: 'Actions' },
    ]

    const clickHandler = (key, user) => {
        dispatch({ type: sagaActions.UPDATE_SELECTED_USER, payload: user })
        switch (key) {
            case AppConstant.commonStrings.edit:
                editUserAction(user)
                break;
            case AppConstant.commonStrings.disable:
                dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: { user_name: user.UserName,
                    p_cu_id: cu_id,
                    warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID,
                     crud_type: 'D' } })

                break;
            case AppConstant.commonStrings.assign:
                dispatch({ type: sagaActions.RESET_USER_ROLE_ASSIGN })
                navigate('/user/assign')
                break;
            case AppConstant.commonStrings.resendEmail:
                setResenEmailLoader(user.CUID)
                dispatch({ type: sagaActions.INITIATE_RESEND_EMAIL, payload: { username: user.Email } })
                break;
            case AppConstant.commonStrings.resetPassword:
                setResetUserPassLoader(user.CUID)
                dispatch({ type: sagaActions.INITIATE_RESET_USER_PASSWORD, payload: { username: user.Email } })
                break;
            case AppConstant.commonStrings.deleteUser:
                setSelectedObj(user)
                setShowDeleteModel(true)    
                break;
            default:
                break;
        }
    }

    const toggleStatus = (data,index) => {
        const response = _.find(userList, { 'UserID': data.UserID});
        let tempObj = {...response}
        if(!_.isEmpty(tempObj)){
             let tempArr = JSON.parse(JSON.stringify(userListData));
            tempObj.EnableStatus = !tempArr[index].EnableStatus;
        tempArr[index] = tempObj;
        setUserListData(tempArr)
        dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: updatePayload(tempObj) })
        }
       
    }


    const getToggleSwitch = (data, value, index) => {
        return (
            <div className='d-flex justify-content-center align-items-center py-1'>
                <CommonToggleSwitch value={value}
                    onChangeValue={(e) => toggleStatus(data,index)} allowPermissionD={allowPermissionD}/>
            </div>
        )

    }

    const actionsButton = (el) => {
        return (
            <div className='d-flex flex-row align-items-center'>
                <span className='px-1'>
                    <CustomButton
                        onClick={() => clickHandler(AppConstant.commonStrings.edit, el)}
                        title={AppConstant.commonStrings.edit}
                        className={allowPermissionA ? 'addButton' : 'addButton disabled'}
                        isLoading={false}
                        disabled={allowPermissionA ? '' : 'disabled'}
                        dataBsToggle="modal"
                        dataBsTarget={`#createUserFormPopup`}
                    >
                    </CustomButton>
                </span>
                <span className='px-1'>
                    <CustomButton
                        onClick={() => clickHandler(AppConstant.commonStrings.assign, el)}
                        title={AppConstant.commonStrings.assign}
                        className={allowPermissionD ? 'addButton' : 'addButton disabled'}
                        isLoading={false}
                        disabled={allowPermissionD ? false : true}>
                    </CustomButton>
                </span>
                <span className='px-1'>
                    <CustomButton
                        onClick={() => clickHandler(AppConstant.commonStrings.resendEmail, el)}
                        title={AppConstant.commonStrings.resendEmail}
                        className={allowPermissionR ? 'addButton' : 'addButton disabled'}
                        isLoading={el.CUID === resenEmailLoader ? true: false}
                        disabled={allowPermissionR ? false : true}>
                    </CustomButton>
                </span>
                <span className='px-1'>
                    <CustomButton
                        onClick={() => clickHandler(AppConstant.commonStrings.resetPassword, el)}
                        title={AppConstant.commonStrings.resetPassword}
                        className={allowPermissionR ? 'addButton' : 'addButton disabled'}
                        isLoading={el.CUID === resetUserPassLoader ? true: false}
                        disabled={allowPermissionR ? false : true}>
                    </CustomButton>
                </span>
                <span className='px-1'>
                    <CustomButton
                        onClick={() => clickHandler(AppConstant.commonStrings.deleteUser, el)}
                        title={AppConstant.commonStrings.deleteUser}
                        className={true ? 'addButton' : 'addButton disabled'}
                        isLoading={el.CUID === selectedObj?.CUID ? true: false}
                        disabled={true ? false : true}>
                    </CustomButton>
                </span>
            </div>
        )
    }

       /**
     * delete popup handler to delete the records based on the confirmation
     * @param {*} text 
     */
const popupBtnHandler = (text) => {
    if (text === AppConstant.commonStrings.no) {
        setShowDeleteModel(false)
        setSelectedObj(null)
    }
    else {
        if(_.upperCase(cu_id) === _.upperCase(selectedObj?.CUID)){
            showAlert(toast.TYPE.ERROR, 'You cannnot delete yourself, contact DB team.', themeSelected)
            setShowDeleteModel(false)
            return 
        } else {
            let tempData = {
                user_name: selectedObj?.Email,
                crud_type: 'R',
                cu_id: selectedObj?.CUID,
                DeletedBy: cu_id
           }
                dispatch({ type: sagaActions.DELETE_USER, payload: tempData })
        }
    }
    setShowDeleteModel(false)
    }
    
     /**
     * Helper to show the delete conformation pop-up
     * @returns 
     */
     const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: `Delete user`,
            bodyText: `${AppConstant.commonStrings.areYouSure} delete user - ${selectedObj?.UserName} ? This would completely delete all user data and all access assigned to user.`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    const formatListData = (data) => {
        return data.map((el, index) => {
            return {
                id: `${el.UserID}_${el.LastName}`,
                0: el.serial_num,
                1: el.UserName || '-',
                2: el.UserType || '-',
                3: el.Email,
                4: el.FirstName || '-',
                5: el.LastName || '-',
                6: el.Phone ? formatContactNumNNN(el.Phone) : '-',
                7: el.CreatedOn,
                8: el.LastLogon || '-',
                9: getToggleSwitch(el, el.EnableStatus, index),
                10: actionsButton(el),
            }
        })
    }

    return (
        <>
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={userListData.length && formatListData(userListData)}
            titleData={warehouseTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={userList}
            initiateDrillDownReport={initateLoading}
            loaderArray={11}
            sortingAction={sagaActions.SORT_USER_TABLE}
            subRoot={{ pathName: 'user' }}
            showUTCTime={false}
            />
            {showDeleteConfirmation()}
            </>
    )
}

export default UserTable;