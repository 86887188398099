import React, { useState, useEffect, forwardRef, useRef } from 'react';
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDockListCommonFunc } from './Common/FetchDockList'
import { toast } from 'react-toastify';
import { changeFormatWithoutTz, showAlert } from '../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { changeDateToGivenFormat, getCurrentDate, getDockOperationalTime } from '../../../../../../../Utils/DateUtils';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import moment from 'moment';
import './Docks.css';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import WarehouseSearchSS from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import DockListSS from './DockList/DockListSS';
import { useNavigate } from 'react-router-dom';
import CustomFieldWithTtileSS from '../../../../../Common/CustomField/CustomFieldWithTtileSS';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { tooltip } from 'aws-amplify';
import CustomTooltip from '../../../../../Common/Tooltip/CustomTooltip';

/**
 * Initial Error state
 */
const initialErrors = {
    DockName: '',
    PPH: '',
    CPH: '',
    TPH: '',
    operational_days: '',
    StartTime: '',
    EndTime: '',
    CapacityModeling: '',
    BlockHours: '',
    appointmentLifeTime: '',
    appointmentLeadTime: '',
    ApptFrequency: ''
}

const DocksSS = (props) => {
    const { themeSelected, editGroupAction, allowPermissionE = true, allowPermissionD, whObj } = props;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [searchStr, setSearchStr] = useState('');
    const [loading, setLoading] = useState(false);
    const [startTimeState, setStartTimeState] = useState('');
    const [endTimeState, setEndTimeState] = useState('');
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { operationObj, preCheckList, dockTypeList } = whMetaData;
    const user = useSelector(getUserSelector);
    const { cu_id, selectedWarehouse } = user;
    const [showPreCheckModal, setShowPreCheckModal] = useState(false);
    const firstUpdate = useRef(true);
    const navigate = useNavigate()
    const [unSlectedDays, setUnSelectedDays] = useState('');
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const [capacityModalObject, setCapacityModalObject] = useState({})
    const [dockListType, setDockListType] = useState(null)
    const { allConfigration } = remoteConfig;

    const [inputs, setInputs] = useState({
        DockName: "",
        PPH: "",
        CPH: "",
        TPH: "",
        operational_days: null,
        StartTime: "",
        EndTime: "",
        CapacityModeling: "",
        BlockHours: "",
        appointmentLifeTime: "",
        appointmentLeadTime: "",
        ApptFrequency: ""
    })

    const [errors, setErrors] = useState(initialErrors)

    const [dirty, setDirty] = useState({
        DockName: false,
        PPH: false,
        CPH: false,
        TPH: false,
        operational_days: false,
        StartTime: false,
        EndTime: false,
        CapacityModeling: false,
        BlockHours: false,
        appointmentLifeTime: false,
        appointmentLeadTime: false,
        ApptFrequency: false
    })

    const [operationalDays, setOperationalDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    })

    const fetchDockTypeList = () => {
        const apiParams = {
            "IDWarehouse": selectedWarehouse.ID,
            "DockType": null,
            "CRUDTYPE": "S",
            "CreatedDate": null,
            "CreatedBy": null,
            "UpdatedDate": null,
            "UpdatedBy": null,
            "Status": null,
            "Deleted": null
        }

        const operationObj = {
            type: 'Fetch dock type',
            status: '',
        }
        dispatch({ type: sagaActions.DOCK_TYPE_LIST_ACTION, payload: apiParams, operationObj: operationObj });

    }
    /**
     * useEffect to form capacity model object 
     */
    useEffect(() => {
        if (allConfigration && allConfigration.CapacityModels) {
            let obj = {};
            JSON.parse(allConfigration.CapacityModels).forEach((el) => {
                obj[el.name] = el.description
            })
            setCapacityModalObject(obj)
        }
        fetchDockTypeList()
    }, [allConfigration])

    useEffect(() => {
        if (dockTypeList.length) {
            let nArray = []
            dockTypeList.forEach((el, i) => {
                let objDockList = {};
                objDockList.dockName = el.DockType;
                objDockList.id = el.ID;
                nArray.push(objDockList)
            })
            setDockListType(nArray)
        } else {
            setDockListType([])
        }
    }, [dockTypeList])


    /**
     * Function to handle change in form 
     * @param {*} data 
     * @param {*} name 
     * @param {*} control 
     */
    let handleChange = (data, name) => {
        if (typeof data === 'object') {
            data = data.toString();
        }


        getFormFieldData && getFormFieldData(data, name);
        setInputs((inputs) => ({ ...inputs, [name]: data }));

    };

    /**
     * Function to get Number by week day name
     * @param {*} name 
     * @returns 
     */
    const getWeekDayNumber = (name) => {
        switch (name) {
            case 'sunday':
                return '1'
                break;
            case 'monday':
                return '2'
                break;
            case 'tuesday':
                return '3'
                break;
            case 'wednesday':
                return '4'
                break;
            case 'thursday':
                return '5'
                break;
            case 'friday':
                return '6'
                break;
            case 'saturday':
                return '7'
                break;
            default:
                break;
        }
    }

    /**
     * Function to handle change in operational days
     * @param {*} e 
     */
    let handleOperationalDays = (e) => {

        const { name, checked } = e.target;
        let obj = { ...inputs.operational_days, [name]: checked }
        if (!checked) {
            setUnSelectedDays((prev) => prev + ',' + getWeekDayNumber(name))
        }
        setOperationalDays((operationalDays) => ({ ...operationalDays, [name]: checked }))
        setInputs(inputs => ({ ...inputs, operational_days: obj }))
    }

    /**
     * Function to handle errors
     * @param {*} name 
     */
    const handleErrors = (name) => {
        setDirty((dirty) => ({ ...dirty, [name]: true }));
        validate(name);
    };

    /**
     * Function to validate form inputs
     * @param {*} name 
     * @returns 
     */
    const validate = (name) => {

        let valid = true
        let errorData = { ...initialErrors }
        if (!inputs.DockName) {
            errorData.DockName = AppConstant.help.required
            valid = false
        }
        if (inputs.CapacityModeling && inputs.CapacityModeling === 'TPH' && !inputs.TPH) {
            errorData.TPH = AppConstant.help.required
            valid = false
        }
        if (inputs.CapacityModeling && inputs.CapacityModeling === 'Productivity' && !inputs.CPH) {
            errorData.CPH = AppConstant.help.required
            valid = false
        }
        if (inputs.CapacityModeling && inputs.CapacityModeling === 'Productivity' && !inputs.PPH) {
            errorData.PPH = AppConstant.help.required
            valid = false
        }
        if (!inputs.StartTime) {
            errorData.StartTime = AppConstant.help.required
            valid = false
        }
        if (!inputs.EndTime) {
            errorData.EndTime = AppConstant.help.required
            valid = false
        }
        if (!inputs.CapacityModeling) {
            errorData.CapacityModeling = AppConstant.help.required
            valid = false
        }
        if (!inputs.operational_days || !validateOperationalDays(inputs?.operational_days)) {
            errorData.operational_days = AppConstant.help.required
            valid = false
        }
        if (inputs.BlockHours) {
            let maxBlockMin = allConfigration.MaxBlockHours ? (allConfigration.MaxBlockHours * 60) : (30 * 60);
            if (inputs.BlockHours < 0) {
                errorData.BlockHours = "Block Time should be grater than 0";
                valid = false
            } else if (inputs.BlockHours > maxBlockMin) {
                errorData.BlockHours = `Block Time should be less than ${maxBlockMin} min (${maxBlockMin / 60}hr)`;
                valid = false
            }

        }
        if (inputs.appointmentLifeTime) {
            let maxAppointmentLifeTime = allConfigration.MaxAppointmentLifeTime ? (allConfigration.MaxAppointmentLifeTime) : (720);
            if (inputs.appointmentLifeTime < 0) {
                errorData.appointmentLifeTime = "Appointment Life Time should be grater than 0";
                valid = false
            } else if (inputs.appointmentLifeTime > maxAppointmentLifeTime) {
                errorData.appointmentLifeTime = `Appointment Life Time should be less than ${maxAppointmentLifeTime} hr`;
                valid = false
            }

        }
        if (inputs.appointmentLeadTime) {
            let maxAppointmentLeadTime = allConfigration.MaxAppointmentLeadTime ? (allConfigration.MaxAppointmentLeadTime) : (120);
            if (parseInt(inputs.appointmentLeadTime) < 0) {
                errorData.appointmentLeadTime = "Appointment Lead Time should be grater than 0";
                valid = false
            } else if (parseInt(inputs.appointmentLeadTime) > parseInt(maxAppointmentLeadTime)) {
                errorData.appointmentLeadTime = `Appointment Lead Time should be less than ${maxAppointmentLeadTime} hr`;
                valid = false
            }

        }
        if (!inputs.ApptFrequency) {
            errorData.ApptFrequency = AppConstant.help.required
            valid = false
        }
        setErrors(errorData)
        return valid
    }

    // const preferedDocksList = dockListType.sort((a, b) => a.dockName.localeCompare(b.dockName));

    useEffect(() => {
        dispatch({ type: sagaActions.UPDATE_PRE_CHECK_LIST, payload: [] })
        setLoading(false);
        setShowModal(false);
    }, [])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (preCheckList.length > 0) {
            setShowModal(false)
            setLoading(false);
            setShowPreCheckModal(true)
        }
    }, [preCheckList])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Create Dock':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.docks.createDockSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.createDockFailure);
                    }
                    break;
                case 'Edit Dock':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.docks.updateDockStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.docks.updateDockFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            setShowModal(false)
            fetchDockList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setLoading(false)
        setUnSelectedDays("")
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    const fetchDockList = () => {
        const { apiParams, operationObj } = fetchDockListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const TUSDatePickerInput = forwardRef(({ value, titleField, onClick }, ref) => {
        if (titleField === 'Start') {
            return (
                <button className="customDatePickerInput-ss" onClick={onClick} ref={ref}>
                    {particularFieldValue && particularFieldValue.StartTime ? particularFieldValue.StartTime : 'HH:MM'}
                </button>
            )
        } else if (titleField === 'End') {
            return (
                <button className="customDatePickerInput-ss" onClick={onClick} ref={ref}>
                    {particularFieldValue && particularFieldValue.EndTime ? particularFieldValue.EndTime : 'HH:MM'}
                </button>
            )
        }
    })

    const filterPassedTimeValue = (time) => {
        return moment(startTimeState, "HH:mm").isBefore(moment(time, "HH:mm"))
    };

    const editDock = (data) => {

        const editData1 = {
            "DockName": data.Name,
            "PPH": data.PPH,
            "TPH": data?.TPH,
            "CPH": data?.CPH,
            "DockStatus": data.DockStatus,
            "ID": data.ID,
            "CRUDTYPE": "U",
            "IDWarehouse": data.IDWarehouse,
            "StartTime": changeFormatWithoutTz(data.StartTime, 'HH:mm'),
            "EndTime": changeFormatWithoutTz(data.EndTime, 'HH:mm'),
            "CreatedOn": data.CreatedDate,
            "operational_days": {
                sunday: data.sunday,
                monday: data.monday,
                tuesday: data.tuesday,
                wednesday: data.wednesday,
                thursday: data.thursday,
                friday: data.friday,
                saturday: data.saturday
            },
            "BlockHours": data?.BlockHours,
            "CapacityModeling": data?.CapacityModeling,
            "appointmentLifeTime": data?.ApptLifeTime,
            "appointmentLeadTime": data?.ApptLeadTime,
            "ApptFrequency": data?.ApptFrequency
        }
        setShowModal(true);
        setEditData(editData1);
        setInputs(editData1)
        setStartTimeState(changeFormatWithoutTz(data.StartTime, 'HH:mm'));
        setEndTimeState(changeFormatWithoutTz(data.EndTime, 'HH:mm'));
        setParticularFieldValue({
            ...editData1,
            'StartTime': changeFormatWithoutTz(data.StartTime, 'HH:mm'),
            'EndTime': changeFormatWithoutTz(data.EndTime, 'HH:mm')
        });
    }

    const getDockList = () => {
        return dockListType.map((ele, index) => {
            return (
                <option key={ele.id} value={ele.dockName}>
                    {ele.dockName}
                </option>
            );
        })
    }
    

    const getDockApptFrequency = () => {
        if (allConfigration && allConfigration.ApptFrequency) {
            return JSON.parse(allConfigration.ApptFrequency).map((ele, index) => {
                return (
                    <option key={ele} value={ele}>
                        {ele}
                    </option>
                );
            })
        }
    }

    const getFormFieldData = (data, name) => {
        if (name === 'StartTime') {
            setInputs({ ...inputs, StartTime: changeDateToGivenFormat(data, 'HH:mm') })
            setStartTimeState(changeDateToGivenFormat(data, 'HH:mm'));
            setEndTimeState('');
            setParticularFieldValue({
                // ...particularFieldValue,
                'StartTime': changeDateToGivenFormat(data, 'HH:mm'),
                'EndTime': particularFieldValue.EndTime ? particularFieldValue.EndTime : ""
            });
        } else if (name === 'EndTime') {
            setInputs({ ...inputs, EndTime: changeDateToGivenFormat(data, 'HH:mm') })
            setParticularFieldValue({
                ...particularFieldValue,
                'EndTime': changeDateToGivenFormat(data, 'HH:mm')
            });
            setEndTimeState(changeDateToGivenFormat(data, 'HH:mm'));
        }
    };

    /**
     * Function to render option for Capacity model select dropdown
     * @param {*} data 
     * @returns JSX 
     */
    const getCapacityModelingList = (data) => {
        return data?.map((el) => {
            return (
                <option key={el.name} value={el.name}>
                    {`${el.name} (${el.description})`}
                </option>
            )
        })
    }

    /**
     * Function to render form
     * @returns 
     */
    const createForm = () => {
        return (
            <div className='d-flex flex-column gap-2'>
                <CustomFieldWithTtileSS
                    type={"select"}
                    showError={
                        dirty["DockName"] && errors["DockName"][0]
                            ? errors["DockName"]
                            : ""
                    }
                    name={"DockName"}
                    value={inputs["DockName"] ? inputs["DockName"] : ""}
                    handleErrors={handleErrors}
                    title={"Dock Type"}
                    required={true}
                    onChange={(data, name) => handleChange(data, name)}
                    listData={getDockList}
                    disabledField={Object.keys(editData).length ? true : false}
                />
                <CustomFieldWithTtileSS
                    type={'select'}
                    showError={dirty["CapacityModeling"] && errors["CapacityModeling"] ? errors["CapacityModeling"] : ""}
                    name='CapacityModeling'
                    listData={() => allConfigration && allConfigration.CapacityModels && getCapacityModelingList(JSON.parse(allConfigration.CapacityModels))}
                    value={inputs.CapacityModeling ? inputs.CapacityModeling : ""}
                    handleErrors={handleErrors}
                    title='Capacity Model'
                    required={true}
                    onChange={(data, name) => handleChange(data, name)}
                    className={"f-14"}
                />
                {inputs.CapacityModeling && inputs.CapacityModeling === 'TPH' &&
                    <CustomFieldWithTtileSS
                        type={"number"}
                        showError={dirty["TPH"] && errors["TPH"][0] ? errors["TPH"] : ""}
                        name={"TPH"}
                        value={inputs["TPH"] ? inputs["TPH"] : ""}
                        handleErrors={handleErrors}
                        title={capacityModalObject[inputs.CapacityModeling]}
                        required={true}
                        onChange={(data, name) => handleChange(data, name)}
                    />
                }
                {inputs.CapacityModeling && inputs.CapacityModeling === 'Productivity' &&
                    <>
                        <CustomFieldWithTtileSS
                            type={"number"}
                            showError={dirty["CPH"] && errors["CPH"][0] ? errors["CPH"] : ""}
                            name={"CPH"}
                            value={inputs["CPH"] ? inputs["CPH"] : ""}
                            handleErrors={handleErrors}
                            title={'Cases Per Hour'}
                            required={true}
                            onChange={(data, name) => handleChange(data, name)}
                        />
                        <CustomFieldWithTtileSS
                            type={"number"}
                            showError={dirty["PPH"] && errors["PPH"][0] ? errors["PPH"] : ""}
                            name={"PPH"}
                            value={inputs["PPH"] ? inputs["PPH"] : ""}
                            handleErrors={handleErrors}
                            title={'Pallets Per Hour'}
                            required={true}
                            onChange={(data, name) => handleChange(data, name)}
                        />
                    </>
                }
                <div className='checkbox-group'>
                    <label>Operational days <span className='color-red'>*</span></label>
                    <div className='flex-wrap'>
                        <div className='d-flex gap-1'>
                            <input type='checkbox' name='sunday' value={true} onChange={handleOperationalDays} checked={inputs?.operational_days?.sunday} />
                            <span>Sunday</span>
                        </div>
                        <div className='d-flex gap-1'>
                            <input type='checkbox' name='monday' value={true} onChange={handleOperationalDays} checked={inputs?.operational_days?.monday} />
                            <span>Monday</span>
                        </div>
                        <div className='d-flex gap-1'>
                            <input type='checkbox' name='tuesday' value={true} onChange={handleOperationalDays} checked={inputs?.operational_days?.tuesday} />
                            <span>Tuesday</span>
                        </div>
                        <div className='d-flex gap-1'>
                            <input type='checkbox' name='wednesday' value={true} onChange={handleOperationalDays} checked={inputs?.operational_days?.wednesday} />
                            <span>Wednesday</span>
                        </div>
                        <div className='d-flex gap-1'>
                            <input type='checkbox' name='thursday' value={true} onChange={handleOperationalDays} checked={inputs?.operational_days?.thursday} />
                            <span>Thursday</span>
                        </div>
                        <div className='d-flex gap-1'>
                            <input type='checkbox' name='friday' value={true} onChange={handleOperationalDays} checked={inputs?.operational_days?.friday} />
                            <span>Friday</span>
                        </div>
                        <div className='d-flex gap-1'>
                            <input type='checkbox' name='saturday' value={true} onChange={handleOperationalDays} checked={inputs?.operational_days?.saturday} />
                            <span>Saturday</span>
                        </div>
                    </div>
                </div>
                {dirty['operational_days'] && errors['operational_days'] ?

                    <span className="errorMessage">
                        {errors['operational_days']}
                    </span> : ''

                }
                <CustomFieldWithTtileSS
                    type={"custom-time-selector"}
                    showError={
                        dirty["StartTime"] && errors["StartTime"][0]
                            ? errors["StartTime"]
                            : ""
                    }
                    name={"StartTime"}
                    customDatePickerConfig={{
                        selectedDate: "",
                        name: "StartTime",
                        timeFormat: 'HH:mm',
                        showTimeSelect: true,
                        showTimeSelectOnly: true,
                        timeIntervals: 15,
                        timeCaption: "Time",
                        customInput: (
                            <TUSDatePickerInput
                                value={startTimeState}
                                titleField={"Start"}
                            />
                        ),
                    }}
                    value={inputs.StartTime ? inputs.StartTime : ""}
                    handleErrors={handleErrors}
                    title={
                        AppConstant.smartScheduler.breaksForm.tableHeaders.startTime
                    }
                    required={true}
                    onChange={(data, name) => handleChange(data, name)}
                />
                <CustomFieldWithTtileSS
                    type={"custom-time-selector"}
                    showError={
                        dirty["EndTime"] && errors["EndTime"][0]
                            ? errors["EndTime"]
                            : ""
                    }
                    name={"EndTime"}
                    timeSlotStart={inputs.StartTime}
                    customDatePickerConfig={{
                        placeholder: "HH:MM",
                        selectedDate: "",
                        timeFormat: 'HH:mm',
                        startDate: "",
                        name: "EndTime",
                        showTimeSelect: true,
                        showTimeSelectOnly: true,
                        timeIntervals: 15,
                        timeCaption: "Time",
                        filterPassedTime: filterPassedTimeValue,
                        customInput: (
                            <TUSDatePickerInput value={endTimeState} titleField={"End"} />
                        ),
                    }}
                    value={inputs.EndTime ? inputs.EndTime : ""}
                    handleErrors={handleErrors}
                    title={AppConstant.smartScheduler.breaksForm.tableHeaders.endTime}
                    required={true}
                    onChange={(data, name) => handleChange(data, name)}
                />
                <CustomFieldWithTtileSS
                    type={"select"}
                    showError={
                        dirty["ApptFrequency"] && errors["ApptFrequency"][0]
                            ? errors["ApptFrequency"]
                            : ""
                    }
                    name={"ApptFrequency"}
                    value={inputs["ApptFrequency"] ? inputs["ApptFrequency"] : ""}
                    handleErrors={handleErrors}
                    title={"Appointment Frequency (In min)"}
                    required={true}
                    onChange={(data, name) => handleChange(data, name)}
                    listData={getDockApptFrequency}
                    // disabledField={false}
                />
                <CustomFieldWithTtileSS
                    type={"number"}
                    showError={dirty["BlockHours"] && errors["BlockHours"][0] ? errors["BlockHours"] : ""}
                    name={"BlockHours"}
                    value={inputs["BlockHours"] ? inputs["BlockHours"] : ""}
                    handleErrors={handleErrors}
                    // title={`Block Time (In min)`}
                    title={<span>Block Time (In min) <i class="fa fa-info-circle" aria-hidden="true" data-for="dock-block-time-info-tooltip" data-tip={("dock-block-time-info-tooltip")}></i></span>}
                    // subTitle={{
                    //     text: 'Block time is the hours post which a carrier can book their appointment at a dock level',
                    //     type: 'danger'
                    // }}
                    required={false}
                    onChange={(data, name) => handleChange(data, name)}

                />
                <CustomTooltip
                    id={'dock-block-time-info-tooltip'}
                    title="Block time is the hours post which a carrier can book their appointment at a dock level"
                    position='top'
                    type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'dark' : 'light'}
                    multiline={true} />
                <CustomFieldWithTtileSS
                    type={"number"}
                    showError={dirty["appointmentLifeTime"] && errors["appointmentLifeTime"][0] ? errors["appointmentLifeTime"] : ""}
                    name={"appointmentLifeTime"}
                    value={inputs["appointmentLifeTime"] ? inputs["appointmentLifeTime"] : ""}
                    handleErrors={handleErrors}
                    title={<span>Appointment Life Time (in hours) <i class="fa fa-info-circle" aria-hidden="true" data-for="dock-apt-life-time-info-tooltip" data-tip={("dock-apt-life-time-info-tooltip")}></i></span>}
                    // subTitle={{
                    //     text: 'By default all appointments expire Midnight of the Appointment date',
                    //     type: 'danger'
                    // }}
                    placeHolder="Default is Zero hours"
                    required={false}
                    onChange={(data, name) => handleChange(data, name)}
                />
                <CustomTooltip
                    id={'dock-apt-life-time-info-tooltip'}
                    title="By default all appointments expire Midnight of the Appointment date"
                    position='top'
                    type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'dark' : 'light'}
                    multiline={true} />
                <CustomFieldWithTtileSS
                    type={"number"}
                    showError={dirty["appointmentLeadTime"] && errors["appointmentLeadTime"][0] ? errors["appointmentLeadTime"] : ""}
                    name={"appointmentLeadTime"}
                    value={inputs["appointmentLeadTime"] ? inputs["appointmentLeadTime"] : ""}
                    handleErrors={handleErrors}
                    // title={`Appointment Lead Time (in hours)`}
                    title={<span>Appointment Lead Time (in hours) <i class="fa fa-info-circle" aria-hidden="true" data-for="dock-appt-lead-time-info-tooltip" data-tip={("dock-appt-lead-time-info-tooltip")}></i></span>}
                    // subTitle={{
                    //     text: 'Appointment lead time is the number of hours a user can start booking an appointment before the due date',
                    //     type: 'danger'
                    // }}
                    placeHolder="Default is Zero hours"
                    required={false}
                    onChange={(data, name) => handleChange(data, name)}
                />
                 <CustomTooltip
                    id={'dock-appt-lead-time-info-tooltip'}
                    title="Appointment lead time is the number of hours a user can start booking an appointment before the due date"
                    position='top'
                    type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'dark' : 'light'}
                    multiline={true} />
                <div className="d-flex justify-content-end my-2">
                    <CustomButtonSS
                        isLoading={false}
                        title={"Reset"}
                        className={"btn-unstyled"}
                        onClick={() => setInputs({})}
                    />
                    <CustomButtonSS
                        isLoading={loading}
                        title={"Save"}
                        className={"btn-styled"}
                        onClick={submitForm}
                    />
                </div>
            </div>
        );
    };

    const createPayload = (data, type) => {
        if (type === "Edit") {
            return {
                "DockName": data.DockName,
                "PPH": data.PPH,
                "DockStatus": data.DockStatus,
                "ID": data.ID,
                "CRUDTYPE": "U",
                "IDWarehouse": data.IDWarehouse,
                "StartTime": data.StartTime,
                "EndTime": data.EndTime,
                "UpdatedBy": cu_id,
                "CreatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "NonOperationalDays": unSlectedDays.length ? unSlectedDays.slice(1) : null
            }
        }
        else {
            return {
                "DockName": data.DockName,
                "PPH": data.PPH,
                "DockStatus": true,
                "ID": data.ID,
                "CRUDTYPE": "I",
                "IDWarehouse": whObj.ID,
                "StartTime": data.StartTime,
                "EndTime": data.EndTime,
                "CreatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "CreatedBy": cu_id,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
        }
    }

    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: '',
        }

        if (data.IDWarehouse) {
            const apiTempParams = createPayload(data, "Edit")
            operationObj.type = 'Edit Dock'
            apiParams = { ...data, ...apiTempParams }
            apiParams.StartTime = apiTempParams.StartTime;
            apiParams.EndTime = apiTempParams.EndTime
        }
        else {
            apiParams = createPayload(data, "Create")
            operationObj.type = 'Create Dock'
        }

        apiParams.StartTime = getDockOperationalTime(data?.StartTime);
        apiParams.EndTime = getDockOperationalTime(data?.EndTime);
        apiParams.operational_days = data.operational_days;
        apiParams.BlockHours = data.BlockHours ? data.BlockHours : null;
        apiParams.CapacityModeling = data?.CapacityModeling;
        apiParams.TPH = data?.TPH;
        apiParams.CPH = data?.CPH;
        apiParams.appointmentLifeTime = data?.appointmentLifeTime || 0;
        apiParams.appointmentLeadTime = data?.appointmentLeadTime || 0;
        apiParams.ApptFrequency = data?.ApptFrequency || 15
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    };

    /**
     * Function to validate operational days 
     * @param {*} data 
     * @returns 
     */
    const validateOperationalDays = (data) => {
        let valid = false;
        Object?.keys(data)?.forEach((el) => {
            if (data[el] === true) {
                valid = true
            }

        })
        return valid
    }

    /**
     * Function to handle form submit
     */
    const submitForm = () => {
        let dirtyData = dirty;
        Object.keys(dirty).forEach((control) => {
            dirtyData[control] = true;
        });


        setDirty(dirtyData);
        if (validate()) {

            getSummitedFormData(inputs);
        }
    };

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const showCreateDockPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: !Object.keys(editData).length ? AppConstant.smartScheduler.docks.createDockTitle : AppConstant.smartScheduler.docks.editDockTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const closeCurrentpopup = () => {
        setEditData({});
        setErrors(initialErrors)
        setShowModal(false);
    }

    
    const openCreateForm = () => {
        setDirty({
        DockName: false,
        PPH: false,
        CPH: false,
        TPH: false,
        operational_days: false,
        StartTime: false,
        EndTime: false,
        CapacityModeling: false,
        BlockHours: false,
        appointmentLifeTime: false,
        appointmentLeadTime: false,
        ApptFrequency: false
        })
        setShowModal(true);
        setEditData({});
        setInputs({})
        setErrors(initialErrors)
        setParticularFieldValue({});
    }
    const closePreCheckpopup = () => {
        setShowPreCheckModal(false)
    }

    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.viewDetailsStr) {
            navigate("/manageappointments")
        }
        else {
            setShowPreCheckModal(false)
        }
    }

    const showPreCheck = () => {
        const popupObj = {
            id: 'adminUpdateOperationPreCheck',
            modalLabel: 'adminUpdateOperationPreCheck',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.docks.editDockTitle,
            bodyText: AppConstant.superUserAdmin.warehouseAdmin.preCheckBockerMsg,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.viewDetailsStr
                },
                btn2: {
                    show: false,
                    text: AppConstant.commonStrings.close
                },

            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { closePreCheckpopup() }} themeSelected={themeSelected} showModal={showPreCheckModal} />
    }

    return (
        <div>
            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1'>
                <button
                    className={'btn-highlighted f-12 me-3'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}>
                    {AppConstant.smartScheduler.docks.createDockTitle}
                </button>
                <div className='ms-5'>
                    <WarehouseSearchSS
                        placeholder={'Search Docks'}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>
            <div className="d-flex justify-content-center metaDockList">
                <DockListSS searchStr={searchStr} editDockAction={(groupObj) => editDock(groupObj)} showPopup={() => showCreateDockPopup()} themeSelected={themeSelected} allowPermissionE={allowPermissionE} allowPermissionD={allowPermissionD} whObj={whObj} />
                {showCreateDockPopup()}
                {showPreCheck()}
            </div>
        </div>

    )
}

export default DocksSS;