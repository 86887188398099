import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    whMetaDocksList: [],
    whMetaLeavesList: [],
    whMetaBreaksList: [],
    whMetaCapacityList: [],
    whMetaTimeslotList: [],
    operationObj: {},
    arrangeOrder: 'asc',
    arrangeKey: 'CreatedOn',
    preCheckList:[],
    lastPreCheckObj:{},
    dockConflictObj :{},
    isLoading: false,
    dockTypeList: []
}

export const WhMetaDataReducerSlice = createSlice({
    name: 'whMetaDataReducerSlice',
    initialState,
    reducers: {
        getWhMetaDockList: (state, action) => {
            state.whMetaDocksList = action.payload;
        },
        getWhMetaLeavesList: (state, action) => {
            state.whMetaLeavesList = action.payload;
        },
        getWhMetaBreaksList: (state, action) => {
            state.whMetaBreaksList = action.payload;
        },
        getWhMetaCapacityList: (state, action) => {
            state.whMetaCapacityList = action.payload;
        },
        getWhMetaTimeslotList: (state, action) => {
            state.whMetaTimeslotList = action.payload;
        },
        updateOperationObj: (state, action) => {
            state.operationObj = action.payload
        },
        updateTableData: (state, action) => {
            const { drillDownReport, arrange, key } = action.payload;
            state[key] = drillDownReport
            state.arrangeKey = arrange.key
            state.arrangeOrder = arrange.order
        },
        updatePreCheckList:(state,action)=>{
            state.preCheckList=action.payload
        },
        updatePreCheckObj:(state,action)=>{
            state.lastPreCheckObj=action.payload
        },
        dockConflictDetailsReducer: (state, action)=>{
            state.dockConflictObj = action.payload
        },
        updateIsLoading: (state, action)=>{
            state.isLoading = action.payload
        },
        updateDockTypeList: (state, action) => {
            state.dockTypeList = action.payload
        },
        resetMetaDocksList : (state, action)=>{
            state.whMetaDocksList = action.payload
        }
    }
});

export const { getWhMetaDockList, updateOperationObj, getWhMetaLeavesList,
    getWhMetaBreaksList, getWhMetaCapacityList, getWhMetaTimeslotList, updateTableData,updatePreCheckList,updatePreCheckObj,dockConflictDetailsReducer, updateIsLoading, updateDockTypeList, resetMetaDocksList } = WhMetaDataReducerSlice.actions;

// Selectors
export const getWhMetaDataReducerSlice = (state) => {
    return state.whMetaDataReducerSlice
};


export default WhMetaDataReducerSlice.reducer;