import { combineReducers } from 'redux';
import getTruckReducerSlice from './reducerSlices/getTruckReducer';
import getLoadReducerSlice from './reducerSlices/getLoadReducer';
import FiltersReducerSlice from './reducerSlices/FiltersReducerSlice';
import DateSelectorReducer from './reducerSlices/DateSelectorReducer';
import AuthReducer from './reducerSlices/AuthReducer';
import CommentsReducerSlice from './reducerSlices/CommentsReducerSlice';
import POReducer from './reducerSlices/POReducer';
import DockListReducer from './reducerSlices/DockListReducer';
import AssignDockReducer from './reducerSlices/AssignDockReducer';
import DefaultDashboardReducer from './reducerSlices/DefaultDashboardReducer';
import SuperAdminReducerSlice from './reducerSlices/SuperAdminReducerSlice';
import RemoteConfigReducerSlice from './reducerSlices/RemoteConfigReducer';
import UserReducer from './reducerSlices/UserReducer';

import HelpAndFeedBackReducer from "./reducerSlices/HelpAndFeedbackReducer";
import FileUploadReducer from "./reducerSlices/FileuploadReducer";
import CompanyGroupReducer from "./reducerSlices/CompanyGroupReducer";
import CompanyReducer from "./reducerSlices/CompanyReducer";
import GetWarehouseDataReducer from "./reducerSlices/GetWarehouseDataReducer";
import GetLumpingFaciltyReducer from "./reducerSlices/GetLumpingFaciltyReducer";
import GetTaxCodeReducer from "./reducerSlices/GetTaxCodeReducer";
import GetTimeZoneReducer from "./reducerSlices/GetTimeZoneReducer";
import GetWareHouseLocationList from "./reducerSlices/GetWareHouseLocationList";
import GetCountryListReducer from "./reducerSlices/GetCountryListReducer";
import AddWarehouseReducer from "./reducerSlices/AddWarehouseReducer";
import ReportsReducerSlice from './reducerSlices/ReportsReducerSlice';
import SendEmailForAppointment from './reducerSlices/sendEmailForAppointment';
import PaytraceReducer from './reducerSlices/PaytraceReducer';
import POSSReducerSlice from './reducerSlices/POSmartSchedulerReducer';
import SmartScheduleReducerSlice from './reducerSlices/SmartScheduleReducer';
import WhMetaDataReducerSlice from './reducerSlices/WhMetaDataReducerSlice';
import UserPaymentsReducerSlice from './reducerSlices/UserPaymentsReducerSlice';
import DriverCheckinReducer from "./reducerSlices/DriverCheckinReducer";
import CheckInReducer from './reducerSlices/CheckInReducer';
import ScheduleUploadStatus from './reducerSlices/ScheduleUploadReducer';
import EclipseOpsPaymentLoadReducer from './reducerSlices/EclipseOpsPaymentLoadReducer';
import FsWarehouseReducer from './reducerSlices/FsWarehouseReducer';
import GatePassReducer from './reducerSlices/GatePassReducer';
import PoIntakeMangementReducer  from './reducerSlices/PoIntakeMangementReducer';

 const rootReducer = combineReducers({
    getTruckReducer: getTruckReducerSlice,
    filterReducer : FiltersReducerSlice,
    getLoadReducer: getLoadReducerSlice,
    dateSelectorReducer: DateSelectorReducer,
    authReducer: AuthReducer,
    commentsReducer: CommentsReducerSlice,
    poReducer: POReducer,
    getDockList: DockListReducer,
    assignDockReducer: AssignDockReducer,
    defaultDashboardReducer: DefaultDashboardReducer,
    remoteConfigReducer: RemoteConfigReducerSlice,
    userReducer: UserReducer,
    helpAndFeedBackReducer:HelpAndFeedBackReducer,
    superAdminReducerSlice: SuperAdminReducerSlice,
    fileUploadReducer:FileUploadReducer,
    CompanyGroupReducer:CompanyGroupReducer,
    CompanyReducer:CompanyReducer,
    GetWarehouseDataReducer:GetWarehouseDataReducer,
    GetLumpingFaciltyReducer:GetLumpingFaciltyReducer,
    GetTaxCodeReducer:GetTaxCodeReducer,
    GetTimeZoneReducer:GetTimeZoneReducer,
    GetWareHouseLocationList:GetWareHouseLocationList,
    GetCountryListReducer:GetCountryListReducer,
    AddWarehouseReducer:AddWarehouseReducer,
    reportsReducerSlice: ReportsReducerSlice,
    SendEmailForAppointment:SendEmailForAppointment,
    smartScheduleReducer: SmartScheduleReducerSlice,
    PaytraceReducer:PaytraceReducer,
    pOSSReducerSlice: POSSReducerSlice,
    whMetaDataReducerSlice: WhMetaDataReducerSlice,
    userPaymentsReducerSlice: UserPaymentsReducerSlice,
   DriverCheckinReducer: DriverCheckinReducer,
    getCheckIn:CheckInReducer,
   scheduleUploadStatus: ScheduleUploadStatus,
   eclipseOpsPaymentLoadReducer: EclipseOpsPaymentLoadReducer,
   FsWarehouseReducer:FsWarehouseReducer,
   GatePassReducer : GatePassReducer,
   PoIntakeMangementReducer : PoIntakeMangementReducer,

  });

export default rootReducer;