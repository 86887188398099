import React, { useState } from 'react';
import { AppConstant } from '../../../../../Assests/AppConstant';
import './SsPOList.css';
import { useEffect } from 'react';
import DrilldownTableSS from '../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';

const SsPOListForUploadSS = (props) => {

    const { themeSelected, type } = props;

    let [createPoTitleDD, setcreatePoTitleDD] = useState([]);
    useEffect(() => {
        if (type === "failed") {
            setcreatePoTitleDD([
                { id: 'PoNumber', name: AppConstant.poManagement.poDetails.poSoTitle },
                { id: 'VendorName', name: AppConstant.poManagement.searchPOSection.vendorTitleStr },
                { id: 'DockName', name: AppConstant.smartScheduler.poSection.produceType },
                { id: 'DueDate', name: "DueDate" },
                { id: 'Pallets', name: AppConstant.smartScheduler.poSection.palletsCount },
                { id: 'Cases', name: AppConstant.smartScheduler.poSection.casesCount },
                { id: 'reasonForFailure', name: "Reason For Fail" }])

        }
        else {
            setcreatePoTitleDD([
                { id: 'PoNumber', name: AppConstant.poManagement.poDetails.poSoTitle },
                { id: 'VendorName', name: AppConstant.poManagement.searchPOSection.vendorTitleStr },
                { id: 'DockName', name: AppConstant.smartScheduler.poSection.produceType },
                { id: 'DueDate', name: "DueDate" },
                { id: 'Pallets', name: AppConstant.smartScheduler.poSection.palletsCount },
                { id: 'Cases', name: AppConstant.smartScheduler.poSection.casesCount }]);
        }
    }, [props.type])

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            if (type === "failed") {
                return {
                    id: el.RoleID,
                    0: el.PoNumber,
                    1: el.VendorName,
                    2: el.ProduceTypeName,
                    3: el.DueDate,
                    4: el.Pallets ? el.Pallets: '-',
                    5: el.ItemsCases? el.ItemsCases: '-',
                    6: el.reasonForFailure
                }
            }
            else {
                return {
                    id: el.RoleID,
                    0: el.PoNumber,
                    1: el.VendorName,
                    2: el.ProduceTypeName,
                    3: el.DueDate,
                    4: el.Pallets? el.Pallets:'-',
                    5: el.ItemsCases?el.ItemsCases:'-'
                }
            }

        })
    }



    return (
        <div className=''>
            {
                <div>
                    <div className='appointmentListTable'>
                        <DrilldownTableSS
                            themeSelected={themeSelected}
                            bodyData={formatListDataDrillDown(props.bodyData)}
                            titleData={createPoTitleDD}
                            showCollapse={false}
                            showInput={false}
                            drillDownReport={props.bodyData}
                            subRoot={{ pathName: 'poSSList' }}
                            showUTCTime={false}
                        />
                    </div>

                </div>
            }
        </div>
    )

}
export default SsPOListForUploadSS;